import { isValidElement, cloneElement } from "react";

export default function ReportTemplateHeading({defaultKey='', children, props, fetus = 1, numberOfFetuses, fetusNames}) {

  const {size="medium", align=null} = (props ?? {});

  const sizes = {
    xl: 1,
    l: 2,
    m: 3,
    s: 4,
    xs: 5,
    xxs: 6,
  };
  const HeaderTag = "h" + (sizes[size] || 2);

  const style = {};
  if (align) style.textAlign = align;
  /* color and background are only supported for printing purposes */

  const childrenWithFetusNumber = Array.isArray(children) ? children.map((child, i) => {
    if (isValidElement(child)) {
      return cloneElement(child, { ...props, numberOfFetuses, fetus, fetusNames, defaultKey: `${defaultKey}_${i}_heading_${fetus}` });
    }
    return child;
  }) : children;

  return (
    <HeaderTag style={style}>{childrenWithFetusNumber}</HeaderTag>
  );
}

import { useMemo, useRef, createRef } from "react";
import Coding from "./Coding";
import SectionsNavBar from "../../../components/SectionsNavBar/SectionsNavBar";

export default function ReportTemplateDocument({children, startEditing, props, placeholder, showErrors, canEdit, ...otherProps}) {
  const {theme = "basic", country = "US"} = props;

  const childrenWithSections = useMemo(() => Array.isArray(children) ? children.map((child, index) => {
    if (child?.props?.type === "section") {
      const select = <ReportTemplateSection index={index} key={index} children={child} {...child.props} />;
      return select;
    }
    return child;
  }) : children, [children]);

  const chapters = Array.isArray(childrenWithSections)
  ? (childrenWithSections.map((child, index) => child?.props?.type === "section" ? ({
        child: child,
        icon: child.props.props.icon,
        title: child.props.props.name,
        description: '',
        index,
        targetClass: `.template-section-${index}`, /* TODO: find a way to manage this by using refs */
      }) : false)).filter(chapter => chapter)
    : [];

  const codingProps = {
    canEdit,
    showErrors,
    placeholder: placeholder.coding,
    ...otherProps
  }

  return (
    <div className="exam-report-container exam-report-template" data-theme={theme} data-country={country}>
      {!!chapters.length && <SectionsNavBar options={chapters} />}
      {childrenWithSections}
      {placeholder?.coding && <Coding {...codingProps} />}
    </div>
  );
}


function ReportTemplateSection({children, props, index}) {
  return <section className={`template-section template-section-${index}`}>{children}</section>;
}

import { formatYYYYMMDDDate } from "../../../utils";

export default function ReportTemplateValue({ props, placeholder: originalPlaceholder, multiselectValue = false, fetus=1, appPreferences }) {
  const preferedDateFormat = appPreferences?.date_format;

  let placeholder;
  if (Array.isArray(originalPlaceholder) || props.data?.startsWith("measurement.")) {
    placeholder = originalPlaceholder[(Number(props.fetus ?? fetus ?? 1))]
  } else if (Array.isArray(originalPlaceholder.value)) {
    placeholder = {...originalPlaceholder, value: originalPlaceholder.value[(Number(props.fetus ?? fetus ?? 1))]}
  } else {
    placeholder = originalPlaceholder;
  }

  const value = placeholder?.value;
  const { attribute, decimals } = (props ?? {});

  const convertDates = label => isNaN(label) && `${label}`?.match(/^\d{4}.\d{2}.\d{2}$/) ? formatYYYYMMDDDate(label, preferedDateFormat) : label;

  const getDisplayedValue = () => {
    let dispValue = value;

    if (multiselectValue) {
      dispValue = placeholder?.value?.[multiselectValue];
    }
    if (props.attribute) {
      return multiselectValue
        ? dispValue?.[props.attribute]
        : placeholder?.[props.attribute];
    }

    return dispValue;
  };

  let output = getDisplayedValue();
  if (Array.isArray(output)) output = output[fetus];

  if (decimals && !isNaN(output)) output = Number(output).toFixed(decimals);

  output = convertDates(output);

  return output || false;
}
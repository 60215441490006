import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory, useLocation } from 'react-router-dom';
import Icon from "../../atoms/Icon/Icon";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import ExamNavBarDetect from "../../components-dt/ExamNavBar/ExamNavBar";
import ExamCompletedBannerDetect from "../../components-dt/Examination/ExamCompletedBanner/ExamCompletedBanner";
import SlideBrowserDetect from "../../components-dt/Examination/SlideBrowser/SlideBrowser";
import SlideGridDetect from "../../components-dt/Examination/SlideGrid/SlideGrid";
import SlidePanelDetect from "../../components-dt/Examination/SlidePanel/SlidePanel";
import PausedPrintButton from "../../components/ExamMediaView/PausedPrintButton/PausedPrintButton";
import ExamNavBarRoutine from "../../components/ExamNavBar/ExamNavBar";
import ExamPresetSelector from "../../components/ExamPresetSelector";
import ExamCompletedBannerRoutine from "../../components/Examination/ExamCompletedBanner/ExamCompletedBanner";
import SlideBrowserRoutine from "../../components/Examination/SlideBrowser/SlideBrowser";
import SlideGridRoutine from "../../components/Examination/SlideGrid/SlideGrid";
import SlidePanelRoutine from "../../components/Examination/SlidePanel/SlidePanel";
import GlobalSearchBar from "../../components/GlobalSearchBar/GlobalSearchBar";
import PatientName from "../../components/PatientName/PatientName";
import { AppContext } from "../../context-providers/App";
import useAuth from "../../context-providers/Auth";
import { ExaminationContext } from "../../context-providers/Examination";
import { NotificationContext } from "../../context-providers/Notification";
import { ZoomLevelContext } from "../../context-providers/ZoomLevel";
import { isNullOrUndefined } from "../../utils";
import ExaminationReport from "../ExaminationReport";
import ExaminationReviewView from "../ExaminationReview";
import PrintPreview from "../ExaminationReview/PrintPreview";
import SharingPreview from "../ExaminationReview/SharingPreview";
import SharingSelect from "../ExaminationSharingFlow/SharingSelect";
import "./ExaminationView.css";
import FetusPanel from "./FetusPanel/FetusPanel";
import NdExaminationToolbar from "./NdExaminationToolbar/NdExaminationToolbar";
import PhenotypePanel from "./PhenotypePanel/PhenotypePanel";
import SearchBar from "./SearchBar/SearchBar";
import { LiveExaminationContext } from "../../context-providers/LiveExamination";
import Button from "../../atoms/Button/Button";
import { WindowContext } from "../../context-providers/Window";
import SharingIndicator from "./SharingIndicator/SharingIndicator";
import SharingTopBar from "../ExaminationSharingFlow/SharingTopBar";

const planeToShow = (userId, examId, defaultPlane) => {
  const json = window.localStorage.getItem(`defaultShownPlanes-user-${userId}`) || JSON.stringify([examId, defaultPlane]);
  const [savedExam, savedPlane] = JSON.parse(json)
  return savedExam === examId ? savedPlane : defaultPlane;
}

const ExaminationView = ({
  t: __,
  instanceViews,
  slides,
  slidesRiskFactors,
  medias,
  setMedias,
  currentTrimester,
  currentPreset,
  fetusSex,
  medicalHistory,
  checklistItems,
  checklistItemsRiskFactors,
  anomalies,
  unusualMedias,
  nextMatchingSlide,
  setLastMatchedSlide,
  isPaused,
  toggleIsPaused,
  isManual,
  toggleIsManual,
  isPrintable,
  toggleIsPrintable,
  setExaminationNextView,
  assocInstanceExam,
  setSharingStatusForInstance,
  isInstanceUnusual,
  naked = false,
  nakedProps = {},
  fetalSexField,
  sexConfirmedBy,
  sexContradictedBy,
  placentaPositionField,
  placentaPositionConfirmedBy,
  placentaPositionContradictedBy,
}) => {
  const windowContext = useContext(WindowContext);
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const notificationContext = useContext(NotificationContext);
  const liveExaminationContext = useContext(LiveExaminationContext);
  const { zoomLevel, zoomIn, zoomOut } = useContext(ZoomLevelContext);
  const history = useHistory();
  const location = useLocation();
  const { isFeatureFlagEnabled, user } = useAuth();

  const [currentSection, setCurrentSection] = useState(false);
  const [openPanel, doSetOpenPanel] = useState(false);
  const [selectedPlane, setSelectedPlane] = useState(false);
  const [oneShotIncomingInstance, setOneShotIncomingInstance] = useState(false);
  const [isPrintPreviewOpen, setIsPrintPreviewOpen] = useState(false);
  const [isSharingPreviewOpen, setIsSharingPreviewOpen] = useState(false);
  const [atRiskSlides, setAtRiskSlides] = useState([]);
  const [shownPlanes, setShownPlanes] = useState(planeToShow(user.id, examinationContext.examination?.id, "all"));
  const [sortPlanesBy, setSortPlanesBy] = useState(window.localStorage.getItem(`defaultSortPlanesBy-user-${user.id}`) || "slide");
  const [showSlideBrowser, setShowSlideBrowser] = useState(false);
  const [videos, setVideos] = useState([]);
  const [pauseButtonLabel, setPauseButtonLabel] = useState("");
  const [extractedFrames, setExtractedFrames] = useState([]);
  const imageChapterRef = useRef(false);
  const matchingChapterRef = useRef(false);
  const videoChapterRef = useRef(false);

  const unverifiedMedias = useMemo(() => isFeatureFlagEnabled("sonio.detect") ? medias.filter(media => !media.slideId && media.dicom_media_type === "image" && media.modality != "SR" && !media?.verified) : [], [isFeatureFlagEnabled, medias]);
  const highlightNextMatching = useMemo(() => !isFeatureFlagEnabled("sonio.detect") || isManual || isPaused, [isFeatureFlagEnabled, isManual, isPaused]);

  const filteredSlides = useMemo(() => {
    const filteredSlides = [...slides]
      .filter(slide => (
        shownPlanes === "all"
        || (shownPlanes === "taken" && (slide.mediaId || slide.is_video))
        || (shownPlanes === "left" && !slide.mediaId)
        || (shownPlanes === "unusual" && unusualMedias.some(mediaId => mediaId === slide.mediaId))
      ));

    const output = {
      protocol: [],
      optional: [],
      unmatched: [],
      videos: [],
    }

    if (sortPlanesBy === "slide") {
      output.protocol = [...filteredSlides].filter(s => !s.optional && s.type === "picture");
      output.optional = [...filteredSlides].filter(s => (s.optional && s.type === "picture") || (s.type === "other" && (s.verified || !s.mediaId) && !s.is_video));
      output.unmatched = [...filteredSlides].filter(s => s.mediaId && !s.optional && !s.verified && !s.is_video);
      output.videos = [...filteredSlides].filter(s => s.is_video);
    } else if (sortPlanesBy === "time") {
      output.protocol = [...filteredSlides].sort((a, b) => (sortPlanesBy === "time" ? (
        (a.inserted_at === b.inserted_at) ? 0 : ((a.inserted_at || "z") > (b.inserted_at || "z") ? 1 : -1)
        ) : 0));
    } else {
      output.protocol =  [...filteredSlides];
    }

    return output;
  }, [slides, shownPlanes, sortPlanesBy]);


  useEffect(() => {
    if (examinationContext.examination?.id && isFeatureFlagEnabled("soniopedia")) {
      history.replace(`/exam/${examinationContext.examination.id}/dx`);
    }
  }, [history, isFeatureFlagEnabled, examinationContext.examination.id])
  
  const setOpenPanel = (panel) => {
    if (typeof panel === "function") {
      panel = panel(openPanel);
    };

    const panelToWindow = {
      stats: "report",
      phenotype: "anamnesis",
      slide: "slide",
    };

    if (panelToWindow[panel]
      && isFeatureFlagEnabled("sonio.multiscreen")
      && (windowContext.windowExists(panelToWindow[panel]) || windowContext.getAllActiveWindows().some(w => w.view === panelToWindow[panel]))
    ) {
      windowContext.detach(panelToWindow[panel], [examinationContext.examination.id]);
    } else {
      if (!panel && location.hash === "#report") history.replace(location.pathname)
      doSetOpenPanel(panel);
    }
  }

  const parseWindowMessage = (e) => {
    if (e.origin !== window.location.origin) return;
    if (!isNullOrUndefined(e.data?.setSelectedPlane)) {
      setSelectedPlane(e.data?.setSelectedPlane);
    }
  }
  
  useEffect(() => {
    if (selectedPlane?.media?.id) {
      const media = examinationContext.instances.find(instance => instance.id === selectedPlane.media.id);
      if (media) setSelectedPlane({...selectedPlane, media});
    }
  }, [examinationContext.instances]);

  const onCloseWindow = () => {
    windowContext.postMessageToView("core", {setSelectedPlane: false})
  }

  useEffect(() => {
    window.addEventListener("message", parseWindowMessage);
    window.addEventListener("beforeunload", onCloseWindow);
    return () => {
      window.removeEventListener("message", parseWindowMessage);
      window.removeEventListener("beforeunload", onCloseWindow);
    }
  }, []);

  useEffect(() => {
    if (openPanel) appContext.activateGoBackMenuButton(true, () => () => setOpenPanel(false));
    else appContext.activateGoBackMenuButton(false);
  }, [openPanel])

  useEffect(() => {
    setExtractedFrames(selectedPlane?.media?.dicom_media_type !== "video"
      ? []
      : medias
        .filter(media => media?.dicom_origin_id === selectedPlane.media.id)
        .map(frame => ({ ...frame, slide: slides.find(slide => slide.id === frame.slideId) }))
    );
  }, [JSON.stringify(selectedPlane?.media), JSON.stringify(medias), slides]);

  useEffect(() => {
    if (isPaused || isManual) {
      setPauseButtonLabel(isPaused
        ? __("examination.paused")
        : __("examination.manual"));
    }
  }, [isPaused, isManual]);

  useEffect(() => {
    const atRiskSlides = Object.entries(slidesRiskFactors)
      .map(([key, value]) => !!value.risk_factors.length ? Number(key) : false)
      .filter(riskId => !!riskId);
    setAtRiskSlides(() => atRiskSlides);
  }, [slidesRiskFactors, slides]);

  useEffect(() => {
    setSelectedPlane(false);
    setShownPlanes(planeToShow(user.id, examinationContext.examination.id, "all"));
  }, [examinationContext.examination.id, user.id])

  /** follow the image on reassociation when image is open in a popup */
  useEffect(() => {
    if (examinationContext.lastReassociation && windowContext.windowExists("slide")) {
      splitScreen("slide", [examinationContext.lastReassociation.key]);
    }
  }, [examinationContext.lastReassociation]);
  
  const splitScreen = useCallback((view, props) => {
    windowContext.detach(view, [examinationContext?.examination?.id, ...(props || [])]);
    setOpenPanel(false);
  }, [examinationContext?.examination?.id, windowContext.detach, setOpenPanel, setSelectedPlane]);

  const onDoubleClickHandle = useCallback((item, callbackFunctionParams, next) => {
    windowContext.windowExists("slide") && splitScreen("slide", [item?.key || callbackFunctionParams?.media?.id]);
    setSelectedPlane(callbackFunctionParams);
  }, [splitScreen, windowContext.windowExists]);

  const onClickHandle = useCallback(async (item, callbackFunctionParams, next) => {
    if (isFeatureFlagEnabled("sonio.detect")) {
      if (isPaused) {
        item.type === "other"
        ? toggleIsPaused(false)
        : toggleIsManual(item);
      } else if (isManual) {
        item.type === "other"
        ? toggleIsPaused(true)
        : toggleIsManual(item?.id === nextMatchingSlide?.id ? false : item);
      } else {
        onDoubleClickHandle(item, callbackFunctionParams, next);
      }
    } else {
      if (highlightNextMatching && item.key === next?.key) {
        onDoubleClickHandle(item, callbackFunctionParams, next);
      } else if (item.type === "picture") {
        await setExaminationNextView(item);
      } else if (item.dicom_media_type !== "video")  {
        toggleIsPaused(true);
      }
    }
  }, [onDoubleClickHandle, highlightNextMatching, toggleIsPaused, toggleIsManual, nextMatchingSlide, isFeatureFlagEnabled]);

  const onPlaneClose = useCallback(() => {
    setSelectedPlane(null);
    history.push();
  }, [])

  useEffect(() => {
    const newSlide = slides
      .filter(slide => !!slide.mediaId)
      .find(slide => slide?.mediaId === selectedPlane?.media?.id);

    !!selectedPlane
      && !!newSlide
      && selectedPlane?.slide?.id !== newSlide?.id
      && setSelectedPlane({
        slide: newSlide,
        media: selectedPlane?.media
      });
  }, [slides, selectedPlane?.media])

  useEffect(() => {
    if (!selectedPlane?.media) return false;
    const newMedia = medias.find(media => media.id === selectedPlane?.media?.id);
    newMedia !== selectedPlane?.media
      && setSelectedPlane(plane => ({
        slide: plane?.slide,
        media: medias.find(media => media.id === selectedPlane?.media.id)
      }));
  }, [JSON.stringify(medias)]);

  useEffect(() => {
    !selectedPlane?.slide && !selectedPlane?.media && setSelectedPlane(false);
  }, [JSON.stringify(selectedPlane)]);

  useEffect(() => {
    setOneShotIncomingInstance(liveExaminationContext.incomingDicomInstance?.id);
  }, [liveExaminationContext.incomingDicomInstance]);

  useEffect(() => {
    /* auto-zoom all views */
    if (
      !oneShotIncomingInstance
      || currentTrimester === "ND"
      || window.localStorage.getItem('zoomUponReceive') !== "on"
      || liveExaminationContext.incomingDicomInstance?.dicom_origin_id
      || liveExaminationContext.incomingDicomInstance?.modality === "SR"
      || !medias.some(media => media.id === oneShotIncomingInstance)
    ) return false;

    setOneShotIncomingInstance(false);
    setSelectedPlaneByMediaId(liveExaminationContext.incomingDicomInstance?.id);
  }, [medias, oneShotIncomingInstance, liveExaminationContext.incomingDicomInstance]);

  useEffect(() => {
    /* auto-zoom unusual views */
    if (
      !examinationContext.isExaminationLoaded
      || window.localStorage.getItem('zoomUponReceive') !== "suboptimal"
    ) return false;

    const predictedMedia = medias.find(media => media.id === liveExaminationContext.incomingQCPrediction?.[0]?.dicom_instance_id);
    if (
      !predictedMedia
      || predictedMedia.modality === "SR"
    ) return false;

    const isUnusual = isInstanceUnusual({ ...predictedMedia, qc_prediction: liveExaminationContext.incomingQCPrediction?.[0]?.qc_prediction });
    if (
      isUnusual
      && !predictedMedia.dicom_origin_id // exclude extracted images
    ) setSelectedPlaneByMediaId(predictedMedia.id);
  }, [liveExaminationContext.incomingQCPrediction]);

  useEffect(() => {
    const videos = medias.filter(media => media.dicom_media_type === "video");
    setVideos(videos.filter((video, index) => videos.findIndex(v => v.id === video.id) === index));
  }, [medias]);

  useEffect(() => {
    if (location.hash === "#report") {
      setOpenPanel("stats");
      setSelectedPlane(false);
      history.replace(location.pathname);
    } else if (location.hash === "#anamnesis") {
      setOpenPanel("phenotype");
      history.replace(location.pathname);
    }
  }, [examinationContext.examination.id, location.hash])

  useEffect(() => {
    if (location.hash.indexOf("#media-") === 0) {
      const mediaId = Number(location.hash.split("-")[1]);
      const matchingElement = document.getElementById(`matching-media-${mediaId}`);
      if (matchingElement) {
        // this allow us to easily display proposed associations in the zoomed slide
        const event_down = new MouseEvent('mousedown', { view: window, cancelable: true, bubbles: true });
        matchingElement.dispatchEvent(event_down);
        const event_up = new MouseEvent('mouseup', { view: window, cancelable: true, bubbles: true });
        matchingElement.dispatchEvent(event_up);
      } else {
        const media = medias.find(m => m.id === mediaId);
        const slide = slides.find(s => s.mediaId === mediaId);
        setSelectedPlane({ slide, media });
      }
    }
  }, [examinationContext.examination.id, location.hash, medias, slides])

  const setSelectedPlaneByKey = useCallback((key) => {
    const slide = slides.find(s => s.key === key);
    const media = medias.find(m => m.id === slide?.mediaId);
    setSelectedPlane({ slide, media });
    windowContext.postMessageToView("core", {setSelectedPlane: {slide, media}});
  }, [medias, slides]);
  
  const setSelectedPlaneByMediaId = useCallback((mediaId) => {
    const media = medias.find(m => m.id === mediaId);
    const slide = slides.find(s => s.id === media?.slideId);
    setSelectedPlane({ slide, media });
    windowContext.postMessageToView("core", {setSelectedPlane: {slide, media}});
  }, [medias, slides]);

  useEffect(() => {
    if (nakedProps.slideKey) {
      if (nakedProps.slideKey.includes("_")) {
        setSelectedPlaneByKey(nakedProps.slideKey);
      } else {
        setSelectedPlaneByMediaId(Number(nakedProps.slideKey));
      }
    }
  }, [nakedProps.slideKey, setSelectedPlaneByKey, setSelectedPlaneByMediaId]);
  
  useEffect(() => {
    if (examinationContext?.examination?.preset?.imaging_expected === false) setOpenPanel("stats");
  }, [examinationContext?.examination?.preset?.imaging_expected])

  useEffect(() => examinationContext.cleanInstancePreviewBlobs(), []);

  useEffect(() => {
    window.localStorage.setItem(`defaultShownPlanes-user-${user.id}`, JSON.stringify([examinationContext.examination?.id, shownPlanes]));
    windowContext.refreshWindow("slide");
  }, [shownPlanes, examinationContext.examination?.id, user.id]);

  useEffect(() => {
    window.localStorage.setItem(`defaultSortPlanesBy-user-${user.id}`, sortPlanesBy);
    windowContext.refreshWindow("slide");
  }, [sortPlanesBy]);

  useEffect(() => examinationContext.cleanInstancePreviewBlobs(), []);

  const showSharingFlowTopBar = isFeatureFlagEnabled("sonio.share");
  const showTopBar = !showSharingFlowTopBar;
  const showBanner = showTopBar && examinationContext.isCompleted();
  const showDetectBanner = showBanner && isFeatureFlagEnabled("sonio.detect") && !isFeatureFlagEnabled("sonio.routine");
  const showRoutineBanner = showBanner && !showDetectBanner;

  const filterOptions = currentTrimester === "ND"
    ? [
      {
        type: "heading",
        label: __("examination.settings.zoomLevel"),
      },
      {
        icon: "zoom-in",
        label: __("examination.settings.zoomIn"),
        value: "zoom-in",
        disabled: zoomLevel <= 1,
        autoClose: false,
      },
      {
        icon: "zoom-out",
        label: __("examination.settings.zoomOut"),
        value: "zoom-out",
        disabled: zoomLevel > 5,
        autoClose: false,
      },
    ]
    : [
      {
        type: "heading",
        label: __("examination.settings.display"),
      },
      {
        icon: shownPlanes === "all" ? "done" : "empty",
        label: __("examination.settings.showAll"),
        value: "showAll"
      },
      {
        icon: shownPlanes === "taken" ? "done" : "empty",
        label: __("examination.settings.showTakenPlanes"),
        value: "showTakenPlanes"
      },
      {
        icon: shownPlanes === "left" ? "done" : "empty",
        label: __("examination.settings.showLeftPlanes"),
        value: "showLeftPlanes"
      },
      {
        icon: shownPlanes === "unusual" ? "done" : "empty",
        label: __("examination.settings.showUnusualPlanes"),
        value: "showUnusualPlanes"
      },
      {
        type: "separator",
      },
      {
        type: "heading",
        label: __("examination.settings.sorting"),
      },
      {
        icon: sortPlanesBy === "slide" ? "done" : "empty",
        label: __("examination.settings.sortByRoutine"),
        value: "sortBySlide"
      },
      {
        icon: sortPlanesBy === "time" ? "done" : "empty",
        label: __("examination.settings.sortByTime"),
        value: "sortByTime"
      },
      {
        type: "separator",
      },
      {
        type: "heading",
        label: __("examination.settings.zoomLevel"),
      },
      {
        icon: "zoom-in",
        label: __("examination.settings.zoomIn"),
        value: "zoom-in",
        disabled: zoomLevel <= 1,
        autoClose: false,
      },
      {
        icon: "zoom-out",
        label: __("examination.settings.zoomOut"),
        value: "zoom-out",
        disabled: zoomLevel > 5,
        autoClose: false,
      },
    ];

  const filterChangeAction = (action) => {
    if (action === "showAll") setShownPlanes("all");
    else if (action === "showTakenPlanes") setShownPlanes("taken");
    else if (action === "showLeftPlanes") setShownPlanes("left");
    else if (action === "showUnusualPlanes") setShownPlanes("unusual");
    else if (action === "sortBySlide"){
      setSortPlanesBy("slide");
    }
    else if (action === "sortByTime") {
      setSortPlanesBy("time");
    }
    else if (action === "zoom-in") {
      zoomIn();
    }
    else if (action === "zoom-out") {
      zoomOut(3);
    }
  };

  const returnNakedComponents = () => {
    if (naked === "report") {
      return <ExaminationReport
        trimester={currentTrimester}
        slides={slides}
      />;
    } else if (naked === "slide") {
      if (!selectedPlane) return <></>;

      return <div className={`examination-live-container ${!!notificationContext.notifications.length ? 'has-notifications' : ''} status-${examinationContext.examination.status}`}>
        <div className={`examination-live-panel selected-plane ${!!selectedPlane && 'open'}`}>
          {
            (isFeatureFlagEnabled('sonio.detect')
              ? <SlidePanelDetect
                plane={selectedPlane}
                slides={slides}
                filteredSlides={Object.values(filteredSlides).flat()}
                extractedFrames={extractedFrames}
                trimester={currentTrimester}
                checklistItems={checklistItems.filter(item => item.instance_view_id[currentTrimester]?.includes(selectedPlane.slide?.id))}
                checklistItemsRiskFactors={checklistItemsRiskFactors}
                anomalies={anomalies}
                setLastMatchedSlide={setLastMatchedSlide}
                setSelectedPlaneByKey={setSelectedPlaneByKey}
                setSelectedPlaneByMediaId={setSelectedPlaneByMediaId}
                onClose={onPlaneClose}
                setShowSlideBrowser={setShowSlideBrowser}
              />
              : <SlidePanelRoutine
                plane={selectedPlane}
                slides={slides}
                filteredSlides={Object.values(filteredSlides).flat()}
                extractedFrames={extractedFrames}
                trimester={currentTrimester}
                checklistItems={checklistItems.filter(item => item.instance_view_id[currentTrimester]?.includes(selectedPlane.slide?.id))}
                checklistItemsRiskFactors={checklistItemsRiskFactors}
                anomalies={anomalies}
                setLastMatchedSlide={setLastMatchedSlide}
                setSelectedPlaneByKey={setSelectedPlaneByKey}
                setSelectedPlaneByMediaId={setSelectedPlaneByMediaId}
                onClose={onPlaneClose}
                setShowSlideBrowser={setShowSlideBrowser}
              />
            )
          }
        </div>
        {!!showSlideBrowser && (
          isFeatureFlagEnabled("sonio.detect") ? (
            <SlideBrowserDetect
              slides={slides}
              selectedSlideId={showSlideBrowser?.slide?.id}
              trimester={currentTrimester}
              onChange={async (slide) => {
                if (isNullOrUndefined(slide?.id)) return false;
                if (!!showSlideBrowser.media) {
                  const assoc = examinationContext.setInstanceAssociation(slide, showSlideBrowser.media.id);
                  setMedias(medias => medias.map(m => m.id === showSlideBrowser.media.id
                    ? {
                      ...m,
                      slideId: slide.id,
                      idx_in_template: slide.idx_in_template,
                      idx_in_group: assoc.idx_in_group
                    }
                    : m
                  ));
                }
                if (!!showSlideBrowser.callback) showSlideBrowser.callback();
                setShowSlideBrowser(false);
              }}
              onClose={() => setShowSlideBrowser(false)}
            />
          ) : (
            <SlideBrowserRoutine
              slides={slides}
              selectedSlideId={showSlideBrowser?.slide?.id}
              trimester={currentTrimester}
              onChange={async (slide) => {
                if (isNullOrUndefined(slide?.id)) return false;
                if (!!showSlideBrowser.media) {
                  const assoc = examinationContext.setInstanceAssociation(slide, showSlideBrowser.media.id);
                  setMedias(medias => medias.map(m => m.id === showSlideBrowser.media.id
                    ? {
                      ...m,
                      slideId: slide.id,
                      idx_in_template: slide.idx_in_template,
                      idx_in_group: assoc.idx_in_group
                    }
                    : m
                  ));
                }
                if (!!showSlideBrowser.callback) showSlideBrowser.callback();
                setShowSlideBrowser(false);
              }}
              onClose={() => setShowSlideBrowser(false)}
            />
          )
        )}
      </div>
    }

    return false;
  }

  const searchBarWidget = <>
    <div className="examination-live-searchbar">
      {isFeatureFlagEnabled("sonio.checklist") ? <GlobalSearchBar callback={(data) => {
        if (data.type === "checklistitem") {
          const slide = slides.find(slide => data.item.instance_view_id.includes(slide.id));
          if (slide) {
            setSelectedPlaneByKey(slide.key);
          }
        }
      }}/> : <SearchBar/>}
    </div>
  </>

  const filterFeedbackWidget = <>
    <div className="feedback">
      {shownPlanes === "taken" ? <><span>{__("examination.settings.showingTakenPlanes")}</span> <Icon name="close" onClick={() => setShownPlanes("all")}/></> : (
        shownPlanes === "left" ? <><span>{__("examination.settings.showingLeftPlanes")}</span> <Icon name="close" onClick={() => setShownPlanes("all")}/></> :
          shownPlanes === "unusual" ? <><span>{__("examination.settings.showingUnusualPlanes")}</span> <Icon name="close" onClick={() => setShownPlanes("all")}/></> :
            false
      )}
    </div>
  </>

  const displayZoomedView = !windowContext.windowExists("slide") && !!selectedPlane;

  return returnNakedComponents() || (
    <div className={`examination-live-container ${!!notificationContext.notifications.length ? 'has-notifications' : ''} status-${examinationContext.examination.status}`}>
      <div className="examination-live-sidebar">
        {isFeatureFlagEnabled("sonio.detect") && !isFeatureFlagEnabled("sonio.routine")
            ? <ExamNavBarDetect
              medicalHistory={medicalHistory}
              selected={openPanel}
              instances={medias}
              anomalies={anomalies}
              videos={sortPlanesBy === "slide" ? videos : []}
              refs={{
                imageChapterRef: imageChapterRef,
                matchingChapterRef: matchingChapterRef,
                videoChapterRef: videoChapterRef,
              }}
              onOptionClick={(panel) => setOpenPanel(openPanel => openPanel === panel ? false : panel)}
              onChangeSection={(current) => setCurrentSection(current)}
              sexConfirmedBy={sexConfirmedBy}
              sexContradictedBy={sexContradictedBy}
              placentaPositionConfirmedBy={placentaPositionConfirmedBy}
              placentaPositionContradictedBy={placentaPositionContradictedBy}
            />
            : <ExamNavBarRoutine
              medicalHistory={medicalHistory}
              selected={openPanel}
              instances={medias}
              anomalies={anomalies}
              videos={sortPlanesBy === "slide" ? videos : []}
              refs={{
                imageChapterRef: imageChapterRef,
                matchingChapterRef: matchingChapterRef,
                videoChapterRef: videoChapterRef,
              }}
              onOptionClick={(panel) => setOpenPanel(openPanel => openPanel === panel ? false : panel)}
              onChangeSection={(current) => setCurrentSection(current)}
              sexConfirmedBy={sexConfirmedBy}
              sexContradictedBy={sexContradictedBy}
              placentaPositionConfirmedBy={placentaPositionConfirmedBy}
              placentaPositionContradictedBy={placentaPositionContradictedBy}
            />
        }
        {currentTrimester !== "ND" && (
          <div
            className={`examination-live-pause ${isPaused || isManual ? 'paused' : ''} ${isPrintable ? 'printable' : ''} ${(!!openPanel || !!selectedPlane) ? 'compact' : ''}`}
            onClick={async () => { isManual ? await toggleIsManual(false) : await toggleIsPaused(); }}
          >
            <Icon name={isPaused || isManual ? 'play' : 'pause'} />
            <label>{pauseButtonLabel}</label>
            {isPaused && isFeatureFlagEnabled("sonio.share") && (!isFeatureFlagEnabled("sonio.detect") || isFeatureFlagEnabled("sonio.routine")) && <PausedPrintButton
              isPrintable={isPrintable}
              toggleIsPrintable={toggleIsPrintable}
            />}
          </div>
        )}
        <div className={`examination-live-panel panel-phenotype ${openPanel === "phenotype" ? 'open' : ''}`}>
          <div className="panel-close">
            <Icon name="close" onClick={() => setOpenPanel(false)} />
            {isFeatureFlagEnabled('sonio.multiscreen') && !windowContext.isDetached && <Icon name="detach" onClick={() => splitScreen("anamnesis")} />}
          </div>
          <div className="panel-content">
            {openPanel === "phenotype" && (
              <PhenotypePanel
                patient={examinationContext.patient}
                episode={examinationContext.episode}
                anomalies={anomalies}
                medicalHistory={medicalHistory}
                slides={slides}
                trimester={currentTrimester}
                slidesRiskFactors={slidesRiskFactors}
                checklistItems={checklistItems}
                checklistItemsRiskFactors={checklistItemsRiskFactors}
              />
            )}
          </div>
        </div>
        <div className={`examination-live-panel panel-fetus ${openPanel === "fetus" ? 'open' : ''}`}>
          <div className="panel-close">
            <Icon name="close" onClick={() => setOpenPanel(false)} />
          </div>
          <div className="panel-content">
            {openPanel === "fetus" && (
              <FetusPanel
                currentPreset={currentPreset}
                fetalSexField={fetalSexField}
                sexConfirmedBy={sexConfirmedBy}
                sexContradictedBy={sexContradictedBy}
                placentaPositionField={placentaPositionField}
                placentaPositionConfirmedBy={placentaPositionConfirmedBy}
                placentaPositionContradictedBy={placentaPositionContradictedBy}
              />
            )}
          </div>
        </div>
        <div className={`examination-live-panel panel-stats ${openPanel === "stats" ? 'open' : ''}`}>
          <div className="panel-close">
            <Icon name="close" onClick={() => setOpenPanel(false)} />
            {isFeatureFlagEnabled('sonio.multiscreen') && !windowContext.isDetached && <Icon name="detach" onClick={() => splitScreen("report")} />}
          </div>
          <div className="panel-content">
            {openPanel === "stats" && (
              isFeatureFlagEnabled("sonio.report") ? (
                <div className="examination-live-stats-panel-container report">
                  <ExaminationReport
                    slides={slides}
                    trimester={currentTrimester}
                  />
                </div>
              ) : (
                <div className="examination-live-stats-panel-container">
                  <ExaminationReviewView
                    medias={medias}
                    anomalies={anomalies}
                    slides={slides}
                    instanceViews={instanceViews}
                    trimester={currentTrimester}
                    slidesRiskFactors={slidesRiskFactors}
                    checklistItems={checklistItems}
                    checklistItemsRiskFactors={checklistItemsRiskFactors}
                    assocInstanceExam={assocInstanceExam}
                    setSharingStatusForInstance={setSharingStatusForInstance}
                    medicalHistory={medicalHistory}
                    setIsPrintPreviewOpen={setIsPrintPreviewOpen}
                    setIsSharingPreviewOpen={setIsSharingPreviewOpen}
                  />
                </div>
              )
            )}
          </div>
        </div>
        <div className={`examination-live-panel selected-plane ${displayZoomedView && 'open'}`}>
          <div className="panel-close">
            <Icon name="close" onClick={onPlaneClose} />
            {isFeatureFlagEnabled('sonio.multiscreen') && !windowContext.isDetached && <Icon name="detach" onClick={() => splitScreen("slide", [selectedPlane?.slide?.key || selectedPlane?.media?.id])} />}
          </div>
          {displayZoomedView && (
            (isFeatureFlagEnabled('sonio.detect')
              ? <SlidePanelDetect
                plane={selectedPlane}
                slides={slides}
                filteredSlides={Object.values(filteredSlides).flat()}
                extractedFrames={extractedFrames}
                trimester={currentTrimester}
                checklistItems={checklistItems.filter(item => item.instance_view_id[currentTrimester]?.includes(selectedPlane.slide?.id))}
                checklistItemsRiskFactors={checklistItemsRiskFactors}
                anomalies={anomalies}
                setLastMatchedSlide={setLastMatchedSlide}
                setSelectedPlaneByKey={setSelectedPlaneByKey}
                setSelectedPlaneByMediaId={setSelectedPlaneByMediaId}
                onClose={onPlaneClose}
                setShowSlideBrowser={setShowSlideBrowser}
              />
              : <SlidePanelRoutine
                plane={selectedPlane}
                slides={slides}
                filteredSlides={Object.values(filteredSlides).flat()}
                extractedFrames={extractedFrames}
                trimester={currentTrimester}
                checklistItems={checklistItems.filter(item => item.instance_view_id[currentTrimester]?.includes(selectedPlane.slide?.id))}
                checklistItemsRiskFactors={checklistItemsRiskFactors}
                anomalies={anomalies}
                setLastMatchedSlide={setLastMatchedSlide}
                setSelectedPlaneByKey={setSelectedPlaneByKey}
                setSelectedPlaneByMediaId={setSelectedPlaneByMediaId}
                onClose={onPlaneClose}
                setShowSlideBrowser={setShowSlideBrowser}
              />
            )
          )}
        </div>
      </div>
      <div className="examination-live-content">
        {showRoutineBanner && (
          <div className="examination-live-ended-container routine">
            <ExamCompletedBannerRoutine
              medias={medias}
              unusualMedias={unusualMedias}
              instanceViews={instanceViews}
              trimester={currentTrimester}
              setOpenPanel={setOpenPanel}
              setShownPlanes={setShownPlanes}
              setIsPrintPreviewOpen={setIsPrintPreviewOpen}
              setIsSharingPreviewOpen={setIsSharingPreviewOpen}
            />
          </div>
        )}
        {showDetectBanner && (
          <div className="examination-live-ended-container detect">
            <ExamCompletedBannerDetect
              medias={medias}
              unusualMedias={unusualMedias}
              instanceViews={instanceViews}
              trimester={currentTrimester}
              setOpenPanel={setOpenPanel}
              setShownPlanes={setShownPlanes}
              setIsPrintPreviewOpen={setIsPrintPreviewOpen}
              setIsSharingPreviewOpen={setIsSharingPreviewOpen}
            />
          </div>
        )}
        {showTopBar && (
          <div className="examination-live-top">
            <div className="examination-live-trimester">
              {
                examinationContext.canEdit ? (
                  <ExamPresetSelector
                    action="change-preset"
                    size="compact"
                    wrap={false}
                    collapse={true}
                  />
                ) : (
                  <Button
                    label={appContext.allPresets?.find(preset => preset.id === examinationContext.examination.preset_id)?.name}/>
                )
              }
            </div>
            <div className="examination-live-settings">
              <SelectInput
                value={undefined}
                placeholder={{
                  icon: "settings-alt",
                  label: "",
                  value: ""
                }}
                onChange={filterChangeAction}
                options={filterOptions}
              />
              {filterFeedbackWidget}
            </div>
            {searchBarWidget}
            <div>
              {isFeatureFlagEnabled("sonio.share") && ((!isFeatureFlagEnabled("sonio.detect") || isFeatureFlagEnabled("sonio.routine"))) && <NdExaminationToolbar />}
            </div>
            {examinationContext.examination &&
              (!isFeatureFlagEnabled("sonio.detect") || isFeatureFlagEnabled("sonio.routine"))
              && (
                <>
                  <div className="examination-patient-name">
                    <PatientName patient={examinationContext.patient} episode={examinationContext.episode}
                                 examinationDate={examinationContext.examination.examination_date}/>
                  </div>
                  {isFeatureFlagEnabled("sonio.share") &&
                    <SharingIndicator/>
                  }
                </>
            )}
          </div>
        )}
        {showSharingFlowTopBar && ( <>
          <SharingTopBar
            dimButtons={!examinationContext.isCompleted}
            searchBar={searchBarWidget}
            filterFeedback={filterFeedbackWidget}
            filterOptions={filterOptions}
            onFilterChange={filterChangeAction}
            onReport={() => {setOpenPanel("stats")}}
            onShare={()=>{history.push(`/exam/${examinationContext.examination.id}/report/sharing-list`)}}
            onPrint={() => {history.push(`/exam/${examinationContext.examination.id}/report/printing-list`)}}
            showReport={appContext.protocolsEnabled}
          />
          <div className="sharing-flow-top-bar-spacer"></div>
        </>)}
        <div className="examination-live-main">
          {isFeatureFlagEnabled("sonio.detect") ? (
            <SlideGridDetect
              currentTrimester={currentTrimester}
              currentPreset={currentPreset}
              currentSection={currentSection}
              zoomLevel={zoomLevel}
              fetusSex={fetusSex}
              slides={filteredSlides}
              selectedPlane={selectedPlane}
              atRiskSlides={atRiskSlides}
              unusualMedias={unusualMedias}
              medias={medias}
              onItemClick={onClickHandle}
              onItemDoubleClick={onDoubleClickHandle}
              nextMatchingSlide={nextMatchingSlide}
              setShowSlideBrowser={setShowSlideBrowser}
              openPanel={openPanel}
              setOpenPanel={setOpenPanel}
              setSelectedPlane={setSelectedPlane}
              imageChapterRef={imageChapterRef}
              matchingChapterRef={matchingChapterRef}
              videoChapterRef={videoChapterRef}
              highlightNextMatching={highlightNextMatching}
            />
          ) : (
            <SlideGridRoutine
              currentTrimester={currentTrimester}
              currentPreset={currentPreset}
              currentSection={currentSection}
              zoomLevel={zoomLevel}
              fetusSex={fetusSex}
              slides={filteredSlides}
              selectedPlane={selectedPlane}
              atRiskSlides={atRiskSlides}
              unusualMedias={unusualMedias}
              medias={medias}
              onItemClick={onClickHandle}
              onItemDoubleClick={onDoubleClickHandle}
              nextMatchingSlide={nextMatchingSlide}
              setShowSlideBrowser={setShowSlideBrowser}
              openPanel={openPanel}
              setOpenPanel={setOpenPanel}
              setSelectedPlane={setSelectedPlane}
              imageChapterRef={imageChapterRef}
              matchingChapterRef={matchingChapterRef}
              videoChapterRef={videoChapterRef}
              highlightNextMatching={highlightNextMatching}
            />
          )}
        </div>
      </div>
      {!!showSlideBrowser && (
        isFeatureFlagEnabled("sonio.detect") ? (
          <SlideBrowserDetect
            slides={slides}
            selectedSlideId={showSlideBrowser?.slide?.id}
            trimester={currentTrimester}
            onChange={async (slide) => {
              if (isNullOrUndefined(slide?.id)) return false;
              if (!!showSlideBrowser.media) {
                const assoc = examinationContext.setInstanceAssociation(slide, showSlideBrowser.media.id);
                setMedias(medias => medias.map(m => m.id === showSlideBrowser.media.id
                  ? {
                    ...m,
                    slideId: slide.id,
                    idx_in_template: slide.idx_in_template,
                    idx_in_group: assoc.idx_in_group
                  }
                  : m
                ));
              }
              if (!!showSlideBrowser.callback) showSlideBrowser.callback();
              setShowSlideBrowser(false);
            }}
            onClose={() => setShowSlideBrowser(false)}
          />
        ) : (
          <SlideBrowserRoutine
            slides={slides}
            selectedSlideId={showSlideBrowser?.slide?.id}
            trimester={currentTrimester}
            onChange={async (slide) => {
              if (isNullOrUndefined(slide?.id)) return false;
              if (!!showSlideBrowser.media) {
                const assoc = examinationContext.setInstanceAssociation(slide, showSlideBrowser.media.id);
                setMedias(medias => medias.map(m => m.id === showSlideBrowser.media.id
                  ? {
                    ...m,
                    slideId: slide.id,
                    idx_in_template: slide.idx_in_template,
                    idx_in_group: assoc.idx_in_group
                  }
                  : m
                ));
              }
              if (!!showSlideBrowser.callback) showSlideBrowser.callback();
              setShowSlideBrowser(false);
            }}
            onClose={() => setShowSlideBrowser(false)}
          />
        )
      )}
      {isPrintPreviewOpen && (
        isFeatureFlagEnabled("sonio.share") ? (
          <SharingSelect
            examination={examinationContext.examination}
            instances={medias.filter(instance => instance.dicom_media_type === "image")}
            slides={slides}
            close={history.goBack}
            mode="print"
            onReport={() => {setOpenPanel("stats")}}
            onShare={()=>{history.push(`/exam/${examinationContext.examination.id}/report/sharing-list`)}}
          />
        ) : (
          <PrintPreview
            examination={examinationContext.examination}
            instances={medias.filter(instance => instance.dicom_media_type === "image")}
            slides={slides}
            assocInstanceExam={assocInstanceExam}
            close={() => setIsPrintPreviewOpen(false)}
          />
        )
      )}
      {isSharingPreviewOpen && (
        isFeatureFlagEnabled("sonio.share") ? (
          <SharingSelect
            examination={examinationContext.examination}
            instances={medias}
            slides={slides}
            close={history.goBack}
            mode="share"
            onReport={() => {setOpenPanel("stats")}}
            onPrint={() => {history.push(`/exam/${examinationContext.examination.id}/report/printing-list`)}}
          />
        ) : (
          <SharingPreview
            examination={examinationContext.examination}
            instances={medias.filter(instance => instance.dicom_media_type === "image")}
            slides={slides}
            close={() => setIsSharingPreviewOpen(false)}
          />
        )
      )}
    </div>
  );
};

export default withTranslation()(ExaminationView);

import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import Icon from "../../../../atoms/Icon/Icon";
import GaItem from "./GaItem";
import AssignedGaItem from "./AssignedGaItem";
import NonEditableGaItem from "./NonEditableGaItem";

const NONE = "none";

const ReportTemplateGaTable = ({ t: __, props, placeholders, setPlaceholders, reportData, numberOfFetuses, currentExamDate, onEndEditingDating, setAssignedGa, examinationContext, appPreferences, print = false, revertAssignedGa, updateEpisode, setRequiredAndInvalid, showErrors, reportMode, onEndEditing }) => {
  if (!placeholders) return false;

  // TODO: Add more languages
  // const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase(); 
  const currentLanguage = "en";
  const { "column-labels": columnLabels, methods, labels, "assigned-label": assignedLabel } = props;
  const [updatingAssignedGa, setUpdatingAssignedGa] = useState(false);

  const splitSlug = (method) => {
    if (!method) return false;
    const [_, m, s] = method.split('.');
    return { method: m, standard: s || NONE };
  };

  const makeSlug = (method, standard) => `ga.${method}` + (standard !== NONE ? `.${standard}` : "");

  const defaultFetusFromSlug = (slug, currFetus = false) => {
    if (reportData?.dating_methods?.[slug]?.patient_value) return 0;
    return currFetus || 1;
  };


  const makeDatingRow = (fetus, slug, label = false, rowId = false) => {
    rowId = rowId || Math.random().toString(16).slice(2);
    const visible = true;
    return { fetus, slug, rowId, visible, label };
  };

  const nonEditableGaSlugs = ["lmp", "conception_date", "embryo_transfer", "edd", "prev_ultrasound"];

  const makeDefaultLmpAndAssignedRows = () => {
    const rows = nonEditableGaSlugs.map(gaSlug => makeDatingRow(0, `ga.${gaSlug}`, false, gaSlug));
    const assignedRow = { label: (assignedLabel || __("report.gatable.assignedDating")), rowId: "assigned", visible: true };
    return [...rows, assignedRow];
  };

  const getDataToDisplay = () => {
    if (reportData.report?.user_edits?.["dating.table"]?.value) {
      return reportData.report.user_edits["dating.table"].value;
    }
    else {
      // Initial GAs to display based on report template, or default to LMP
      const userLabels = labels?.split("|") || [];
      const rows = (methods?.split("|") || [])
        .flatMap((method, idx) => {
          const slug = `ga.${method}`;
          const label = userLabels[idx] || false;
          if (defaultFetusFromSlug(slug) === 0) return [makeDatingRow(0, slug, label)];
          return [...Array(numberOfFetuses).keys()].map(fetus => makeDatingRow(fetus + 1, slug, label));
        });

      return [...rows, ...makeDefaultLmpAndAssignedRows()];
    }
  };
  const displayData = getDataToDisplay();
  const [currentData, setCurrentData] = useState(displayData);

  let defaultColumnLabels = [
    __("report.gatable.label"),
    __("report.gatable.scandDate"),
    __("report.gatable.method"),
    __("report.gatable.standard"),
    __("report.gatable.currentValue"),
    __("report.gatable.EDD"),
    ""
  ];

  const columnLabelsArray = columnLabels?.split("|") || [];
  for (let i = 0; columnLabelsArray[i]; i++) {
    defaultColumnLabels[i] = columnLabelsArray[i];
  }

  const availableSlugs =
    Object.keys(placeholders || {})
      .filter(key => key.startsWith('ga.') && !key.startsWith('ga.assigned.'));

  const availableStandards = reportData?.dating_standards;

  const methodsWithStandards = availableSlugs.reduce((acc, slug) => {
    const { method, standard } = splitSlug(slug);
    if (!Object.hasOwn(acc, method)) acc[method] = [];
    acc[method].push({ value: standard, label: availableStandards?.[standard]?.[currentLanguage] });
    return acc;
  }, {});


  methodsWithStandards[NONE] = [{ value: NONE, label: availableStandards?.[NONE]?.[currentLanguage] }];

  // TODO: feels a bit hacky, we need better labels in the DB
  const methodsDropdownOptions = Object.entries(methodsWithStandards)
    .sort((a, b) => a[0] >= b[0] ? 1 : -1)
    .map(([method, standards]) => {
      const slug = makeSlug(method, standards[0]?.value);
      const option = { value: method, label: reportData.dating_methods?.[slug]?.label?.[currentLanguage] };
      if (!option.label) option.invisible = true;
      return option;
    })
    .filter(({ value }) => !nonEditableGaSlugs.includes(value));

  const fetusDropdownOptions = [...Array(numberOfFetuses + 1).keys()].map(fetus => ({ value: fetus, label: placeholders["fetus.name"]?.value[fetus] }));
  const showFetusDropDown = numberOfFetuses > 1;

  const doOptimisticUpdate = async (newData) => {
    const oldData = JSON.parse(JSON.stringify(currentData));
    setCurrentData(newData);
    const ok = await onEndEditingDating(newData);
    if (!ok) {
      setCurrentData(oldData);
    }
  };

  const updateRow = (updatedValues, rowId) => {
    const idxToUpdate = currentData.findIndex(row => row.rowId === rowId);
    if (idxToUpdate === -1) return;
    const copy = JSON.parse(JSON.stringify(currentData));
    copy[idxToUpdate] = { ...copy[idxToUpdate], ...updatedValues };
    doOptimisticUpdate(copy);
  };

  const addRow = () => {
    const slug = "ga.none";
    const fetus = defaultFetusFromSlug(slug);
    const newData = [...currentData, makeDatingRow(fetus, slug)];
    doOptimisticUpdate(newData);
  };

  const removeRow = (rowId) => {
    const idxToRemove = currentData.findIndex(row => row.rowId === rowId);
    if (idxToRemove === -1) return;
    const copy = JSON.parse(JSON.stringify(currentData));
    copy.splice(idxToRemove, 1);
    doOptimisticUpdate(copy);
  };

  const getEpisodeValueFromPlaceholders = (fieldName) => {
    return placeholders?.[`episode.${fieldName}`]?.value;
  };

  const onChangeEpisodeDatingField = async (fieldName, value) => {
    if (fieldName === "dateObtained") {
      fieldName = slugDateObtainedMapping[slug];
      value = new Date(value).toISOString().slice(0, 10);
    }
    setPlaceholders((placeholders) => {
      let newPlaceholders = { ...placeholders };
      newPlaceholders[`episode.${fieldName}`] = newPlaceholders[`episode.${fieldName}`] || {};
      newPlaceholders[`episode.${fieldName}`].value = value;
      return newPlaceholders;
    });
    await updateEpisode(fieldName, value);
  };

  const gaItemProps = {
    placeholders,
    reportData,
    currentData,
    currentExamDate,
    methodsWithStandards,
    methodsDropdownOptions,
    fetusDropdownOptions,
    showFetusDropDown,
    updateRow,
    removeRow,
    setAssignedGa,
    updatingAssignedGa,
    setUpdatingAssignedGa,
    splitSlug,
    makeSlug,
    defaultFetusFromSlug,
    updateEpisode,
    appPreferences,
    currentLanguage,
    print,
    reportMode,
    onEndEditing,
    revertAssignedGa
  };

  const invalidValue = () => !placeholders?.["ga.assigned.value"]?.value;

  useEffect(() => {
    if (setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        invalidValue() ?
          prevState.add("ga_table") : prevState.delete("ga_table");
        return prevState;
      });
    }
  }, [placeholders?.["ga.assigned.value"]?.value, setRequiredAndInvalid]);

  const examId = examinationContext?.examination?.id;
  const assignedExam = placeholders["ga.assigned.exam"]?.value;
  const assignedFetus = placeholders["ga.assigned.fetus"]?.value;
  const assignedMethod = placeholders["ga.assigned.method"]?.value;
  const assignedValue = placeholders["ga.assigned.value"]?.value;
  const dateObtained = assignedExam == examId ? reportData?.examination_data?.examination_date : reportData?.previous_exams?.[assignedExam]?.examination_date;
  const gaData = { slug: assignedMethod, examId: assignedExam, fetus: assignedFetus, value: assignedValue, dateObtained, isAssigned: true };

  return (
    <>
      <div className={`ga-table ${numberOfFetuses > 1 ? 'multi-fetal' : ''} ${invalidValue() && showErrors ? 'required-error' : ''}`}>
        <div className={`ga-item column-heading ${showFetusDropDown ? "show-fetus" : ""}`}>
          {defaultColumnLabels.map((label, index) => {
            return <div key={index} className={index === 0 && showFetusDropDown ? "double-col" : ""}>{label || <>&nbsp;</>}</div>;
          })}
        </div>

        <NonEditableGaItem
          data={currentData.find(row => row.rowId === "lmp")}
          gaItemProps={gaItemProps}
          getEpisodeValueFromPlaceholders={getEpisodeValueFromPlaceholders}
          onChangeEpisodeDatingField={onChangeEpisodeDatingField}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          examinationContext={examinationContext}
        />
        <NonEditableGaItem
          data={currentData.find(row => row.rowId === "conception_date")}
          gaItemProps={gaItemProps}
          onChangeEpisodeDatingField={onChangeEpisodeDatingField}
          getEpisodeValueFromPlaceholders={getEpisodeValueFromPlaceholders}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          examinationContext={examinationContext}
        />

        {["ivf", "gift_zift"].includes(getEpisodeValueFromPlaceholders("conception_method")) &&
          <NonEditableGaItem
            data={currentData.find(row => row.rowId === "embryo_transfer")}
            gaItemProps={gaItemProps}
            getEpisodeValueFromPlaceholders={getEpisodeValueFromPlaceholders}
            onChangeEpisodeDatingField={onChangeEpisodeDatingField}
            reportMode={reportMode}
            onEndEditing={onEndEditing}
            examinationContext={examinationContext}
          />
        }

        <NonEditableGaItem
          data={currentData.find(row => row.rowId === "edd")}
          gaItemProps={gaItemProps}
          getEpisodeValueFromPlaceholders={getEpisodeValueFromPlaceholders}
          onChangeEpisodeDatingField={onChangeEpisodeDatingField}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          examinationContext={examinationContext}
        />
        <NonEditableGaItem
          data={currentData.find(row => row.rowId === "prev_ultrasound")}
          gaItemProps={gaItemProps}
          getEpisodeValueFromPlaceholders={getEpisodeValueFromPlaceholders}
          onChangeEpisodeDatingField={onChangeEpisodeDatingField}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          examinationContext={examinationContext}
        />

        {currentData.filter(d => ![...nonEditableGaSlugs, "assigned"].includes(d.rowId)).map((gaData, index) => {
          return <GaItem
            key={index}
            data={gaData}
            gaItemProps={gaItemProps}
            reportMode={reportMode}
            onEndEditing={onEndEditing}
            examinationContext={examinationContext}
          />;
        })}

        <AssignedGaItem
          data={currentData.find(d => d.rowId === "assigned")}
          gaData={gaData}
          selectedAt={placeholders["ga.assigned.selected_at"]?.value}
          gaItemProps={gaItemProps}
          prevUsMeasurement={placeholders["episode.prev_ultrasound_option"]?.value}
          conceptionMethod={placeholders["episode.conception_method"]?.value}
          reportMode={reportMode}
          onEndEditing={onEndEditing}
          examinationContext={examinationContext}
        />
        {reportMode === "edit" &&
          <div className="ga-item add-row" onClick={addRow}>
            <div className="plus-icon">
              <Icon name="add" />
            </div>
          </div>
        }
      </div>
    </>
  );
};

export default withTranslation()(ReportTemplateGaTable);




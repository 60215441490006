import parse from 'html-react-parser';
import { QRCodeSVG } from 'qrcode.react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import TextInput from '../../atoms/TextInput/TextInput';
import { AppContext } from '../../context-providers/App';
import { ExaminationContext } from '../../context-providers/Examination';
import { ExaminationSharingContext } from '../../context-providers/ExaminationSharing';
import { useExamSharingState } from '../../providers/examSharing/index'
import ResourceApi from '../../services/resource';
import './SharingPreviewPopup.css';
import PatientNameValidation, {isPatientNameValid} from "./PatientNameValidation";

const SharingPreviewPopup = ({ t: __, onClose = () => { }, annotate = undefined, documents = undefined }) => {
  const examinationContext = useContext(ExaminationContext);
  const { documentsToggle } = useContext(ExaminationSharingContext);
  const { site } = useContext(AppContext);
  const { totalActiveUploadedDocuments, selectedSharingImages, selectedSharingVideos } = useExamSharingState();

  const history = useHistory();

  const printableFrame = useRef(null);

  const [sharingLabel, setSharingLabel] = useState("");
  const [sharingCode, setSharingCode] = useState("");
  const [shareTo, setShareTo] = useState("");
  const [mountNode, setMountNode] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);

  const [patientNameConfirmed, setPatientNameConfirmed] = useState(false);
  const totalUploadedDocumentsCount = () => {
    if (documentsToggle) {
      return totalActiveUploadedDocuments
    } else {
      return 0
    }
  }
  const totalSharedMedia = totalUploadedDocumentsCount() + selectedSharingImages.length + selectedSharingVideos.length;

  useEffect(() => {
    if (examinationContext?.patient?.email_address) {
      setShareTo(examinationContext.patient.email_address)
    }
  }, [examinationContext?.patient?.email_address])

  useEffect(() => {
    /** awaiting for DOM creation inside the iframe (Firefox only) */
    if (printableFrame.current) printableFrame.current?.addEventListener('load', () => setIframeLoaded(true));
  }, []);

  useEffect(() => {
    if (!iframeLoaded && !printableFrame.current?.contentWindow) return false;
    if (printableFrame.current?.contentWindow?.document?.head.getElementsByClassName("main-style").length > 0) return false;

    const css = document.createElement("style");
    css.className = "main-style";
    const styleString = `
      * {
        margin:0;
        padding:0;
        border:0;
        outline:0;
        vertical-align:baseline;
        background: transparent;
        box-sizing: border-box;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }
      *::-webkit-scrollbar {
        display: none;
      }

      @page {
        margin: 0;
      }

      html,body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }
      body {
        font-size: 13px;
        font-family: "Metropolis", sans-serif;
        text-align: center;
      }

      .print-preview-page {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        min-height: 100%;
        margin-bottom: 15px;
        position: relative;
        padding: 100px 0.4cm 50px 0.4cm;
        background: white;
        color: black;
        font-size: 13px;
      }
      .qr-only-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        margin-left: 20px;
        margin-right: 20px;
      }
      .common-message {
        font-size: 12px;
        text-align: left;
        margin-bottom: 20px;
      }
      .page-header {
        display: flex;
        width: 100%;
        left: 0;
        right: 0;
        background: white;
        top: 0;
        height: 100px;
        min-height: 100px;
        align-items: center;
        position: absolute;
        margin-bottom: 0.1cm;
        padding: 0.1cm 0.3cm;
      }
      .page-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        padding: 0.1cm 0.3cm;
      }
      .page-footer > .powered-by {
        font-size: 11px;
      }
      .page-header > img {
        position: absolute;
        width: 2.4cm;
        height: 2.4cm;
        background: white;
      }
      .page-footer > img {
        width: 0.82cm;
        height: 0.82cm;
        max-width: 0.82cm;
        max-height: 0.82cm;
        background: white;
        margin-left: 0.1cm;
      }
      .page-header > .site-name {
        width: 100%;
        text-align: center;
        font-size: 16px;
      }
      .img-item {
        margin: 0;
        padding: 0;
      }
      .slides-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: 100%;
        column-gap: 1%;
        row-gap: 3%;
        align-content: flex-start;
      }
      .qr-row {
        width: 100%;
      }
      .label-container {
        max-width: 100%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
      }
      .label-container > p {
        text-overflow: ellipsis;
        margin: 0;
        font-size: 12px;
        margin-bottom: 2px;
      }
      .img-container {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      img {
        border:0;
        object-fit: contain;
        width: 100%;
        height: 100%;
        position: relative;
        aspect-ratio: 4 / 3;
        background: #000;
      }
      .img-container.anonymized {
        margin-top: 5%;
      }
      .img-container.anonymized img {
        top: -10%;
      }

      .single-qr-code {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-column: span var(--number-of-columns);
        grid-row-start: span var(--number-of-rows);
      }
      .footer-qr-code {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        margin-top: 2px;
      }

      .footer-qr-code > p, .top-qr-code > p {
        font-size: 11px;
        text-align: left;
      }
      `;
    const style = document.createTextNode(`
      ${styleString}
      @media print {
        html {
          box-sizing: border-box;
          font-size: 12px;
        }
        html, body {
          width: 100%;
          height: 100%;
        }
        *, *:before, *:after {
          box-sizing: inherit;
          margin: 0;
          padding: 0;
        }
        body, h1, h2, h3, h4, h5, h6, p, ol, ul {
          font-weight: normal;
        }
        ol, ul {
          list-style: none;
        }
        .print-preview-page {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          min-height: 100%;
          position: relative;
          padding: 100px 0.4cm 50px 0.4cm;
          background: white;
          color: black;
        }
        .page-header {
          height: 100px;
        }
        .page-footer {
          height: 40px;
        }
        .label-container {
          max-width: 100%;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          margin:0;
          margin-bottom: 2px;
        }
        .label-container > p {
          text-overflow: ellipsis;
          margin: 0;
          font-size: 12px;
        }
        .slides-content {
          display: inline-flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          column-gap: 1%;
          row-gap: 2%;
          align-content: flex-start;
          width: 100%;
        }

        .img-item {
          display: inline-block;
        }
        img {
          border:0;
          object-fit: contain;
          width: 100%;
          height: 100%;
          position: relative;
          aspect-ratio: 4 / 3;
          background: #000;
        }
        .img-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
        .img-container.anonymized {
          margin-top: 5%;
        }
        .img-container.anonymized img {
          top: -10%;
        }
        .footer-qr-code > p, .top-qr-code > p {
          font-size: 13px;
        }
        .common-message {
          font-size: 16px;
        }
      }
    `)
    css.appendChild(style);
    printableFrame.current?.contentWindow?.document?.head.appendChild(css);

    setMountNode(printableFrame.current?.contentWindow?.document?.body);

  }, [iframeLoaded, printableFrame.current?.contentWindow]);


  const onShareInstances = async () => {
    let shareEmails = [];
    if (shareTo) shareEmails = [shareTo];
    setSharingCode("");
    setSharingLabel(__("examinationReview.waitingForSharingCode"));
    await ResourceApi.shareExamination(window.location.origin, examinationContext.examination.id, shareEmails, annotate, documents)
      .then(resp => {
        const newSharingCode = resp.data.share_code;
        setSharingLabel(__("examinationReview.sharingCode"));
        setSharingCode(newSharingCode);
      }).catch(err => {
        setSharingCode("");
        setSharingLabel(err.response.data.message);
      });
  }

  const handleClickMediaCounter = () => {
    history.push(`/exam/${examinationContext.examination.id}/report/sharing-list`)
    onClose();
  }

  const frameComponent = useMemo(() => {
    const Header = () => {
      if (site?.logo_base64 == null || site?.logo_base64 == "") {
        return (
          <div className="page-header">
            <div className="site-name">{site?.name ?? ""}</div>
          </div>
        )
      }
      return (
        <div className="page-header">
          <img src={`data:image/png;base64, ${site.logo_base64}`} />
          <div className="site-name">{site?.name ?? ""}</div>
        </div>
      )
    }
    const Footer = () => {
      return (
        <div className="page-footer">
          <div className="powered-by">{__("branding.poweredBySonio")}</div>
          <img src="/logo192.png" />
        </div>
      )
    }
    const SiteMessage = () => {
      if (site?.brand_message == null || site?.brand_message == "") return null;
      return (
        <div className="common-message">
          {parse(site.brand_message)}
        </div>
      )
    }

    const FullSlide = ({ children }) => {
      return (
        <div className="print-preview-page">
          <Header />
          {children}
          <Footer />
        </div>
      )
    }

    const QrOnlyContent = () => {
      return (
        <FullSlide>
          <div className="qr-only-content">
            <SiteMessage />
            <div className="popup-qr-code">
              <QRCodeSVG
                value={`${examinationContext.share.share_link}&source=qr`}
                size={120}
                level="Q"
                data-test-id="qr-code-2"
                includeMargin={true}
              />
            </div>
          </div>
        </FullSlide>
      )
    }

    return <QrOnlyContent />
  }, [site, examinationContext.share.share_link])

  const printIframe = (ref) => {
    const iframeWindow = ref.current.contentWindow || ref.current;
    iframeWindow.focus();
    iframeWindow.print();
    return false;
  };

  return (
    <div className="examination-review-sharing-preview-popup-container">
      <div className={"examination-review-sharing-preview-popup email-option-open"}>
        <div className="share-popup-close" onClick={onClose}>
          <Icon name="close" />
        </div>
        <div className={"popup-qr-code-ui"}>
          {isPatientNameValid(examinationContext?.patient) || patientNameConfirmed ? <>
            <div className="sharing-popup-title">
              {__("examinationReview.popupTitle.prefix")}
              &nbsp;
              <span className="sharing-popup-title-number" onClick={handleClickMediaCounter}>
                {__(`examinationReview.popupTitle.number`, { count: totalSharedMedia })}
              </span>
              &nbsp;
              {__(`examinationReview.popupTitle.suffix`)}
            </div>
            <div className="sharing-popup-media-files">
              <div className="sharing-popup-media-files-item">
                <Icon name="picture" />
                <span className="count">
                  {__('examinationReview.imageCount.number', { count: selectedSharingImages.length })}
                </span>
              </div>
              <div className="vertical-line" />
              <div className="sharing-popup-media-files-item">
                <Icon name="cinema" />
                <span className="count">
                  {__('examinationReview.videoCount.number', { count: selectedSharingVideos.length })}
                </span>
              </div>
              <div className="vertical-line" />
              <div className="sharing-popup-media-files-item">
                <Icon name="file-with-line" />
                <span className="count">
                  {__('examinationReview.documentCount.number', { count: totalUploadedDocumentsCount() })}
                  </span>
                </div>
            </div>
            <div className="popup-qr-code-outer-container">
              <div className="popup-qr-code-container">
              <div className="sharing-options-label">{__("examinationReview.qrCode.label")}</div>
                {examinationContext.share?.instant_share_link && <div className="popup-qr-code" data-test-instant-qr={`${examinationContext.share.instant_share_link}&source=qr`}>
                  <QRCodeSVG
                    value={`${examinationContext.share.instant_share_link}&source=qr`}
                    size={"100%"}
                    level="Q"
                    data-test-id="qr-code-1"
                    includeMargin={false}
                    bgColor={"var(--color-black)"}
                    fgColor={"var(--color-white)"} />
                </div>}
              </div>
              <div className="sharing-options">
                <div className="sharing-options-label">
                  {__("examinationReview.shareOption.labelPrint")}
                </div>
                <Button
                  label={__("examinationReview.qrCode.imprimer")}
                  variant="outline"
                  icon="print"
                  size="full-width"
                  onClick={() => printIframe(printableFrame)}
                />
                <hr className="sharing-popup-hr" />
                <div className="sharing-options-label">
                  {__("examinationReview.shareOption.labelShare")}
                </div>
                <div className="flex-line-wrapper">
                  <TextInput
                    className="share-patient-email"
                    value={shareTo}
                    fullwidth
                    placeholder={__("examinationReview.shareOption.placeholder")}
                    onChange={setShareTo} />
                  <Button
                    icon="arrow-right"
                    onClick={onShareInstances}
                    disabled={!shareTo || !totalSharedMedia}
                  />
                </div>
                {!!sharingLabel && <label className='success-sharing-label'>
                  <Icon name="done" className='done-icon' />
                  <div>
                    {sharingLabel.split('.').map(text => <span>{text}</span>)}
                    {" "}
                    {!!sharingCode &&  <b>{sharingCode}.</b>}
                  </div>
                </label>
                }
              </div>
            </div>
          </> : <PatientNameValidation onClose={onClose} onConfirm={() => setPatientNameConfirmed(true)}/>
          }
        </div>
      </div>
      <iframe
        style={{ display: "none" }}
        ref={printableFrame}
        title="shared medias">
        {mountNode && createPortal(frameComponent, mountNode)}
      </iframe>
      <div className="modal-background" onClick={onClose} />
    </div>
  )
}

export default withTranslation()(SharingPreviewPopup)

import { useContext, useEffect, useState } from "react";
import {withTranslation} from "react-i18next";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import {presets as presetsConfig} from "../../config";
import UserApi from "../../services/user";
import {AppContext} from "../../context-providers/App";

const PreselectOptions = ({t: __}) => {
  const appContext = useContext(AppContext);
  const [preselectConfiguration, setPreselectConfiguration] = useState(null);

  useEffect(() => {
    UserApi.getSiteSharingPreselection().then((resp) => setPreselectConfiguration(resp.data.preselection));
  }, []);

  const sharingExamNd = preselectConfiguration?.sharing_exam_nd ?? "all";
  const printingExamNd = preselectConfiguration?.printing_exam_nd ?? "none";
  const sharingExamProtocol = preselectConfiguration?.sharing_exam_with_protocol ?? "all";
  const printingExamProtocol = preselectConfiguration?.printing_exam_with_protocol ?? "template";

  const generateOptions = (value, withTemplate = false) => {
    const options = withTemplate ? [
      {label: __("sharingPreferences.preselect.option.none"), value: "none"},
      {label: __("sharingPreferences.preselect.option.template"), value: "template"},
      {label: __("sharingPreferences.preselect.option.all"), value: "all"},
    ] : [
      {label: __("sharingPreferences.preselect.option.none"), value: "none"},
      {label: __("sharingPreferences.preselect.option.all"), value: "all"},
    ];

    return !value ? [
        {label: __("sharingPreferences.preselect.option.notSet"), value: null},
        ...options
      ]
      : options;
  }
  const updatePreselectField = (field, value) => {
    if (value) {
      UserApi.setSiteSharingPreselection(field, value)
        .then((resp) => setPreselectConfiguration(resp.data.preselection));
    }
  };


  return <>
    {appContext.protocolsEnabled ? (<>
      <div className="row">
        <h3>{__("sharingPreferences.preselect.title.printed")}</h3>
      </div>
      <div className="row">
        <div className="w4">{__("sharingPreferences.preselect.examWithoutProtocol")}</div>
        <div className="w8">
          <SelectInput
            value={printingExamNd}
            onChange={(value) => updatePreselectField("printing_exam_nd", value)}
            options={generateOptions(printingExamNd)}
          />
        </div>
      </div>
      <div className="row">
        <div className="w4">{__("sharingPreferences.preselect.examWithProtocol")}</div>
        <div className="w8">
          <SelectInput
            value={printingExamProtocol}
            onChange={(value) => updatePreselectField("printing_exam_with_protocol", value)}
            options={generateOptions(printingExamProtocol, true)}
          />
        </div>
      </div>
      <div className="row">
        <h3>{__("sharingPreferences.preselect.title.shared")}</h3>
      </div>
      <div className="row">
        <div className="w4">{__("sharingPreferences.preselect.examWithoutProtocol")}</div>
        <div className="w8">
          <SelectInput
            value={sharingExamNd}
            onChange={(value) => updatePreselectField("sharing_exam_nd", value)}
            options={generateOptions(sharingExamNd)}
          />
        </div>
      </div>
      <div className="row">
        <div className="w4">{__("sharingPreferences.preselect.examWithProtocol")}</div>
        <div className="w8">
          <SelectInput
            value={sharingExamProtocol}
            onChange={(value) => updatePreselectField("sharing_exam_with_protocol", value)}
            options={generateOptions(sharingExamProtocol)}
          />
        </div>
      </div>
    </>) : (<>
      <div className="row">
        <h3>{__("sharingPreferences.preselect.title.printedAndShared")}</h3>
      </div>
      <div className="row">
        <div className="w4">{__("sharingPreferences.preselect.printedImages")}</div>
        <div className="w8">
          <SelectInput
            value={printingExamNd}
            onChange={(value) => updatePreselectField("printing_exam_nd", value)}
            options={generateOptions(printingExamNd)}
          />
        </div>
      </div>
      <div className="row">
        <div className="w4">{__("sharingPreferences.preselect.sharedImages")}</div>
        <div className="w8">
          <SelectInput
            value={sharingExamNd}
            onChange={(value) => updatePreselectField("sharing_exam_nd", value)}
            options={generateOptions(sharingExamNd)}
          />
        </div>
      </div>
    </>)}
  </>
};
export default withTranslation()(PreselectOptions);

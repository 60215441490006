import LookupApi from "../../services/lookup";

const MedicalHistory = ({ medicalHistory = {} }) => {
  //   console.log(medicalHistory);

  const mhItems = {
    "medicalexam.fetus.sex": ({ value }) => <div>Sex - {value}</div>,
    "wayoflife.alcohol": ({ value }) => <div>Alcohol - {value}</div>,
    // "teratogenicrisks.medications": async ({ value }) => {
    //   const resp = await LookupApi.getMedicationsByIds(value);
    //   return <div>{resp.data?.[0].name.en}</div>;
    // },
  };
  return Object.keys(medicalHistory).map(
    (key) => mhItems[key] && mhItems[key](medicalHistory[key])
  );
};

export default MedicalHistory;

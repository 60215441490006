import Checklist_v1 from "./Checklist_v1";
import Checklist_v2 from "./Checklist_v2";

const ReportTemplateChecklist = ({
  // common
  t: __,
  children,
  props,
  checklistItems = [],
  fetus = 0,
  
  // v1
  placeholders,
  fetuses,
  zones = [],
  onEndEditing,
  onEndEditingChecklist,
  reportMode = "edit",
  showOptions = true,
  
  // v2
  groups = [],
  getPlaceholderWithProps,
  setEditChecklistDialogIsOpen
}) => {
  if (props.status) {
    return <Checklist_v1
      {...{children, props, placeholders, checklistItems, zones, onEndEditing, onEndEditingChecklist, fetus, fetuses, reportMode, showOptions}}
    />
  }

  return <Checklist_v2
    {...{children, props, checklistItems, groups, fetus, getPlaceholderWithProps, setEditChecklistDialogIsOpen}}
  />    
}

export default ReportTemplateChecklist;
import ButtonGroup from "../../../atoms/ButtonGroup/ButtonGroup";
import { withTranslation } from "react-i18next";


const ReportTemplateButtonGroup = ({ t: __, placeholder, onButtonClick }) => {
  return (
    <ButtonGroup
      label={__("fetusSexVisibility.PatientChoiceSelector", { fullName: placeholder.patient_name })}
      value={placeholder.choice}
      options={[
        { value: "visible", label: __("fetusSexVisibility.state.visible") },
        { value: "masked", label: __("fetusSexVisibility.state.masked") },
      ]}
      onChange={onButtonClick}
    />
  );
}

export default withTranslation()(ReportTemplateButtonGroup)

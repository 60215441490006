import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import Button from '../../atoms/Button/Button';
import ProgressRing from '../../atoms/ProgressRing/ProgressRing';
import ExamPresetButtons from '../../components/ExamPresetButtons/ExamPresetButtons';
import { AppContext } from '../../context-providers/App';
import useAuth from "../../context-providers/Auth";
import { ExaminationContext } from '../../context-providers/Examination';
import { formatName } from "../../services/examination";
import LookupApi from "../../services/lookup";
import ResourceApi from '../../services/resource';
import './ExaminationReviewView.css';
import ExaminationReviewOverview from './chapters/Overview';
import ExaminationReviewOverviewPrint from './print/Overview';

const ExaminationReviewView = ({
  t: __,
  itemsAtRisk,
  slides,
  expectedMedias,
  expectedCNEOFMedias,
  takenCNEOFMedias,
  medicalHistory,
  riskFactors,
  teratogenicRisks,
  medias,
  takenMedias,
  anomalies = [],
  checklistItems = [],
}) => {
  const history = useHistory();
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const { user, isFeatureFlagEnabled } = useAuth();

  const { patient, episode, examination, instances, medicalHistoryItems } = examinationContext;

  const [examinationMetadata, setExaminationMetadata] = useState([]);
  const [zones, setZones] = useState([]);
  const [canBeDownloaded, setCanBeDownloaded] = useState(false);
  const [printingPDF, setPrintingPDF] = useState(false);
  const malformations = examination?.malformations || [];
  const currentTrimester = examination.trimester;
  const examinationType = examination.type;

  examination.type = (examination.type || (!!malformations.length ? "diagnostic" : "screening"));

  /** slides */
  const isSlideChecked = (slide, trimester) => !!slide.status || slide.mediaIds?.length >= slide.medias[trimester];

  const pictureSlides = useMemo(() => slides.filter(slide => slide.type === "picture"), [slides])
  const slidesNotChecked = pictureSlides.filter(slide => !isSlideChecked(slide, currentTrimester));

  const cneofSlidesNotChecked = slidesNotChecked.filter(slide => appContext.isSlideInDefaultCategory(slide, currentTrimester));
  let cneofSlidesNotCheckedNumber = cneofSlidesNotChecked.length;
  // TODO: to be refactored: BIP/PC should count as 1 when the first media is present, and 2 when all the medias are present. Not this logic is hardcoded
  const hiddenCneofSlidesNotChecked = ["T2", "T3"].includes(currentTrimester) && cneofSlidesNotChecked.some(slide => slide.id === 1) ? 1 - cneofSlidesNotChecked.find(slide => slide.id === 1)?.mediaIds?.length : 0;
  cneofSlidesNotCheckedNumber += hiddenCneofSlidesNotChecked;

  /** sections management */
  const sectionDashboard = useRef(null);
  const sectionRiskFactors = useRef(null);

  useEffect(() => {
    LookupApi.getExamZone().then(resp => {
      setZones(resp.data.data.slice(0, 5));
    });
  }, [])

  useEffect(() => {
    if (examination?.id) {
      ResourceApi.getExaminationMetadata(examination.id).then(resp => {
        setExaminationMetadata(resp.data.data);
      });
    }
  }, [examination.id]);

  const renderReportHtml = () => renderToString(
    <>
      <div className="page">
        <ExaminationReviewOverviewPrint
          {...{ patient, episode, examination, examinationMetadata, zones, instances, itemsAtRisk, slides, riskFactors, teratogenicRisks, appContext, examinationContext }}
          biometries={[]}
        />
      </div>
    </>
  );

  /**
   * Download PDF report
   */
  const downloadReportAsPDF = async () => {
    setPrintingPDF(true);

    const HTML = renderReportHtml()

    ResourceApi.getPdfFromHtml(examination.id, HTML).then(response => {
      const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      const date = new Date().toJSON().slice(0, 16).replace(/[T|:]/g, "-");
      const patientName = patient?.name ? formatName(patient.name).fullName : __("patients.anonymous");
      const fileName = `Sonio-${date}-${patientName}.pdf`;
      const a = document.createElement('a');
      let blob = new Blob([response.data])
      blob = blob.slice(0, blob.size, "application/pdf");
      a.href = window.URL.createObjectURL(blob);
      if (!isMobile) a.setAttribute('download', fileName);
      a.click();
      a.remove();
      setPrintingPDF(false);
    });
  }

  return (
    <div className="examination-review-container">
      <div className="examination-review">
        {examinationContext.isCompleted() && (
          <div className="examination-review-shortcuts">
            <div className="examination-review-start-new">
              <ExamPresetButtons
                action="new-exam"
                label={__("examinationReview.startNewExam")}
                variant="outline"
                size="compact"
              />
            </div>
          </div>
        )}
        <section className="examination-live-review-chapter dashboard" ref={sectionDashboard}>
          <div className="examination-review-dashboard-cta">
            <h1>{__('examinationReview.report')}</h1>
            {examinationContext.examination.status !== "completed" && (
              <div className="examination-live-review-not-completed">
                {__("examinationReview.notCompleted")}
                <Button
                  variant="outline"
                  label={__("examinationReview.endExamination")}
                  onClick={() => !!examinationContext.endExamination && examinationContext.endExamination()}
                />
              </div>
            )}

            <div className="examination-live-review-stat">
              {examinationContext.examination.trimester !== "ND" ? (
                <>
                  {!!expectedCNEOFMedias && (
                    <div className="examination-live-review-stat-graph">
                      <div>
                        <ProgressRing
                          progress={Math.min(100, 100 / expectedCNEOFMedias * takenCNEOFMedias)}
                          stroke="0.5"
                          label={`${takenCNEOFMedias} / ${expectedCNEOFMedias}`}
                          color={takenCNEOFMedias < expectedCNEOFMedias ? 'ocra' : 'blue'}
                        />
                      </div>
                      <label>
                        {expectedCNEOFMedias - takenCNEOFMedias
                          ? `${expectedCNEOFMedias - takenCNEOFMedias} ${__("examination.stats.missingFeaturedMedias", { category: __(`examination-plane.category.${appContext.preferences.default_category}`) })}`
                          : __("examination.stats.allFeaturedMediasTaken", { category: __(`examination-plane.category.${appContext.preferences.default_category}`) })}
                      </label>
                    </div>
                  )}
                  <div className="examination-live-review-stat-graph">
                    <div>
                      <ProgressRing
                        progress={100 / expectedMedias * takenMedias}
                        stroke="0.5"
                        label={`${takenMedias} / ${expectedMedias}`}
                        color={takenMedias < expectedMedias ? 'ocra' : 'blue'}
                      />
                    </div>
                    <label>{expectedMedias - takenMedias > 0 ? `${expectedMedias - takenMedias} ${__("examination.stats.missingMedias")}` : __("examination.stats.allMediasTaken")}</label>
                  </div>
                </>
              ) : (
                <div className="examination-live-review-stat-text">
                  <span className="count">{medias.length}</span>
                  <span className="label">{__("examination.stats.takenMedias")}</span>
                </div>
              )}
            </div>

            {isFeatureFlagEnabled("sonio.share") && <div className="cta">

              <Button
                label={__('examinationReview.shareImages')}
                icon={isFeatureFlagEnabled("sonio.share") ? "sharing-indicator" : "image"}
                onClick={() => {
                  history.push(`/exam/${examinationContext.examination.id}/#report`)
                  history.push(`/exam/${examinationContext.examination.id}/report/sharing-list`)
                }}
                variant={"outline"}
              />

              <Button
                label={__('examinationReview.printFeaturedImages')}
                icon="print"
                onClick={() => {
                  history.push(`/exam/${examinationContext.examination.id}/#report`)
                  history.push(`/exam/${examinationContext.examination.id}/report/printing-list`)
                }}
                variant={"outline"}
              />

              {!!canBeDownloaded && (
                <Button
                  variant={"outline"}
                  icon="inbox"
                  label={printingPDF ? __('examinationReview.generatingPdf') : __('examinationReview.download')}
                  onClick={() => downloadReportAsPDF()}
                  disabled={printingPDF || !canBeDownloaded}
                />
              )}
            </div>}
          </div>
        </section>

        <section className="examination-live-review-chapter" ref={sectionRiskFactors}>
          <ExaminationReviewOverview
            {...{
              examinationType,
              currentTrimester,
              patient,
              episode,
              examination,
              examinationMetadata,
              zones,
              instances,
              itemsAtRisk,
              slides,
              anomalies,
              checklistItems,
              medicalHistory,
              riskFactors,
              teratogenicRisks,
              appContext,
              examinationContext
            }}
          />
        </section>
      </div>
    </div>
  )
}

export default withTranslation()(ExaminationReviewView)

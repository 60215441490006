import { useCallback, useMemo } from "react";
import { withTranslation } from "react-i18next";
import { getNiceGestionalAgeFromDays } from "../../services/examination";
import "./BiometryGraph.css";
import { SHAPES_AND_COLOURS } from "./biometryGraphConstants";
import { isNullOrUndefined } from "../../utils";

const BiometryGraph = ({ t: __, xAxis, yAxis, measurements, atRisk, equations }) => {
    const currentLanguage = localStorage.getItem("i18nextLng").toLowerCase();

    const svgWidth = 100;
    const svgHeight = 100;
    const svgPadding = 15;

    // Get min x and y values in for measurements
    const minXMeasurementValue = measurements.reduce((min, m) => {
        const minInM = m.reduce((minInner, e) => Math.min(minInner, e.xvalue), Infinity);
        return Math.min(min, minInM);
    }, Infinity);
    const minYMeasurementValue = measurements.reduce((min, m) => {
        const minInM = m.reduce((minInner, e) => Math.min(minInner, e.value), Infinity);
        return Math.min(min, minInM);
    }, Infinity);

    const maxXMeasurementValue = measurements.reduce((min, m) => {
        const minInM = m.reduce((minInner, e) => Math.max(minInner, e.xvalue), -Infinity);
        return Math.max(min, minInM);
    }, -Infinity);
    const maxYMeasurementValue = measurements.reduce((min, m) => {
        const minInM = m.reduce((minInner, e) => Math.max(minInner, e.value), -Infinity);
        return Math.max(min, minInM);
    }, -Infinity);

    const detectMinValue = (axis, percentiles) => {
        const minPercentile = Object.keys(percentiles).sort((a, b) => a - b)?.[0];
        const arrayRef = axis === "x" ? 0 : 1;

        const minPercentileValue = percentiles[minPercentile].sort((a, b) => a[arrayRef] - b[arrayRef])?.[0]?.[
            arrayRef
        ];

        const minMeasurement = axis === "x" ? minXMeasurementValue : minYMeasurementValue;

        const minValue = Math.min(minMeasurement - minMeasurement * 0.5, minPercentileValue);

        return Math.floor(minValue);
    };

    const detectMaxValue = (axis, percentiles) => {
        const maxPercentile = Object.keys(percentiles).sort((a, b) => b - a)?.[0];
        const arrayRef = axis === "x" ? 0 : 1;
        const maxPercentileValue = percentiles[maxPercentile].sort((a, b) => b[arrayRef] - a[arrayRef])?.[0]?.[
            arrayRef
        ];

        const maxMeasurement = axis === "x" ? maxXMeasurementValue : maxYMeasurementValue;

        const maxValue = Math.max(maxMeasurement, maxPercentileValue);
        return maxValue > 1 ? Math.ceil(maxValue) : Math.ceil(maxValue * 10) / 10;
    };

    const detectStep = (axis) => {
        const currentAxis = axis === "x" ? xAxis : yAxis;
        let start = currentAxis?.start;
        let end = currentAxis?.end;
        if (axis === "x") {
            currentAxis.id = equations.horizontal_axis_id;
        }
        if (currentAxis.id === "ga") {
            start = start / 7;
            end = end / 7;
        }

        const steps = [
            0.1, 0.2, 0.5, 1, 2, 5, 10, 20, 30, 50, 100, 200, 300, 400, 500, 750, 1000, 2000, 3000, 4000, 5000, 10000,
            20000, 30000, 40000, 50000, 100000, 200000, 300000, 400000, 500000, 1000000,
        ];
        for (const step of steps) {
            if ((end - start) / step <= 10) return currentAxis.id === "ga" ? step * 7 : step;
        }

        return Math.round((end - start) / 10);
    };

    const detectSteps = (axis) => {
        const [currentAxis, currentMinValue, currentMaxValue] =
            axis === "x"
                ? [xAxis, minXMeasurementValue, maxXMeasurementValue]
                : [yAxis, minYMeasurementValue, maxYMeasurementValue];

        if (currentAxis.start === currentMinValue) {
            currentAxis.start = currentAxis.start - currentAxis.step;
        }
        if (currentAxis.end === currentMinValue) {
            currentAxis.end = currentAxis.end + currentAxis.step;
        }

        const start = Math.ceil(currentAxis.start / currentAxis.step) * currentAxis.step;
        const end = currentAxis.end;
        const steps = [];
        for (let step = start; step <= end; step += currentAxis.step) {
            steps.push(step);
        }
        return steps;
    };

    const detectUnit = (axis) => {
        return axis === "x" ? "weeks" : "mm";
    };

    const roundValue = (value) => {
        const outputSign = value < 0 ? -1 : 1;
        let outputValue = value;
        let outputUnit = "";

        value = Math.abs(value);

        if (value > 1000) {
            outputValue = (value / 1000).toFixed(1);
            outputUnit = "K";
        }

        if (value < 1 || !(value % 1 === 0)) {
            outputValue = value.toFixed(1);
        }

        return outputValue * outputSign + outputUnit;
    };

    const getClassByIndex = (index, total) => {
        if (index === 0) return "first";
        if (index === (total - 1) / 2) return "half";
        if (index === total - 1) return "last";
    };

    // inverse of the standard normal cumulative distribution
    const invStdDistribution = (p) => {
        const a1 = -39.6968302866538,
            a2 = 220.946098424521,
            a3 = -275.928510446969,
            a4 = 138.357751867269,
            a5 = -30.6647980661472,
            a6 = 2.50662827745924;
        const b1 = -54.4760987982241,
            b2 = 161.585836858041,
            b3 = -155.698979859887,
            b4 = 66.8013118877197,
            b5 = -13.2806815528857;
        const c1 = -7.78489400243029e-3,
            c2 = -0.322396458041136,
            c3 = -2.40075827716184,
            c4 = -2.54973253934373,
            c5 = 4.37466414146497,
            c6 = 2.93816398269878;
        const d1 = 7.78469570904146e-3,
            d2 = 0.32246712907004,
            d3 = 2.445134137143,
            d4 = 3.75440866190742;
        const p_low = 0.02425,
            p_high = 1 - p_low;
        let q, r;
        let retVal;

        if (p < 0 || p > 1) retVal = 0;
        else if (p < p_low) {
            q = Math.sqrt(-2 * Math.log(p));
            retVal =
                (((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
                ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
        } else if (p <= p_high) {
            q = p - 0.5;
            r = q * q;
            retVal =
                ((((((a1 * r + a2) * r + a3) * r + a4) * r + a5) * r + a6) * q) /
                (((((b1 * r + b2) * r + b3) * r + b4) * r + b5) * r + 1);
        } else {
            q = Math.sqrt(-2 * Math.log(1 - p));
            retVal =
                -(((((c1 * q + c2) * q + c3) * q + c4) * q + c5) * q + c6) /
                ((((d1 * q + d2) * q + d3) * q + d4) * q + 1);
        }

        return retVal;
    };

    const equationToJS = (eq) => {
        eq = ` ${eq} `?.replace(/log ?\(/gi, "Math.log(");
        eq = ` ${eq} `?.replace(/exp ?\(/gi, "Math.exp(");
        return eq;
    };

    // TODO: rename min/max ga field
    const xAxisOptions = {
        ga: {
            start: Math.min(minXMeasurementValue, equations.minimum_ga * 7 || 6 * 7),
            end: Math.max(maxXMeasurementValue, equations.maximum_ga * 7 || 40 * 7),
            units: "weeks",
            start_curve: equations.minimum_ga * 7 || 6 * 7,
            end_curve: equations.maximum_ga * 7 || 40 * 7,
        },
        efw: {
            start: Math.min(minXMeasurementValue - 14, equations.minimum_ga || 0),
            end: Math.max(maxXMeasurementValue, equations.maximum_ga || 4500),
            units: "g",
            start_curve: equations.minimum_ga || 0,
            end_curve: equations.maximum_ga || 4500,
        },
        crl: {
            start: Math.min(minXMeasurementValue - 14, equations.minimum_ga || 0),
            end: Math.max(maxXMeasurementValue, equations.maximum_ga || 100),
            units: "mm",
            start_curve: equations.minimum_ga || 0,
            end_curve: equations.maximum_ga || 100,
        },
    };

    xAxis = xAxis ? { ...xAxis, ...xAxisOptions[xAxis.id] } : {};
    xAxis.step = xAxis?.step ?? detectStep("x");
    xAxis.steps = xAxis.steps ?? detectSteps("x");

    const invStdDistributionValues = useMemo(() => {
        return {
            3: invStdDistribution(0.03),
            10: invStdDistribution(0.1),
            90: invStdDistribution(0.9),
            97: invStdDistribution(0.97),
        };
    }, [invStdDistribution]);

    let percentiles;

    if (!equations.percentile_equations) {
        // NORMAL FLOW

        percentiles = useMemo(() => {
            const min = xAxis.start_curve;
            const max = xAxis.end_curve;
            const output = {
                5: [],
                10: [],
                50: [],
                90: [],
                95: [],
            };
            for (let step = min; step <= max; step++) {
                const EFW = step;
                const CRL = step;
                const GA = step / 7; // used inside eval
                const eq = equationToJS(equations.equation);
                const sd = equationToJS(equations.sd_equation);
                const eq_result = eval(eq);
                const sd_result = eval(sd);

                const inverseEquation = equationToJS(equations.inverse_transformation_equation || "x");
                const calcValue = (invStdDVal) => {
                    let x = eq_result + invStdDVal * sd_result; // used inside eval
                    return Math.max(0, eval(inverseEquation));
                };

                output[5].push([step, calcValue(invStdDistributionValues[3])]);
                output[10].push([step, calcValue(invStdDistributionValues[10])]);
                output[50].push([step, calcValue(0)]);
                output[90].push([step, calcValue(invStdDistributionValues[90])]);
                output[95].push([step, calcValue(invStdDistributionValues[97])]);
            }
            return output;
        }, [equations?.equation, xAxis.start, xAxis.end, xAxis.step]);
    } else {
        // Mutliple equations flow

        percentiles = useMemo(() => {
            const min = xAxis.start;
            const max = xAxis.end;
            const output = Object.keys(equations.percentile_equations).reduce((acc, curr) => {
                acc[curr] = [];
                return acc;
            }, {});
            for (let step = min; step <= max; step++) {
                const EFW = step;
                const CRL = step;
                const GA = step / 7; // used inside eval
                const inverseEquation = equationToJS(equations.inverse_transformation_equation || "x");

                Object.entries(equations.percentile_equations).forEach(([key, eq]) => {
                    eq = equationToJS(eq);
                    let x = eval(eq); // used inside eval
                    const result = Math.max(0, eval(inverseEquation));
                    output[key].push([step, result]);
                });
            }
            return output;
        }, [equations?.equation, xAxis.start, xAxis.end, xAxis.step]);
    }
    yAxis = yAxis ? { ...yAxis } : {};

    yAxis.start = yAxis?.start ?? detectMinValue("y", percentiles);
    yAxis.end = yAxis?.end ?? detectMaxValue("y", percentiles);
    yAxis.step = yAxis?.step ?? detectStep("y");
    yAxis.steps = yAxis?.steps ?? detectSteps("y");
    yAxis.units = yAxis?.units ?? detectUnit("y");

    // xAxis.start = Math.floor(percentiles[97]?.filter(p => p[1] === 0).sort((a, b) => b[0] - a[0])?.[0]?.[0]) ?? xAxis.start;

    const xAxisRatio = svgWidth / (xAxis.end - xAxis.start);
    const yAxisRatio = svgHeight / (yAxis.end - yAxis.start);

    const curves = Object.entries(percentiles)
        .sort((a, b) => a[0] - b[0])
        .map(([percentile, line]) => ({
            percentile,
            line: line
                .sort((a, b) => a[0] - b[0])
                .map((point) => [
                    (point[0] - xAxis.start) * xAxisRatio,
                    svgHeight - (point[1] - yAxis.start) * yAxisRatio,
                ]),
        }));

    const formatMeasurements = (measurements) => {
        return measurements
            ?.filter((m) => m)
            .map((measurement) => [
                (measurement.xvalue - xAxis.start) * xAxisRatio,
                svgHeight - (measurement.value - yAxis.start) * yAxisRatio,
                measurement.xvalue,
                measurement.value,
            ]);
    };

    return (
        <div className="biometry-wrapper">
            <svg
                viewBox={`0 0 ${svgWidth + svgPadding * 2} ${svgHeight + svgPadding * 2}`}
                className={atRisk ? "at-risk" : ""}
            >
                <rect className="background" x={svgPadding} y={svgPadding} width={svgWidth} height={svgHeight} />
                {/* X axis labels */}
                <g className="x-axis" transform={`translate(${svgPadding}, ${svgHeight + svgPadding})`}>
                    <text
                        x={svgWidth / 2}
                        y={(svgPadding - 6) / 2 + 6}
                        textAnchor="middle"
                        dominantBaseline="middle"
                        className="label"
                    >
                        <tspan>{xAxis?.label?.[currentLanguage]}</tspan>
                        <tspan className="unit">&nbsp;({xAxis?.units})</tspan>
                    </text>
                    <line x1="0" y1="0" x2={svgWidth} y2="0" />
                    {xAxis?.steps.map((step, index) => {
                        const x = (svgWidth / (xAxis?.end - xAxis?.start)) * (step - xAxis?.start);
                        return (
                            <g key={index}>
                                <line x1={x} y1="0" x2={x} y2="2" />
                                <text x={x} y="3" textAnchor="middle" dominantBaseline="hanging">
                                    {roundValue(xAxis?.id === "ga" ? step / 7 : step)}
                                </text>
                            </g>
                        );
                    })}
                </g>
                ;{/* Y axis labels */}
                <g className="y-axis" transform={`translate(${svgPadding}, ${svgPadding})`}>
                    <text x="-3" y="-3" textAnchor="end" className="label">
                        <tspan className="unit">{!yAxis?.units || yAxis.units === "null" ? "" : yAxis.units}</tspan>
                    </text>
                    <line x1="0" y1="0" x2="0" y2={svgHeight} />
                    {yAxis?.steps.map((step, index) => {
                        const y = svgHeight - (svgHeight / (yAxis?.end - yAxis?.start)) * (step - yAxis?.start);
                        return (
                            <g key={index}>
                                <line x1="0" y1={y} x2="-2" y2={y} />
                                <text x="-3" y={y} textAnchor="end" dominantBaseline="middle">
                                    {roundValue(step)}
                                </text>
                            </g>
                        );
                    })}
                </g>
                ;{/* Percentile curves */}
                <g className="percentiles" transform={`translate(${svgPadding}, ${svgPadding})`}>
                    {curves.map((curve, index) => (
                        <g key={index} className={getClassByIndex(index, curves.length)}>
                            <path
                                d={curve.line
                                    .map((point, index) => (index === 0 ? "M " : "L ") + point[0] + " " + point[1])
                                    .join(" ")}
                            />
                            <text x={svgWidth} y={curve.line.slice(-1)[0][1] + 1}>
                                <tspan className="value">{curve.percentile}</tspan>
                                <tspan className="abbreviation">{__("report.percentile.abbreviation")}</tspan>
                            </text>
                        </g>
                    ))}
                </g>
                {measurements.map((values, index) => {
                    return (
                        <BiometryGraphPoint
                            values={formatMeasurements(values)}
                            svgPadding={svgPadding}
                            svgHeight={svgHeight}
                            yAxis={yAxis}
                            index={index}
                            key={index}
                            xAxis={xAxis}
                        />
                    );
                })}
            </svg>
        </div>
    );
};

export default withTranslation()(BiometryGraph);

const BiometryGraphPoint = withTranslation()(({ t: __, values, svgPadding, svgHeight, yAxis, xAxis, index: i }) => {
    const { path, colour } = SHAPES_AND_COLOURS[i] || SHAPES_AND_COLOURS[0];
    return (
        <g className="measurements" transform={`translate(${svgPadding}, ${svgPadding})`}>
            <path
                d={values.map((point, index) => (index === 0 ? "M " : "L ") + point[0] + " " + point[1]).join(" ")}
                style={{ stroke: colour }}
            />
            {values.map((point, index) => (
                <g className="point" key={index}>
                    <line x1="0" y1={point[1]} x2={point[0]} y2={point[1]} style={{ stroke: colour }} />
                    <line x1={point[0]} y1={point[1]} x2={point[0]} y2={svgHeight} style={{ stroke: colour }} />
                    <path d={path} transform={`translate(${point[0]}, ${point[1]})`} style={{ fill: colour }} />
                    <text x="0" y={point[1]} textAnchor="middle" dominantBaseline="text-bottom" paintOrder="stroke">
                        <tspan className="x-axis-value" x={point[0]} dy="-2.2em">
                            {xAxis.id === "ga"
                                ? getNiceGestionalAgeFromDays(__, point[2])
                                : `${point[2]} ${xAxis.units}`}
                        </tspan>
                        <tspan className="y-axis-value" x={point[0]} dy="1em">
                            {point[3] +
                                " " +
                                (isNullOrUndefined(yAxis?.units) || yAxis.units === "null" ? "" : yAxis.units)}
                        </tspan>
                    </text>
                </g>
            ))}
        </g>
    );
});

import { useContext, useState } from 'react';
import { withTranslation } from "react-i18next";
import { useHistory, useParams } from 'react-router-dom';
import PatientName from "../../components/PatientName/PatientName";
import Button from '../../atoms/Button/Button';
import { WindowContext } from '../../context-providers/Window';
import { ExaminationContext } from '../../context-providers/Examination';
import { XMLTemplateContext } from '../../context-providers/XMLTemplate';
import "./ExaminationCustomMedicalHistoryView.css";
import XMLToReact from 'xml-to-react';
import useAuth from "../../context-providers/Auth";

import ReportTemplateDocument from '../ExaminationReport/template/Document';
import ReportTemplateHeading from '../ExaminationReport/template/Heading';
import ReportTemplatePageFooter from '../ExaminationReport/template/PageFooter';
import ReportTemplateValue from '../ExaminationReport/template/Value';
import ReportTemplateHeader from '../ExaminationReport/template/Header';
import ReportTemplateBlock from '../ExaminationReport/template/Block';
import ReportTemplateLabel from "../ExaminationReport/template/Label";
import ReportTemplateContent from "../ExaminationReport/template/Content";
import ReportTemplateDropdown from "../ExaminationReport/template/Dropdown";
import ReportTemplateOption from '../ExaminationReport/template/Option';
import ReportTemplateButtonGroup from "../ExaminationReport/template/ButtonGroup";
import ReportTemplateFetus from "../ExaminationReport/template/Fetus";
import ReportTemplateEmpty from "../ExaminationReport/template/Empty";
import ReportTemplateIf from "../ExaminationReport/template/If";
import ReportTemplateImage from "../ExaminationReport/template/Image";
import ReportTemplateImages from "../ExaminationReport/template/Images";
import ReportTemplateLogo from "../ExaminationReport/template/Logo";
import ReportTemplateSpacer from "../ExaminationReport/template/Spacer";
import ReportTemplateTable from "../ExaminationReport/template/Table";
import ReportTemplateTextBlock from "../ExaminationReport/template/TextBlock";
import ReportTemplateCondition from '../ExaminationReport/template/Condition';
import ReportTemplateElse from '../ExaminationReport/template/Else';
import ReportTemplatePrivate from '../ExaminationReport/templatePrint/Private';
import ReportTemplateGraphs from '../ExaminationReport/template/Graphs';
import ReportTemplateMeasurementCurve from '../ExaminationReport/template/MeasurementCurve';
import ReportTemplateMeasurementCurveLegend from '../ExaminationReport/template/MeasurementCurveLegend';
import ReportTemplateGaTable from '../ExaminationReport/template/GaTable/GaTable';
import ReportTemplateBiometryTable from '../ExaminationReport/template/BiometryTable';
import ReportTemplateBiometryItem from '../ExaminationReport/template/BiometryItem';
import ReportTemplateFetalGrowthTable from '../ExaminationReport/template/FetalGrowthTable';
import ReportTemplateMedicalHistory from '../ExaminationReport/template/MedicalHistory';
import ReportTemplatePageBreak from '../ExaminationReport/template/PageBreak';

const ExaminationCustomMedicalHistoryView = ({ t: __, isFeatureFlagEnabled, reportData, placeholders, setPlaceholders, getPlaceholderWithProps, appContext, checkCondition, onEndEditing, onEndEditingDating, editingFieldId, startEditingField, startEditing, setAssignedGa, revertAssignedGa, updateEpisode }) => {
  const windowContext = useContext(WindowContext);
  const examinationContext = useContext(ExaminationContext);
  const XMLTemplate = useContext(XMLTemplateContext);
  const { siteFlowsConnectors, user } = useAuth();
  const templateBlueprint = examinationContext.debugTemplate || reportData?.medical_history_template?.blueprint;

  const { examId } = useParams();
  const history = useHistory();

  const [requiredAndInvalid, setRequiredAndInvalid] = useState(new Set());
  const [showErrors, setShowErrors] = useState(false);

  let fetusNumber = 1;
  const numberOfFetuses = placeholders["patient.nb_fetuses"]?.value || 0;
  const fetusNames = placeholders["fetus.name"]?.value || [];
  const fetuses = [{id: null}].concat(examinationContext.examination.fetuses);

  const backToExamination = (url = false) => {
    // save modifications before leaving (avoid waiting examinationContext timeout)
    examinationContext.updateExamination({ ...examinationContext.examination });
    examinationContext.updateEpisode({ ...examinationContext.episode });

    if (url === false) url = `/exam/${examId}`;

    if (url.match(new RegExp(`^\/exam\/${Number(examId)}$`)) && isFeatureFlagEnabled("soniopedia")) url += "/dx";

    history.push(url);
  }


  const commonProps = {
    reportMode: "edit",
    user,
    reportData,
    appPreferences: appContext?.preferences,
    examinationContext,
    canEdit: examinationContext.canEdit,
    requiredAndInvalid,
    setRequiredAndInvalid,
    showErrors,
    onEndEditing,
    numberOfFetuses,
    fetuses,
    fetusNames,
  };

  const dropdownProps = {
    loadDynamicDropdownFullTree: XMLTemplate.loadDynamicDropdownFullTree,
    onEndEditingDynamicDropdown: XMLTemplate.onEndEditingDynamicDropdown
  }

  const xmlToReact = new XMLToReact({
    snippet: (props) => ({ type: ReportTemplateEmpty, props: { props, type: "snippet", ...commonProps } }),
    section: (props) => ({ type: ReportTemplateEmpty, props: { props, type: "section", showOptions: false, ...commonProps } }),
    block: (props) => ({ type: ReportTemplateBlock, props: { props, ...commonProps } }),
    buttongroup: (props) => ({ type: ReportTemplateButtonGroup, props: { props, placeholder: { patient_name: name, choice: "masked" }, onEndEditing, showOptions: false } }),
    column: (props) => ({ type: ReportTemplateEmpty, props: { props, type: "column", showOptions: false, ...commonProps } }),
    content: (props) => ({ type: ReportTemplateContent, props: { props, placeholder: getPlaceholderWithProps(props, "content"), ...commonProps } }),
    "labelled-content": (props) => ({ type: ReportTemplateContent, props: { props, placeholder: getPlaceholderWithProps(props, "content"), ...commonProps } }),
    document: (props) => ({ type: ReportTemplateDocument, props: { props, startEditing, placeholder: () => { }, ...commonProps } }),
    dropdown: (props) => ({ type: ReportTemplateDropdown, props: { props, placeholder: getPlaceholderWithProps(props), ...dropdownProps, ...commonProps } }),
    fetus: (props) => ({ type: ReportTemplateFetus, props: { props, ...commonProps } }),
    option: (props) => ({ type: ReportTemplateOption, props: { props, type: "option", ...commonProps } }),
    condition: (props) => ({ type: ReportTemplateCondition, props: { props, getPlaceholderWithProps, checkCondition, ...commonProps } }),
    if: (props) => ({ type: ReportTemplateIf, props: { props, getPlaceholderWithProps, type: "if", checkCondition, ...commonProps } }),
    else: (props) => ({ type: ReportTemplateElse, props: { props, type: "else", ...commonProps } }),
    image: (props) => ({ type: ReportTemplateImage, props: { props } }),
    images: (props) => ({ type: ReportTemplateImages, props: { props, placeholder: getPlaceholderWithProps(props), base64Images, setBase64Images } }),
    label: (props) => ({ type: ReportTemplateLabel, props: { props } }),
    logo: (props) => ({ type: ReportTemplateLogo, props: { props, url: placeholders["logo.url"] } }),
    "measurement-curve": (props) => ({ type: ReportTemplateMeasurementCurve, props: { props, type: "measurement-curve", reportData, placeholder: placeholders[`measurement.${props.data}`], placeholders, editingFieldId, startEditingField, onEndEditing } }),
    "measurement-curve-legend": (props) => ({ type: ReportTemplateMeasurementCurveLegend, props: { props, type: "measurement-curve-legend", numberOfFetuses, names: fetusNames, fetusNumber } }),
    "ga-table": (props) => ({ type: ReportTemplateGaTable, props: { props, placeholders, setPlaceholders, currentExamDate: reportData?.examination_data?.examination_date, onEndEditingDating, setAssignedGa, revertAssignedGa, updateEpisode, ...commonProps } }),
    "biometry-table": (props) => ({ type: ReportTemplateBiometryTable, props: { props, numberOfFetuses, fetusNumber, fetusNames } }),
    "biometry-item": (props) => ({ type: ReportTemplateBiometryItem, props: { props, reportData, placeholder: placeholders[`measurement.${props.data}`], placeholders, fetusNumber, numberOfFetuses, fetusNames, editingFieldId, startEditingField } }),
    "fetal-growth-table": (props) => ({ type: ReportTemplateFetalGrowthTable, props: { props, reportData, placeholders, fetusNumber, numberOfFetuses, fetusNames, appPreferences: appContext?.preferences, onEndEditing } }),
    graphs: (props) => ({ type: ReportTemplateGraphs, props: { props, mode: "edit" } }),
    header: (props) => ({ type: ReportTemplateHeader, props: { props, reportData } }),
    heading: (props) => ({ type: ReportTemplateHeading, props: { props } }),
    "page-break": (props) => ({ type: ReportTemplatePageBreak, props: { props } }),
    private: (props) => ({ type: ReportTemplatePrivate, props: { props } }),
    search: (props) => ({ type: ReportTemplateSearch, props: { props, placeholder: getPlaceholderWithProps(props), carryForward: () => { }, ...commonProps } }),
    spacer: (props) => ({ type: ReportTemplateSpacer, props: { props } }),
    table: (props) => ({ type: ReportTemplateTable, props: { props, placeholder: getPlaceholderWithProps(props, "table"), carryForward: () => { }, showOptions: false, ...dropdownProps, ...commonProps } }),
    textblock: (props) => ({ type: ReportTemplateTextBlock, props: { props, placeholder: getPlaceholderWithProps(props, "textarea"), ...commonProps } }),
    "medical-history": (props) => ({ type: ReportTemplateMedicalHistory, props: { props, medicalHistory: reportData.medical_history, isFeatureFlagEnabled } }),
    value: (props) => ({ type: ReportTemplateValue, props: { props, placeholder: getPlaceholderWithProps(props), ...commonProps } }),
    "page-footer": (props) => ({ type: ReportTemplatePageFooter, props: { props } }),
    "page-header": (props) => ({ type: ReportTemplatePageHeader, props: { props } }),
  });
  const templateReactTree = xmlToReact.convert(templateBlueprint?.trim());

  return (
    <div className="custom-medical-history-container">
      {!!templateBlueprint && (
        <>
          <div className="custom-medical-history-header">
            <PatientName patient={examinationContext.patient} episode={examinationContext.episode} examinationDate={examinationContext.examination.examination_date} />
          </div>
          {templateReactTree}
        </>
      )}

      {!windowContext.isDetached && (
        <div className="examination-medical-history-cta-bar">
          {appContext.referer.match(new RegExp(`^/exam/${Number(examId)}/dx$`)) ? (
            <Button label={__("examination.backToExamination")} iconAfter="right-big" onClick={() => backToExamination(appContext.referer)} />
          ) : appContext.referer.match(new RegExp(`^/exam/${Number(examId)}$`)) ? (
            <Button label={__("examination.backToExamination")} iconAfter="right-big" onClick={() => backToExamination(appContext.referer)} />
          ) : examinationContext.examination.status === "draft" ? (
            <>
              <Button label={__("examination.backToTheDashboard")} icon="home" onClick={() => backToDashboard()} />
              <Button label={__("examination.startTheExamination")} variant="outline" iconAfter="right-big" onClick={() => backToExamination()} />
            </>
          ) : (
            <Button label={examinationContext.examination.status !== "draft" ? __("examination.backToExamination") : __("examination.startTheExamination")} iconAfter="right-big" onClick={() => backToExamination()} />
          )}
        </div>
      )}
    </div>
  )
}

export default withTranslation()(ExaminationCustomMedicalHistoryView)

export const grouping = [
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t1",
        order: 1,
        measurement_slug: "crl",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t1",
        order: 2,
        measurement_slug: "nt",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t1",
        order: 3,
        measurement_slug: "mean_sac_diameter1",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t1",
        order: 4,
        measurement_slug: "mean_sac_diameter2",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t1",
        order: 5,
        measurement_slug: "mean_sac_diameter3",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 1,
        measurement_slug: "bpd",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 2,
        measurement_slug: "hc",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 3,
        measurement_slug: "ofd",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 4,
        measurement_slug: "ac",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 5,
        measurement_slug: "fl",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 6,
        measurement_slug: "hl",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 7,
        measurement_slug: "hc_ac_ratio",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 8,
        measurement_slug: "efw",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 9,
        measurement_slug: "nasal_bone",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 10,
        measurement_slug: "rad_l",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 11,
        measurement_slug: "ulna_l",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 12,
        measurement_slug: "tib_l",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 13,
        measurement_slug: "fib_l",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 15,
        measurement_slug: "fl_ac_ratio",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 16,
        measurement_slug: "fl_bpd_ratio",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "t2_t3",
        order: 17,
        measurement_slug: "fl_hc_ratio",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "amniotic_fluid",
        order: 1,
        measurement_slug: "q1.amniotic_sac",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "amniotic_fluid",
        order: 2,
        measurement_slug: "q2.amniotic_sac",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "amniotic_fluid",
        order: 3,
        measurement_slug: "q3.amniotic_sac",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "amniotic_fluid",
        order: 4,
        measurement_slug: "q4.amniotic_sac",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "amniotic_fluid",
        order: 5,
        measurement_slug: "mvp",
    },
    {
        section: "fetal_biometry",
        subsection: "standard",
        subsubsection: "amniotic_fluid",
        order: 6,
        measurement_slug: "afi.amniotic_sac",
    },
    {
        section: "fetal_biometry",
        subsection: "head_face_neck",
        subsubsection: "head",
        order: 1,
        measurement_slug: "bpd_ofd_ratio",
    },
    {
        section: "fetal_biometry",
        subsection: "head_face_neck",
        subsubsection: "head",
        order: 2,
        measurement_slug: "lv_ah_width",
    },
    {
        section: "fetal_biometry",
        subsection: "head_face_neck",
        subsubsection: "head",
        order: 3,
        measurement_slug: "lv_ph_width",
    },
    {
        section: "fetal_biometry",
        subsection: "head_face_neck",
        subsubsection: "head",
        order: 4,
        measurement_slug: "tcd",
    },
    {
        section: "fetal_biometry",
        subsection: "head_face_neck",
        subsubsection: "head",
        order: 5,
        measurement_slug: "cm",
    },
    {
        section: "fetal_biometry",
        subsection: "head_face_neck",
        subsubsection: "head",
        order: 6,
        measurement_slug: "cerebellar_vermis_sagittal_cc_diam",
    },
    {
        section: "fetal_biometry",
        subsection: "head_face_neck",
        subsubsection: "head",
        order: 12,
        measurement_slug: "intercranial_translucency",
    },
    {
        section: "fetal_biometry",
        subsection: "head_face_neck",
        subsubsection: "face_neck",
        order: 3,
        measurement_slug: "nasal_bone",
    },
    {
        section: "fetal_biometry",
        subsection: "head_face_neck",
        subsubsection: "face_neck",
        order: 4,
        measurement_slug: "nt",
    },
    {
        section: "fetal_biometry",
        subsection: "head_face_neck",
        subsubsection: "face_neck",
        order: 5,
        measurement_slug: "nf",
    },
    {
        section: "fetal_biometry",
        subsection: "thorax_lungs",
        subsubsection: "thorax",
        order: 1,
        measurement_slug: "thorax_circ.thorax",
    },
    {
        section: "fetal_biometry",
        subsection: "thorax_lungs",
        subsubsection: "thorax",
        order: 2,
        measurement_slug: "thorax_area.thorax",
    },
    {
        section: "fetal_biometry",
        subsection: "thorax_lungs",
        subsubsection: "thorax",
        order: 3,
        measurement_slug: "ctr.thorax",
    },
    {
        section: "fetal_biometry",
        subsection: "abdomen_urinary_tract",
        subsubsection: "abdomen",
        order: 1,
        measurement_slug: "anterior_posterior_thoracic_diameter",
    },
    {
        section: "fetal_biometry",
        subsection: "abdomen_urinary_tract",
        subsubsection: "abdomen",
        order: 2,
        measurement_slug: "transverse_thoracic_diameter",
    },
    {
        section: "fetal_biometry",
        subsection: "abdomen_urinary_tract",
        subsubsection: "right_kidney",
        order: 1,
        measurement_slug: "right_pelvis",
    },
    {
        section: "fetal_biometry",
        subsection: "abdomen_urinary_tract",
        subsubsection: "right_kidney",
        order: 2,
        measurement_slug: "right_kidney_w",
    },
    {
        section: "fetal_biometry",
        subsection: "abdomen_urinary_tract",
        subsubsection: "right_kidney",
        order: 4,
        measurement_slug: "right_kidney_l",
    },
    {
        section: "fetal_biometry",
        subsection: "abdomen_urinary_tract",
        subsubsection: "left_kidney",
        order: 1,
        measurement_slug: "left_pelvis",
    },
    {
        section: "fetal_biometry",
        subsection: "abdomen_urinary_tract",
        subsubsection: "left_kidney",
        order: 2,
        measurement_slug: "left_kidney_w",
    },
    {
        section: "fetal_biometry",
        subsection: "abdomen_urinary_tract",
        subsubsection: "left_kidney",
        order: 4,
        measurement_slug: "left_kidney_l",
    },
    {
        section: "fetal_biometry",
        subsection: "extremities_bony_structures",
        subsubsection: "lower_extremities",
        order: 1,
        measurement_slug: "right_fl",
    },
    {
        section: "fetal_biometry",
        subsection: "extremities_bony_structures",
        subsubsection: "lower_extremities",
        order: 6,
        measurement_slug: "left_fl",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "heart",
        order: 4,
        measurement_slug: "cardiac_circ.thorax",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "heart",
        order: 5,
        measurement_slug: "heart_area.thorax",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "heart",
        order: 7,
        measurement_slug: "ctcr.thorax",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "heart",
        order: 8,
        measurement_slug: "ctar.thorax",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "heart",
        order: 9,
        measurement_slug: "cardiac_axis.thorax",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 2,
        measurement_slug: "main_pulmonary_artery_diameter",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 3,
        measurement_slug: "ductus_arteriosus_diameter",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 4,
        measurement_slug: "pulmonary_artery_diameter/right",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 5,
        measurement_slug: "pulmonary_artery_diameter/left",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 7,
        measurement_slug: "pa_ao_ratio",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 8,
        measurement_slug: "aortic_trunk_dim",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 9,
        measurement_slug: "aortic_root_diameter",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 10,
        measurement_slug: "ascending_aorta_diameter.aortic_arch",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 11,
        measurement_slug: "descending_aorta_diameter.aortic_arch",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 13,
        measurement_slug: "aortic_isthmus_width.aortic_arch",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "great_arteries",
        order: 15,
        measurement_slug: "ao/pa_ratio",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "systemic_veins",
        order: 1,
        measurement_slug: "inferior_veina_cava_diameter",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "systemic_veins",
        order: 2,
        measurement_slug: "vena_cava_superior",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 1,
        measurement_slug: "av_aorta.aortic_arch",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 2,
        measurement_slug: "av_pv_annulus_diam_diast.aortic_arch",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 3,
        measurement_slug: "heart_anteriorposterior_diameter",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 4,
        measurement_slug: "pulmonary_artery_diameter",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 5,
        measurement_slug: "la_ra_a",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 6,
        measurement_slug: "la_ra_w",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 7,
        measurement_slug: "left_atrium_area",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 8,
        measurement_slug: "lv_rv_a",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 9,
        measurement_slug: "lv_rv_l",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 10,
        measurement_slug: "lv_rv_w",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 11,
        measurement_slug: "atrial_fetal_heart_rate",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 12,
        measurement_slug: "atrial_length_diastolic",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 13,
        measurement_slug: "atrial_width_diastolic",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 14,
        measurement_slug: "mv_annulus.chambers",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 15,
        measurement_slug: "tv_annulus.chambers",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 16,
        measurement_slug: "right_atrium_area",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 17,
        measurement_slug: "septum",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 18,
        measurement_slug: "short_axis",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 19,
        measurement_slug: "ventricle_area",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 20,
        measurement_slug: "long_axis",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 21,
        measurement_slug: "ventricle_inlet_diameter",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 22,
        measurement_slug: "ventricle_length_diastolic",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 23,
        measurement_slug: "ventricle_width_diastolic",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 24,
        measurement_slug: "wall_thickness_diastolic",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_great_vessels",
        subsubsection: "other",
        order: 25,
        measurement_slug: "pv_annulus",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_1",
        order: 2,
        measurement_slug: "atrial_length_diastolic/right",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_1",
        order: 3,
        measurement_slug: "atrial_width_diastolic/right",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_1",
        order: 5,
        measurement_slug: "ventricle_length_diastolic/right",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_1",
        order: 7,
        measurement_slug: "ventricle_width_diastolic/right",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_1",
        order: 9,
        measurement_slug: "wall_thickness_diastolic/right",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_2",
        order: 1,
        measurement_slug: "atrial_length_diastolic/left",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_2",
        order: 3,
        measurement_slug: "transverse_cardiac_diameter",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_2",
        order: 5,
        measurement_slug: "ventricle_length_diastolic/left",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_2",
        order: 7,
        measurement_slug: "ventricle_width_diastolic/left",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_2",
        order: 9,
        measurement_slug: "wall_thickness_diastolic/left",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_3",
        order: 1,
        measurement_slug: "interventricular_septum_systolic_thickness.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_3",
        order: 3,
        measurement_slug: "atrial_width_diastolic/left",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_3",
        order: 4,
        measurement_slug: "ventricle_inlet_diameter/right",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_3",
        order: 5,
        measurement_slug: "left_ventricular_diastolic_area.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_3",
        order: 6,
        measurement_slug: "left_ventricular_systolic_area.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_3",
        order: 7,
        measurement_slug: "ventricle_area/left",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_3",
        order: 8,
        measurement_slug: "ventricle_inlet_diameter/left",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_4",
        order: 2,
        measurement_slug: "tricuspid_valve_orifice",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_4",
        order: 5,
        measurement_slug: "mitral_valve_orifice",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_4",
        order: 7,
        measurement_slug: "pulmonary_vein_annulus_diast_diam",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_4",
        order: 8,
        measurement_slug: "pulmonary_valve_width.pulmonic valve",
    },
    {
        section: "cardiac_biometry",
        subsection: "heart_dimensions_z_score",
        subsubsection: "cardiac_chambers_4",
        order: 9,
        measurement_slug: "aortic_valve_annulus_diast_diam.aortic_arch",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_1",
        order: 5,
        measurement_slug: "interventricular_septum_diastolic_thickness.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 4,
        measurement_slug: "left_ventricle_internal_end_diastolic_dimension.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 5,
        measurement_slug: "left_ventricle_internal_systolic_dimension.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 11,
        measurement_slug: "left_ventricular_fractional_shortening.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 12,
        measurement_slug: "left_ventricle_ejection_fraction.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 13,
        measurement_slug: "left_ventricular_end_diastolic_volume.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 14,
        measurement_slug: "left_ventricular_end_systolic_volume.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 15,
        measurement_slug: "left_ventricle_posterior_wall_diastolic_thickness.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 16,
        measurement_slug: "left_ventricle_posterior_wall_systolic_thickness.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 17,
        measurement_slug: "pr_interval.aortic",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 18,
        measurement_slug: "left_ventricle_posterior_wall_%_thickening.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 19,
        measurement_slug: "left_ventricle_mass.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 20,
        measurement_slug: "left_ventricular_fractional_area_change.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 21,
        measurement_slug: "interventricular_septum_%_thickening.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 22,
        measurement_slug: "interventricular_septum_to_posterior_wall_thickness_ratio.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 23,
        measurement_slug: "relative_wall_thickness.left_ventricle",
    },
    {
        section: "cardiac_biometry",
        subsection: "m_mode_measurements",
        subsubsection: "heart_m_mode_2",
        order: 24,
        measurement_slug: "stroke_volume.left_ventricle",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "umbilical_artery",
        order: 1,
        measurement_slug: "pulsatility_index.umbilical_artery",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "umbilical_artery",
        order: 2,
        measurement_slug: "resistivity_index.umbilical_artery",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "umbilical_artery",
        order: 3,
        measurement_slug: "peak_systolic_velocity.umbilical_artery",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "umbilical_artery",
        order: 4,
        measurement_slug: "end_diastolic_velocity.umbilical_artery",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "umbilical_artery",
        order: 6,
        measurement_slug: "time_avg_peak_velocity.umbilical_artery",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "umbilical_artery",
        order: 8,
        measurement_slug: "min_diastolic_velocity.umbilical_artery",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "umbilical_artery",
        order: 9,
        measurement_slug: "systolic_to_diastolic_velocity_ratio.umbilical_artery",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "umbilical_artery",
        order: 10,
        measurement_slug: "vti.umbilical_artery",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "umbilical_artery",
        order: 11,
        measurement_slug: "fhr.umbilical_artery",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 1,
        measurement_slug: "pulsatility_index.embryonic_vascular_structure",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 2,
        measurement_slug: "resistivity_index.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 3,
        measurement_slug: "peak_systolic_velocity.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 5,
        measurement_slug: "end_diastolic_velocity.embryonic_vascular_structure",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 7,
        measurement_slug: "time_avg_peak_velocity.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 9,
        measurement_slug: "min_diastolic_velocity.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 10,
        measurement_slug: "systolic_to_diastolic_velocity_ratio.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 12,
        measurement_slug: "heart_rate.embryonic_vascular_structure",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 13,
        measurement_slug: "acceleration_time.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 14,
        measurement_slug: "acceleration.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 15,
        measurement_slug: "cerebroplacental_ratio_using_pulsatility_index.embryonic_vascular_structure",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 16,
        measurement_slug: "cerebroplacental_ratio.embryonic_vascular_structure",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 17,
        measurement_slug: "deceleration_time.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 18,
        measurement_slug: "deceleration.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 19,
        measurement_slug: "diastolic_to_systolic_velocity_ratio.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 20,
        measurement_slug: "mean_gradient.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 21,
        measurement_slug: "peak_gradient.embryonic_vascular_structure",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "mca",
        order: 22,
        measurement_slug: "preload_index.embryonic_vascular_structure/unilateral",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "right_mca",
        order: 1,
        measurement_slug: "pulsatility_index.middle_cerebral_artery/right",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "right_mca",
        order: 2,
        measurement_slug: "resistivity_index.middle_cerebral_artery/right",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "right_mca",
        order: 3,
        measurement_slug: "peak_systolic_velocity.middle_cerebral_artery/right",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "right_mca",
        order: 5,
        measurement_slug: "end_diastolic_velocity.middle_cerebral_artery/right",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "right_mca",
        order: 8,
        measurement_slug: "time_avg_peak_velocity.middle_cerebral_artery/right",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "right_mca",
        order: 9,
        measurement_slug: "min_diastolic_velocity.middle_cerebral_artery/right",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "right_mca",
        order: 10,
        measurement_slug: "systolic_to_diastolic_velocity_ratio.middle_cerebral_artery/right",
    },
    {
        section: "fetal_dopplers",
        subsection: "umbilical_artery_mca",
        subsubsection: "right_mca",
        order: 12,
        measurement_slug: "heart_rate.middle_cerebral_artery/right",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 1,
        measurement_slug: "peak_systolic_velocity.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 2,
        measurement_slug: "peak_diastolic_velocity.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 3,
        measurement_slug: "min_diastolic_velocity.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 6,
        measurement_slug: "time_avg_peak_velocity.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 7,
        measurement_slug: "time_averaged_mean_velocity.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 8,
        measurement_slug: "pulsatility_index.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 9,
        measurement_slug: "peak_velocity_index.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 10,
        measurement_slug: "resistivity_index.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 11,
        measurement_slug: "peak_gradient.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 12,
        measurement_slug: "systolic_a_wave_ratio.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 13,
        measurement_slug: "min_diastolic_to_peak_systolic_ratio.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 15,
        measurement_slug: "peak_diastolic_to_min_diastolic_velocity_ratio.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 20,
        measurement_slug: "heart_rate.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 21,
        measurement_slug: "peak_gradient.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 22,
        measurement_slug: "diastolic_to_systolic_velocity_ratio.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 25,
        measurement_slug: "systolic_to_diastolic_velocity_ratio.ductus_venosus_vein",
    },
    {
        section: "fetal_dopplers",
        subsection: "dv_uv_hepatic",
        subsubsection: "ductus_venosus",
        order: 26,
        measurement_slug: "mean_gradient.ductus_venosus_vein",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "tricuspid_valve",
        order: 1,
        measurement_slug: "e_wave.tricuspid_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "tricuspid_valve",
        order: 2,
        measurement_slug: "a_wave.tricuspid_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "tricuspid_valve",
        order: 3,
        measurement_slug: "e_wave_a_wave_ratio.tricuspid_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "tricuspid_valve",
        order: 4,
        measurement_slug: "v_max_regurg.tricuspid_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "mitral_valve",
        order: 1,
        measurement_slug: "e_wave.mitral_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "mitral_valve",
        order: 2,
        measurement_slug: "a_wave.mitral_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "mitral_valve",
        order: 3,
        measurement_slug: "e_wave_a_wave_ratio.mitral_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "mitral_valve",
        order: 5,
        measurement_slug: "v_max_regurg.mitral_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "mitral_valve",
        order: 6,
        measurement_slug: "peak_pg_regurg.mitral_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "rvot_pulmonary_valve",
        order: 1,
        measurement_slug: "v_max.pulmonary_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "rvot_pulmonary_valve",
        order: 2,
        measurement_slug: "acceleration_time.pulmonary_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "rvot_pulmonary_valve",
        order: 3,
        measurement_slug: "peak_gradient.pulmonary_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 1,
        measurement_slug: "v_max.lvot",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 4,
        measurement_slug: "peak_gradient.aortic_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 7,
        measurement_slug: "vti.lvot",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 8,
        measurement_slug: "ict.lvot",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 9,
        measurement_slug: "ejection_time.lvot",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 10,
        measurement_slug: "irt.lvot",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 15,
        measurement_slug: "v_max_regurg.aortic",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 16,
        measurement_slug: "peak_pg_regurg.mitral_valve",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 17,
        measurement_slug: "peakpg.lvot",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 18,
        measurement_slug: "mnpg.lvot",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 19,
        measurement_slug: "fhr.lvot",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 20,
        measurement_slug: "tei_index.lvot",
    },
    {
        section: "cardiac_dopplers",
        subsection: "intracardiac",
        subsubsection: "lvot_aortic_valve",
        order: 21,
        measurement_slug: "ict.lvot",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "main_pa",
        order: 1,
        measurement_slug: "pulsatility_index.main_pulmonary_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "main_pa",
        order: 2,
        measurement_slug: "resistivity_index.main_pulmonary_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "main_pa",
        order: 3,
        measurement_slug: "end_diastolic_velocity.main_pulmonary_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "main_pa",
        order: 4,
        measurement_slug: "ta_max.pulmonary_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "main_pa",
        order: 5,
        measurement_slug: "peak_gradient.main_pulmonary_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "main_pa",
        order: 6,
        measurement_slug: "min_diastolic_velocity.main_pulmonary_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "main_pa",
        order: 7,
        measurement_slug: "diastolic_to_systolic_velocity_ratio.main_pulmonary_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "main_pa",
        order: 8,
        measurement_slug: "v_max.pulmonary_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "main_pa",
        order: 9,
        measurement_slug: "vti.pulmonary_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "ductus_arteriosus",
        order: 1,
        measurement_slug: "pulsatility_index.ductus_venosus_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "ductus_arteriosus",
        order: 2,
        measurement_slug: "resistivity_index.ductus_venosus_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "ductus_arteriosus",
        order: 3,
        measurement_slug: "peak_systolic_velocity.ductus_venosus_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "ductus_arteriosus",
        order: 4,
        measurement_slug: "end_diastolic_velocity.ductus_venosus_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "ductus_arteriosus",
        order: 6,
        measurement_slug: "time_avg_peak_velocity.ductus_venosus_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "ductus_arteriosus",
        order: 9,
        measurement_slug: "vti.ductus_venosus_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "ductus_arteriosus",
        order: 10,
        measurement_slug: "min_diastolic_velocity.ductus_venosus_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "ductus_arteriosus",
        order: 11,
        measurement_slug: "diastolic_to_systolic_velocity_ratio.ductus_venosus_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "ductus_arteriosus",
        order: 12,
        measurement_slug: "peak_gradient.ductus_venosus_artery",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "asc_aorta",
        order: 1,
        measurement_slug: "pulsatility_index.ascending_aorta",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "asc_aorta",
        order: 2,
        measurement_slug: "resistivity_index.ascending_aorta",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "asc_aorta",
        order: 3,
        measurement_slug: "peak_systolic_velocity.ascending_aorta",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "asc_aorta",
        order: 4,
        measurement_slug: "end_diastolic_velocity.ascending_aorta",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "desc_aorta",
        order: 1,
        measurement_slug: "pulsatility_index.descending_aorta",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "desc_aorta",
        order: 2,
        measurement_slug: "resistivity_index.descending_aorta",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "desc_aorta",
        order: 3,
        measurement_slug: "peak_systolic_velocity.descending_aorta",
    },
    {
        section: "cardiac_dopplers",
        subsection: "postcardiac",
        subsubsection: "desc_aorta",
        order: 4,
        measurement_slug: "end_diastolic_velocity.descending_aorta",
    },
    {
        section: "maternal_structures",
        subsection: "uterus_cervix",
        subsubsection: "uterus",
        order: 1,
        measurement_slug: "ut_h",
    },
    {
        section: "maternal_structures",
        subsection: "uterus_cervix",
        subsubsection: "uterus",
        order: 2,
        measurement_slug: "ut_width",
    },
    {
        section: "maternal_structures",
        subsection: "uterus_cervix",
        subsubsection: "uterus",
        order: 3,
        measurement_slug: "ut_l",
    },
    {
        section: "maternal_structures",
        subsection: "uterus_cervix",
        subsubsection: "uterus",
        order: 4,
        measurement_slug: "ut_vol",
    },
    {
        section: "maternal_structures",
        subsection: "uterus_cervix",
        subsubsection: "cervix",
        order: 1,
        measurement_slug: "cervix_l",
    },
    {
        section: "maternal_structures",
        subsection: "uterus_cervix",
        subsubsection: "cervix",
        order: 2,
        measurement_slug: "cervix_l_2",
    },
    {
        section: "maternal_structures",
        subsection: "uterus_cervix",
        subsubsection: "cervix",
        order: 3,
        measurement_slug: "cervix_l_3",
    },
    {
        section: "maternal_structures",
        subsection: "uterus_cervix",
        subsubsection: "endometrium",
        order: 1,
        measurement_slug: "endom_thickness",
    },
    {
        section: "maternal_structures",
        subsection: "ovaries",
        subsubsection: "right_ovary",
        order: 1,
        measurement_slug: "right_ov_h.ovary",
    },
    {
        section: "maternal_structures",
        subsection: "ovaries",
        subsubsection: "right_ovary",
        order: 2,
        measurement_slug: "right_ov_w.ovary",
    },
    {
        section: "maternal_structures",
        subsection: "ovaries",
        subsubsection: "right_ovary",
        order: 3,
        measurement_slug: "right_ov_l.ovary",
    },
    {
        section: "maternal_structures",
        subsection: "ovaries",
        subsubsection: "right_ovary",
        order: 4,
        measurement_slug: "ovary_mean_right",
    },
    {
        section: "maternal_structures",
        subsection: "ovaries",
        subsubsection: "right_ovary",
        order: 5,
        measurement_slug: "right_ov_vol.ovary",
    },
    {
        section: "maternal_structures",
        subsection: "ovaries",
        subsubsection: "left_ovary",
        order: 1,
        measurement_slug: "left_ov_h.ovary",
    },
    {
        section: "maternal_structures",
        subsection: "ovaries",
        subsubsection: "left_ovary",
        order: 2,
        measurement_slug: "left_ov_w.ovary",
    },
    {
        section: "maternal_structures",
        subsection: "ovaries",
        subsubsection: "left_ovary",
        order: 3,
        measurement_slug: "left_ov_l.ovary",
    },
    {
        section: "maternal_structures",
        subsection: "ovaries",
        subsubsection: "left_ovary",
        order: 4,
        measurement_slug: "ovary_mean_left",
    },
    {
        section: "maternal_structures",
        subsection: "ovaries",
        subsubsection: "left_ovary",
        order: 5,
        measurement_slug: "left_ov_vol.ovary",
    },
    {
        section: "maternal_structures",
        subsection: "adnexa",
        subsubsection: "right_adnexa",
        order: 1,
        measurement_slug: "height.ovarian_mass/right",
    },
    {
        section: "maternal_structures",
        subsection: "adnexa",
        subsubsection: "right_adnexa",
        order: 2,
        measurement_slug: "length.ovarian_mass/right",
    },
    {
        section: "maternal_structures",
        subsection: "adnexa",
        subsubsection: "right_adnexa",
        order: 3,
        measurement_slug: "width.ovarian_mass/right",
    },
    {
        section: "maternal_structures",
        subsection: "adnexa",
        subsubsection: "left_adnexa",
        order: 1,
        measurement_slug: "height.ovarian_mass/left",
    },
    {
        section: "maternal_structures",
        subsection: "adnexa",
        subsubsection: "left_adnexa",
        order: 2,
        measurement_slug: "length.ovarian_mass/left",
    },
    {
        section: "maternal_structures",
        subsection: "adnexa",
        subsubsection: "left_adnexa",
        order: 3,
        measurement_slug: "width.ovarian_mass/left",
    },
    {
        section: "maternal_dopplers",
        subsection: "uterine_artery_dopplers",
        subsubsection: "uterine_artery",
        order: 1,
        measurement_slug: "peak_systolic_velocity.uterine_artery",
    },
    {
        section: "maternal_dopplers",
        subsection: "uterine_artery_dopplers",
        subsubsection: "uterine_artery",
        order: 2,
        measurement_slug: "end_diastolic_velocity.uterine_artery",
    },
    {
        section: "maternal_dopplers",
        subsection: "uterine_artery_dopplers",
        subsubsection: "uterine_artery",
        order: 3,
        measurement_slug: "resistivity_index.uterine_artery",
    },
    {
        section: "maternal_dopplers",
        subsection: "uterine_artery_dopplers",
        subsubsection: "uterine_artery",
        order: 4,
        measurement_slug: "systolic_to_diastolic_velocity_ratio.uterine_artery",
    },
    {
        section: "maternal_dopplers",
        subsection: "ovarian_artery_dopplers",
        subsubsection: "ovarian_artery",
        order: 1,
        measurement_slug: "peak_systolic_velocity.ovarian_artery",
    },
    {
        section: "maternal_dopplers",
        subsection: "ovarian_artery_dopplers",
        subsubsection: "ovarian_artery",
        order: 2,
        measurement_slug: "end_diastolic_velocity.ovarian_artery",
    },
];

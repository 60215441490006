import { useCallback, useState, useEffect } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
/* Atoms */
import InlineMultiselect from '../../../atoms/InlineEditing/InlineMultiSelect/InlineMultiSelect';
import Icon from '../../../atoms/Icon/Icon';

/* Utils */
import { isNullOrUndefined } from '../../../utils';
import NotVisibleOverlay from '../../../atoms/InlineEditing/NotVisibleOverlay/NotVisibleOverlay';
/* Context */


export default function ReportTemplateDropdown({children, props, placeholder: originalPlaceholder, setRequiredAndInvalid, loadDynamicDropdownFullTree, showErrors, highlight = false, onEndEditing = () => {}, onEndEditingDynamicDropdown = () => {}, showOptions=null, fetus: unFormattedFetus, reportMode, canEdit = false}) {
  let placeholder, fetus;
  const isMultiple = (originalPlaceholder.value && !Array.isArray(originalPlaceholder.value) && typeof originalPlaceholder.value === 'object') || originalPlaceholder.format === "multiple" || props.multiple === "true";
  const placeholderId = ((!props.data?.startsWith('custom.') && props.custom === "true") ? 'custom.' : '') + props.data;

  if (Array.isArray(originalPlaceholder) || props.data?.startsWith("measurement.")) {
    fetus = Number(props.fetus ?? unFormattedFetus ?? 1);
    placeholder = originalPlaceholder[fetus];
  } else if (Array.isArray(originalPlaceholder?.value)) {
    fetus = Number(props.fetus ?? unFormattedFetus ?? 1);
    placeholder = { ...originalPlaceholder, value: originalPlaceholder.value[fetus] };
  } else if (isMultiple) {
    fetus = null;
    placeholder = { ...originalPlaceholder };
  } else {
    fetus = Number(props.fetus ?? unFormattedFetus ?? 1);
    const tempValue = [...Array(fetus + 1).keys()].map(v => originalPlaceholder.value);
    placeholder = { ...originalPlaceholder, value: tempValue };
  }

  const { visible = true } = (placeholder ?? {});
  const [editing, setEditing] = useState(false);
  showOptions = reportMode === "edit" && (showOptions ?? placeholder?.showOptions ?? true);
  
  const onFieldMouseOver = (e) => {
    if (placeholder.isDynamic) {
      loadDynamicDropdownFullTree && loadDynamicDropdownFullTree(placeholder.data);
    }
  }

  const getOptionById = (id, tree) => {
    if (!tree || !id) return false;

    let elm = tree.find(node => `${node.id}`.toLowerCase() === `${id}`.toLowerCase());
    if (elm) return elm;

    for (const node of tree) {
      elm = getOptionById(id, node.tree);
      if (elm) return elm;
    }

    return elm;
  }

  const updateOption = (option, tree) => {
    if (!tree) return [];
    if (!option) return tree;

    const foundNodeIndex = tree.findIndex(node => node.id === option.id);
    if (foundNodeIndex >= 0) {
      tree[foundNodeIndex] = { ...tree[foundNodeIndex], ...option };
    }
    tree.map(node => ({ ...node, tree: updateOption(option, node?.tree) }));

    return tree;
  }

  const getLabel = (children) => {
    const label = renderToStaticMarkup(children);
    if (!label) return "";
    return label.replace("\n", "").replace("\r", "").trim();
  }

  const getOptions = useCallback(() => {
    let options = [...(placeholder?.tree || [])];

    for (const option of (children?.filter(c => c?.props?.type === "option") || [])) {
      const alreadyExisting = getOptionById(option.props.props.value, options);

      const value = option.props.props.value ?? option.props.children.toString();
      const isDefault = !!option.props.props.default;

      if (alreadyExisting) {
        options = updateOption({
          value,
          id: value,
          label: getLabel(option.props.children) || alreadyExisting.label,
          default: isDefault,
        }, options);

      } else {
        options.push({
          value,
          id: value,
          label: getLabel(option.props.children),
          default: isDefault,
          selectable: true,
        });
      }
    }

    options = options.map(option => ({
      ...option,
      selectable: option.selectable ?? true
    }));

    if (!isNullOrUndefined(props.default)) {
      options = options.map(option => {
        const isDefault = option.id.toLowerCase() === props.default.toLowerCase();

        return {
          ...option,
          default: isDefault,
          selectable: isDefault || (option.selectable ?? true)
        }
      })
    }

    if (isMultiple) {
      options = options.filter(option => option?.id || !option.selectable);
    }

    return options;
  }, [placeholder?.tree, children]);

  const options = getOptions();

  const getDefaultValue = (tree) => {
    if (!tree) return [];

    let nodes = Object.fromEntries(tree.filter(node => node.selectable && node.default).map(node => [node.id, { value: true }]));

    for (const node of tree) {
      nodes = { ...nodes, ...getDefaultValue(node.tree) };
    }
    return nodes;
  };

  const isValidOption = (tree, value) => {
    if (!Array.isArray(tree) || !tree.length) return [];

    let isValid = tree.some(node => (isNullOrUndefined(node.selectable) || node.selectable) && (node.id === value || (isNullOrUndefined(node.id) && node.label === value)));
    if (isValid) return true;

    for (const node of tree) {
      if (node.tree) {
        isValid = isValidOption(node.tree, value);
        if (isValid) return true;
      }
    }
    return isValid;
  };

  const getValue = () => {
    if (isMultiple) {
      return Object.keys(Array.isArray(placeholder.value) ? {} : (placeholder.value || getDefaultValue(options)))
    }


    if (Array.isArray(placeholder.value)) {
      return isValidOption(options, placeholder.value[fetus]) ? placeholder.value[fetus] : (placeholder.value == 0 ? placeholder.value : Object.keys(getDefaultValue(options) || [])?.[0]);
    }

    return isValidOption(options, placeholder.value) ? placeholder.value : (placeholder.value == 0 ? placeholder.value : Object.keys(getDefaultValue(options) || [])?.[0]);
  }

  const valueIsEmpty = () => {
    const value = getValue();
    return Array.isArray(value) ? value.filter(v => v).length === 0 : !value;
  }

  const onCloseHandler = () => {
    setTimeout(() => setEditing(false), 200);
    window.removeEventListener('click', onCloseHandler, false);
  }

  useEffect(() => {
    if (editing === "value") {
      window.removeEventListener('click', onCloseHandler, false);
      window.addEventListener('click', onCloseHandler, false);
    }
  }, [editing]);

  const saveChange = (updates) => {
    if (!props.data) return false;

    if (updates.value) {
      if (placeholder.format === "multiple" || props.multiple === "true" || (!Array.isArray(placeholder.value) && placeholder.value !== null && typeof placeholder.value === "object")) {
        let newValue = placeholder.value;
        if (!newValue || typeof newValue !== 'object') {
          newValue = {};
        }

        if (typeof updates.value === 'string') {
          // TODO: support toggle value
          /** single value needing to be stored in a multivalue format */
          delete newValue[updates.value];
          const label = getOptionById(updates.value, placeholder.tree)?.label;
          newValue[updates.value] = { value: true, label, order: newValue.length };

        } else if (Array.isArray(updates.value)) {
          newValue = {};
          let index = 0;
          for (const value of updates.value) {
            const label = getOptionById(value, placeholder.tree)?.label;
            const description = getOptionById(value, placeholder.tree)?.description;
            const order = index;
            newValue[value] = placeholder.value?.[value] ?? { value: true, label, description, order };
            index++;
          }
        }
        updates.value = newValue;
      }

      if (fetus && !isMultiple && Array.isArray(originalPlaceholder.value)) {
        let value = originalPlaceholder.value;
        value[fetus] = updates.value;
        updates.value = value;
      }

      if ((props.data?.startsWith('custom.') || props.custom === "true") && updates.value && !Array.isArray(updates.value)) {
        let newUpdatesValue = "";
        if (Array.isArray(originalPlaceholder.value)) {
          newUpdatesValue = (originalPlaceholder.value || "");
        } else {
          let defaultValue = originalPlaceholder.value || Object.keys(getDefaultValue(options) || [])?.[0] || "";
          newUpdatesValue = Array(fetus + 1);
          newUpdatesValue.fill(defaultValue);
          // Set the 0th index to empty (for the mother) - multifetal defaults are not relevant for the mother
          newUpdatesValue[0] = "";
        }

        newUpdatesValue[fetus] = updates.value;
        updates.value = newUpdatesValue;
      }
    }

    const id = props.data;
    const custom = props.custom === "true";

    if (placeholder.isDynamic) {
      onEndEditingDynamicDropdown(id, updates, custom);
    } else {
      onEndEditing(id, updates, custom);
    }
    onCloseHandler();
  };

  const invalidValue = () => {
    const selectedValue = getValue();
    return (selectedValue && typeof selectedValue === 'object') ? !Object.keys(selectedValue).length : !selectedValue;
  };

  const placeHolderValue = getValue()

  useEffect(() => {
    if (props.required === "true" && setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        invalidValue() ?
          prevState.add(props.data) : prevState.delete(props.data);
        return prevState;
      });
    }
  }, [placeHolderValue, setRequiredAndInvalid]);

  const isRequired = () => {
    return reportMode === "edit" && props.required === "true" ? <span className="required">*</span> : false;
  };

  let labelStyle = {};
  if (props["label-width"]) {
    labelStyle.width = props["label-width"];
    labelStyle.minWidth = props["label-width"];
  }

  return (
    <div className={`
      dropdown-wrapper exam-report-editing-field
      ${!valueIsEmpty() ? (visible ? 'has-printable-value' : '') : 'not-printable'}
      ${visible ? "is-visible" : "not-visible"}
      ${props.fullwidth ? "full-width" : ""}
      ${(!props.label || props.inline) ? 'is-inline' : 'is-block'}
      ${isRequired() ? 'is-required' : ''}
      ${props.required === "true" && invalidValue() && showErrors ? 'required-error' : ''}
    `}
    onMouseOver={onFieldMouseOver}>
      {!props.compact && !!props.label && (<div className="label" style={labelStyle}>{props.label} {isRequired()}</div>)}
      <span className={!!highlight ? 'highlight-field' : ''}>
        <InlineMultiselect
          value={getValue()}
          options={options.map(option => ({ ...option, label: option.label }))}
          printable={visible}
          multiple={isMultiple}
          compactMode={props.compact || false}
          compactModeLabel={props.label || undefined}
          fullwidth={props.fullwidth || false}
          onChange={(value) => saveChange({ value })}
          showRecent={props.data}
          showMostUsed={props.data}
          showSearchBar="auto"
          disabled={reportMode === "print"}
          active={canEdit}
        />
        {!props.label && isRequired()}
        {highlight && highlight.icon && (
          <span className={highlight.iconClass || ''}><Icon name={highlight.icon} /></span>
        )}
        {canEdit && visible && reportMode === "edit" && showOptions && (
          <div className="dropdown-options exam-report-editing-options not-printable">
            <div onClick={() => saveChange({ visible: !visible })}>
              <Icon name={visible ? "eye" : "eye-off"} />
            </div>
          </div>
        )}
      </span>
      {!visible && reportMode === "edit" && <NotVisibleOverlay onClick={() => saveChange({ visible: !visible })} allowUpdates={!!placeholder?.allowUpdatesWhenHidden} />}
    </div>
  );
}

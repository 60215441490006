import { withTranslation } from "react-i18next";
import LogoSettings from "./LogoSettings";
import MessageEditor from "./MessageEditor";
import TextInput from "../../atoms/TextInput/TextInput";
import TextArea from "../../atoms/TextArea/TextArea";
import Button from "../../atoms/Button/Button";
import "./PrintingSharingSettings.css";
import ResourceApi from "../../services/resource";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context-providers/App";

const PrintingSharingSettings = ({ t: __ }) => {

  const { site, setSite } = useContext(AppContext);
  const [siteDetails, setSiteDetails] = useState(site);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setSiteDetails(site);
  }, [site]);

  const handleSiteInput = (fieldName) => (newValue) => {
    setSiteDetails({ ...siteDetails, [fieldName]: newValue });
  };

  const submitSiteDetails = async () => {
    setLoading(true);
    setError(false);
    try {
      const updatedSite = await ResourceApi.updateSite(site.id, siteDetails);
      setSite(updatedSite.data.data);
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  return !siteDetails ? false : (
    <div className="site-information-container">
      <div className="w12">
        <h1>
          {__("preferences.printingSharing")}
        </h1>
      </div>
      <div className="section">

        <div className="title">
          {__("preferences.printingSharing.generalInfo")}
        </div>
        <div className="subtitle">
          {__("preferences.printingSharing.subtitle")}
        </div>
        <div className="row">
          <div className="w4 preferences-align-content">{__("preferences.printingSharing.name")}</div>
          <div className="w8">
            <TextInput
              value={siteDetails.name}
              onChange={handleSiteInput("name")}
              fullwidth="true"
            />
          </div>
        </div>
        <div className="row">
          <div className="w4 preferences-align-content">{__("preferences.printingSharing.address")}</div>
          <div className="w8">
            <TextArea
              value={siteDetails.address}
              onChange={handleSiteInput("address")}
              fullwidth="true"
            />
          </div>
        </div>
        <div className="row">
          <div className="w4 preferences-align-content">{__("preferences.printingSharing.phone")}</div>
          <div className="w8">
            <TextInput
              value={siteDetails.phone_number}
              onChange={handleSiteInput("phone_number")}
              fullwidth="true"
            />
          </div>
        </div>
        <div className="row">
          <div className="w4 preferences-align-content">{__("preferences.printingSharing.website")}</div>
          <div className="w8">
            <TextInput
              value={siteDetails.website}
              onChange={handleSiteInput("website")}
              fullwidth="true"
            />
          </div>
        </div>
        <div className="row">
          <div className="w4 preferences-align-content">{__("preferences.printingSharing.notes")}</div>
          <div className="w8">
            <TextArea
              fullwidth="true"
              value={siteDetails.notes}
              onChange={handleSiteInput("notes")}
            />
          </div>
        </div>
        <div className="row">
          <div className="w4 preferences-align-content" />
          <div className="w8">
            <Button
              label={!loading ? __('common.submit') : __('common.loading')}
              disabled={loading}
              onClick={submitSiteDetails}
              fullwidth="true"
            />
          </div>
        </div>
        {error && (
          <div className="row">
            <div className="w4" />
            <div className="w8">
              <div className="error-message">Something went wrong. Your changes weren't saved.</div>
            </div>
          </div>
        )}
      </div>
      <hr />
      <LogoSettings />
      <hr />
      <MessageEditor />
    </div>
  );
};

export default withTranslation()(PrintingSharingSettings);

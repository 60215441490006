import parse from 'html-react-parser';
import { QRCodeSVG } from 'qrcode.react';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { withTranslation } from "react-i18next";
import { createPortal } from 'react-dom';
import { useHistory, useParams } from 'react-router-dom';
import Checkbox from '../../atoms/Checkbox/Checkbox'
import { AppContext } from '../../context-providers/App';
import useAuth from "../../context-providers/Auth";
import { ExaminationContext } from '../../context-providers/Examination';
import { ExaminationSharingContext } from '../../context-providers/ExaminationSharing';
import { ZoomLevelContext } from "../../context-providers/ZoomLevel";
import ResourceApi from '../../services/resource';
import '../../components/Examination/SlideGrid/SlideGrid.css';
import './SharingSelect.css'
import globalConfig from "../../config";
import Slide from "../../components/Slide/Slide";
import SlideShareDisplay from "../../components/Slide/SlideShareDisplay";
import SharingPreviewPopup from "../ExaminationReview/SharingPreviewPopup";
import LoaderInline from "../../atoms/LoaderInline/LoaderInline";
import Button from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon/Icon';
import SharingListWidget from './SharingListWidget';
import UserApi from "../../services/user";
import SharingTopBar from "./SharingTopBar";
import SelectInput from "../../atoms/SelectInput/SelectInput";
import loadExamAssocAttachments from '../../services/loadExamAssocAttachments';
import { useExamSharingState } from '../../providers/examSharing';
import {formatName} from "../../services/examination";
import {formatDate} from "../../utils";

const DEFAULT_NB_IMG_PER_PAGE = 8;

const chunk = function (array, size) {
  if (!array.length) {
    return [];
  }
  const head = array.slice(0, size);
  const tail = array.slice(size);

  return [head, ...chunk(tail, size)];
};

const reorder = (instances, slideMap, sortBy) => {
  const orderBySlide = (instances, slideMap) => {
    const sortBySlideKey = (instances, slideMap) =>
      [...instances].sort((instanceA, instanceB) => {
        const slideA = slideMap.get(instanceA.dicom_instance_id || instanceA.id)
        const slideB = slideMap.get(instanceB.dicom_instance_id || instanceB.id)

        if (!slideB) return -1;
        if (!slideA) return 1;
        if (slideA.key === slideB.key) return 0;

        const keyToWeight = key => {
          const [a, b, c] = key.split("_").map(str => parseInt(str))
          const weight = (a || 0) * 1000000 + (b || 0) * 1000 + (c || 0)
          return weight
        }
        const weightA = keyToWeight(slideA.key)
        const weightB = keyToWeight(slideB.key)

        return (weightA > weightB) ? 1 : -1;
      })

    const groupByTypes = (instances, slideMap) => {
      const ifSlide = condition => (i => {
        const slide = slideMap.get(i.dicom_instance_id || i.id);
        return condition(slide);
      })
      const isPicture = slide => slide && !slide.optional && slide.type === "picture";
      const isOptional = slide => slide && (
        (slide.optional && slide.type === "picture") ||
        (slide.type === "other" && (slide.verified || !slide.mediaId) && !slide.is_video)
      );
      const isUnmatched = slide => slide && slide.mediaId && !slide.optional && !slide.verified && !slide.is_video;
      const isVideo = slide => slide && slide.is_video;
      const absent = slide => !slide;

      return [
        ...instances.filter(ifSlide(isPicture)),
        ...instances.filter(ifSlide(isOptional)),
        ...instances.filter(ifSlide(isUnmatched)),
        ...instances.filter(ifSlide(isVideo)),
        ...instances.filter(ifSlide(absent)),
      ]
    }

    const sortedInstances = sortBySlideKey(instances, slideMap);
    const sortedAndGroupedInstances = groupByTypes(sortedInstances, slideMap);

    return sortedAndGroupedInstances;
  }

  const orderByTime = (instances, slideMap) =>
    [...instances].sort((instanceA, instanceB) => {
      const slideA = slideMap.get(instanceA.id || instanceA.dicom_instance_id)
      const slideB = slideMap.get(instanceB.id || instanceB.dicom_instance_id)

      if (!slideA && !slideB) return 0;
      if (!slideB) return -1;
      if (!slideA) return 1;

      return (slideA.inserted_at === slideB.inserted_at) ? 0 : ((slideA.inserted_at || "z") > (slideB.inserted_at || "z") ? 1 : -1)
    })

  if (sortBy === "slide") return orderBySlide(instances, slideMap);
  if (sortBy === "time") return orderByTime(instances, slideMap);
  return instances;
};

const extraPageCount = (slides, perPage, qrEnabled) => {
  if (!qrEnabled) return 0;
  const remainder = slides % perPage
  if (remainder === 0) return 1;
  if (perPage === 2) return remainder > 1 && 1 || 0;
  if (perPage === 8) return remainder > 6 && 1 || 0;
  if (perPage === 15) return remainder > 12 && 1 || 0;
  return 0;
}
const SharingSelect = ({
  t: __,
  close = null,
  onReport = null,
  onShare = null,
  onPrint = null,
  instances,
  mode,
  slides
}) => {
  const history = useHistory();
  const {examId} = useParams();
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const examinationSharingContext = useContext(ExaminationSharingContext);
  const { generalDocuments, totalActiveUploadedDocuments } = useExamSharingState();
  const { zoomLevel, updateZoomLevel } = useContext(ZoomLevelContext);
  const { site } = appContext;
  const { instancePreviewBlobs, loadedPreviewsList, loadShareForExam, reloadInstancePreviewBlobList } = examinationContext;
  const { updateExamSharedParams } = examinationContext;

  const { anonymizeToggle, annotateToggle, documentsToggle, setAnnotateToggle, setDocumentsToggle } = examinationSharingContext;

  const [selectedInstanceAmount, setSelectedInstanceAmount] = useState(0)
  const [selectedInstancesLoading, setSelectedInstancesLoading] = useState([])
  const [isAllSelected, setIsAllSelected] = useState(false)
  const [shareLoading, setShareLoading] = useState(false);
  const [printPreviewsLoading, setPrintPreviewsLoading] = useState(false);

  const [closeAction, setCloseAction] = useState(null)
  const [qrCodePopupOpen, setQrCodePopupOpen] = useState(false);

  const [annotateConfig, setAnnotateConfig] = useState(null);
  const [annotateParam, setAnnotateParam] = useState(null);
  const [documentsParam, setDocumentsParam] = useState(null);
  const [annotateChange, setAnnotateChange] = useState(null);
  const [documentsChange, setDocumentsChange] = useState(null);
  const [isSharingListWidget, setIsSharingListWidget] = useState(false);
  const { isFeatureFlagEnabled, user } = useAuth();
  const [sortPlanesBy, setSortPlanesBy] = useState(window.localStorage.getItem(`defaultSortPlanesBy-user-${user.id}`) || "slide");

  const slideMap = useMemo(() => {
    return new Map(!!slides && slides.map(slide => [slide.mediaId, slide]) || [])
  }, [slides])

  const sortedInstances = useMemo(() => reorder(instances,slideMap, sortPlanesBy), [instances, slideMap, sortPlanesBy])

  useEffect(() => {
    const closeActionProvider = () => (!close) ? history.goBack : close;
    appContext.activateGoBackMenuButton(true, closeActionProvider);
    setCloseAction(closeActionProvider);
    if (isFeatureFlagEnabled("sonio.attachments")) {
      loadExamAssocAttachments(examId);
    }
    return () => appContext.activateGoBackMenuButton(false);
  }, [])

  useEffect(() => {
    UserApi.getPatientAppConfig()
      .then(res => {
        setAnnotateConfig(res.data.show_names_on_views);
      })
    setDocumentsToggle(true);
  }, []);

  useEffect(() => {
    if (examinationContext.share?.parameters?.annotate_images !== undefined) {
      setAnnotateParam(examinationContext.share.parameters.annotate_images)
    }
  }, [examinationContext.share]);

  useEffect(() => {
    if (examinationContext.share?.parameters?.share_documents !== undefined) {
      setDocumentsParam(examinationContext.share.parameters.share_documents)
    }
  }, [examinationContext.share]);

  useEffect(() => {
    if (annotateChange !== null) {
      setAnnotateToggle(annotateChange)
    } else if (annotateParam !== null) {
      setAnnotateToggle(annotateParam)
    } else if (annotateConfig !== null) {
      setAnnotateToggle(annotateConfig);
    }
  }, [annotateConfig, annotateParam, annotateChange]);

  useEffect(() => {
    if (documentsChange !== null) {
      setDocumentsToggle(documentsChange)
    } else if (documentsParam !== null) {
      setDocumentsToggle(documentsParam)
    }
  }, [documentsParam, documentsChange]);

  useEffect(() => {
    let instances = examinationContext.instances;
    let total = 0;
    let selected = 0;

    if (mode === "share") {
      total = instances.filter(instance => instance.modality !== "SR").length;
      selected = instances
        .filter(i => isMediaSelected(i))
        .length;
    }

    if (mode === "print") {
      total = instances.filter(instance => instance.dicom_media_type === "image" && instance.modality !== "SR" ).length;
      selected = instances.filter(instance => instance.dicom_media_type === "image")
        .filter(i => isMediaSelected(i))
        .length;
    }

    setSelectedInstanceAmount(selected)
    setIsAllSelected(total === selected)
  }, [examinationContext.instances, mode])

  useEffect(() => {
    if (annotateChange !== null && examinationContext.share?.share_id) {
      updateExamSharedParams(examinationContext.examination.id, 'annotate', annotateChange);
      loadShareForExam();
    }
  }, [annotateChange]);

  useEffect(() => {
    if (documentsChange !== null && examinationContext.share?.share_id && examinationContext.share?.parameters !== null) {
      updateExamSharedParams(examinationContext.examination.id, 'documents', documentsChange);
      loadShareForExam();
    }
  }, [documentsChange]);

  const containerRef = useRef(null);

  const { examination } = examinationContext;
  const { config } = useAuth();
  const currentLanguage = localStorage.getItem('i18nextLng').toLowerCase();
  const currentTrimester = examination.trimester;
  const malformations = examination?.malformations || [];
  examination.type = (examination.type || (!!malformations.length ? "diagnostic" : "screening"));

  const printingTemplate = useMemo(() => config?.printing_configuration?.find((printing_config) => printing_config.trimester === currentTrimester?.toLowerCase()), [currentTrimester, config]);
  const [printingPreferenceImgPerPage, setPrintingPreferenceImgPerPage] = useState(DEFAULT_NB_IMG_PER_PAGE);
  const [mountNode, setMountNode] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [instancesToPrint, setInstancesToPrint] = useState([]);

  // The page will render a single QR whenever it's selected to exclusively do so or
  // the user did not select a single instance image yet selected to include a QR
  const printOnlyQr = useMemo(() => {
    return examinationContext.exclusivelyQr || (instancesToPrint.length === 0 && examinationContext.includeQRCode)
  }, [examinationContext.exclusivelyQr, instancesToPrint, examinationContext.includeQRCode])
  /** medias */
  const printableFrame = useRef(null);

  useEffect(() => {
    if (mode === "print")
      if (examinationContext.examination.id)
        ResourceApi.printingList(examinationContext.examination.id)
          .then(resp => {
            const slideMapString = JSON.stringify(slideMap)
            const sortedInstances = reorder(resp.data.data, slideMap, sortPlanesBy).filter(i => {
              if (slideMapString === '{}') return true;
              const slide = slideMap.get(i.dicom_instance_id)
              return !!slide;
            })
            setInstancesToPrint(sortedInstances)
          });
  }, [examinationContext.examination.id, examinationContext.instances])

  useEffect(() => {
    const sortedInstances = reorder(instancesToPrint, slideMap, sortPlanesBy).filter(i => {
      const slide = slideMap.get(i.dicom_instance_id)
      return !!slide;
    })
    if (sortedInstances.length > 0) setInstancesToPrint(sortedInstances)
  }, [slideMap, JSON.stringify(instancesToPrint)]);

  useEffect(() => {
    if (printingTemplate?.number_of_images_per_page != null) {
      setPrintingPreferenceImgPerPage(printingTemplate?.number_of_images_per_page);
    }
  }, [printingTemplate?.number_of_images_per_page]);

  useEffect(() => {
    /** awaiting for DOM creation inside the iframe (Firefox only) */
    if (printableFrame.current) printableFrame.current?.addEventListener('load', () => setIframeLoaded(true));
  }, [JSON.stringify(instancesToPrint)]);

  useEffect(() => {
    if (!iframeLoaded && !printableFrame.current?.contentWindow) return false;
    if (printableFrame.current?.contentWindow?.document?.head.getElementsByClassName("main-style").length > 0) return false;

    const css = document.createElement("style");
    css.className = "main-style";
    const styleString = `
      * {
        margin:0;
        padding:0;
        border:0;
        outline:0;
        vertical-align:baseline;
        background: transparent;
        box-sizing: border-box;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }
      *::-webkit-scrollbar {
        display: none;
      }

      @page {
        margin: 0;
      }

      html,body {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
      }
      body {
        font-size: 13px;
        font-family: "Metropolis", sans-serif;
        text-align: center;
      }

      .print-preview-page {
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%;
        min-height: 100%;
        margin-bottom: 15px;
        position: relative;
        padding: 100px 0.4cm 50px 0.4cm;
        background: white;
        color: black;
        font-size: 13px;
      }
      .qr-only-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 100px;
        margin-left: 20px;
        margin-right: 20px;
      }
      .common-message {
        font-size: 12px;
        text-align: left;
        margin-bottom: 20px;
      }
      .page-header {
        display: flex;
        width: 100%;
        left: 0;
        right: 0;
        background: white;
        top: 0;
        height: 100px;
        min-height: 100px;
        align-items: center;
        position: absolute;
        margin-bottom: 0.1cm;
        padding: 0.1cm 0.3cm;
      }
      .page-footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        padding: 0.1cm 0.3cm;
      }
      .page-footer > .powered-by {
        font-size: 11px;
      }
      .page-header > img {
        position: absolute;
        width: 2.4cm;
        height: 2.4cm;
        background: white;
      }
      .page-footer > img {
        width: 0.82cm;
        height: 0.82cm;
        max-width: 0.82cm;
        max-height: 0.82cm;
        background: white;
        margin-left: 0.1cm;
      }
      .page-header > .site-name {
        width: 100%;
        text-align: center;
        font-size: 16px;
      }
      .img-item {
        margin: 0;
        padding: 0;
      }
      .slides-content {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        height: 100%;
        column-gap: 1%;
        row-gap: 3%;
        align-content: flex-start;
      }
      .last-page2 .img-item {
        margin-bottom: 32%;
      }
      .qr-row {
        width: 100%;
      }
      .label-container {
        max-width: 100%;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
      }
      .label-container > p {
        text-overflow: ellipsis;
        margin: 0;
        font-size: 12px;
        margin-bottom: 2px;
      }
      .img-container {
        overflow: hidden;
        width: 100%;
        height: 100%;
      }
      img {
        border:0;
        object-fit: contain;
        width: 100%;
        height: 100%;
        position: relative;
        aspect-ratio: 4 / 3;
        background: #000;
      }
      .img-container.anonymized {
        margin-top: 5%;
      }
      .img-container.anonymized img {
        top: -10%;
      }

      .single-qr-code {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-column: span var(--number-of-columns);
        grid-row-start: span var(--number-of-rows);
      }
      .footer-qr-code {
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: center;
        margin-top: 2px;
      }

      .footer-qr-code > p, .top-qr-code > p {
        font-size: 11px;
        text-align: left;
      }
      .page-footer.new-footer {
        height: 6rem;
      }
      .footer-block {
        display: block;
        width: 100%;
        font-family: "Montserrat", sans-serif;
        padding: 0 3rem 2.3rem 3rem;
      }
      .footer-line {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-bottom: 0.3rem;
      }

      .footer-line .site-name {
        font-weight: bold;
        text-transform: uppercase;
      }
      .footer-line .patient-name {
        font-weight: bold;
        text-transform: capitalize;
      }

      .footer-line span:first-child::before {
        content: "";
        padding: 0;
      }
      .footer-line span::before {
        content: "|";
        padding: 0 0.4rem 0 0.3rem;
      }
      .footer-line img {
        padding-left: 0.2rem;
        max-height: 1.2rem;
        width: 4.4rem;
      }
      
      .footer-line .powered-by {
        display: flex;
        align-items: center;
      }
      
      
      `;
    const style = document.createTextNode(`
      ${styleString}
      @media print {
        html {
          box-sizing: border-box;
          font-size: 12px;
        }
        html, body {
          width: 100%;
          height: 100%;
        }
        *, *:before, *:after {
          box-sizing: inherit;
          margin: 0;
          padding: 0;
        }
        body, h1, h2, h3, h4, h5, h6, p, ol, ul {
          font-weight: normal;
        }
        ol, ul {
          list-style: none;
        }
        .print-preview-page {
          display: flex;
          flex-direction: column;
          height: 100%;
          width: 100%;
          min-height: 100%;
          position: relative;
          padding: 100px 0.4cm 50px 0.4cm;
          background: white;
          color: black;
        }
        .page-header {
          height: 100px;
        }
        .page-footer {
          height: 40px;
        }
        .label-container {
          max-width: 100%;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          margin:0;
          margin-bottom: 2px;
        }
        .label-container > p {
          text-overflow: ellipsis;
          margin: 0;
          font-size: 12px;
        }
        .slides-content {
          display: inline-flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          column-gap: 1%;
          row-gap: 2%;
          align-content: flex-start;
          width: 100%;
        }

        .img-item {
          display: inline-block;
        }
        img {
          border:0;
          object-fit: contain;
          width: 100%;
          height: 100%;
          position: relative;
          aspect-ratio: 4 / 3;
          background: #000;
        }
        .img-container {
          display: flex;    
          flex-direction: column;
          align-items: flex-start;
        }
        .img-container.anonymized {
          margin-top: 5%;
        }
        .img-container.anonymized img {
          top: -10%;
        }
        .footer-qr-code > p, .top-qr-code > p {
          font-size: 13px;
        }
        .common-message {
          font-size: 16px;
        }
      }
    `)
    css.appendChild(style);
    printableFrame.current?.contentWindow?.document?.head.appendChild(css);

    setMountNode(printableFrame.current?.contentWindow?.document?.body);

  }, [iframeLoaded, printableFrame.current?.contentWindow]);

  // Places the QR either at the top or bottom depend on whether it's the only rendered element
  const qrCodeContent = examinationContext.share?.share_link == null ?
    (<></>)
    : (
      <div className={printOnlyQr ? 'single-qr-code' : 'footer-qr-code'} key="qr-code">
        <QRCodeSVG
          value={`${examinationContext.share.share_link}&source=qr`}
          size={120}
          level="Q"
          includeMargin={true}
          imageSettings={{
            src: "/logo192.png",
            x: null,
            y: null,
            height: 22,
            width: 22,
          }} />
        <p>{__("examinationReview.qrCode.label")}</p>
      </div>
    );

  const frameComponent = useMemo(() => {
    const InstanceView = ({ instance }) => {
      let imgWidth = 48;
      let imgHeight = 22;

      if (printingPreferenceImgPerPage === 2) {
        imgWidth = 98;
        imgHeight = 46;
      }
      else if (printingPreferenceImgPerPage === 15) {
        imgWidth = 32;
        imgHeight = 16;
      }

      // We do not want to show the label for "other" type instances on the print
      const view = examinationContext.instanceViews.find(view => view?.id === instance.instance_view_id && view?.type !== "other");
      const imageBlob = examinationContext.instancePreviewBlobs[instance.dicom_instance_id];
      return (
        <div className="img-item" style={{ width: imgWidth + '%', height: imgHeight + '%' }}>
          {annotateToggle && (
            <div className="label-container">
              <p>
                {view?.label[currentLanguage] || <>&nbsp;</>}
              </p>
            </div>
          )}
          <div className={`img-container ${anonymizeToggle ? 'anonymized' : ''}`}>
            {imageBlob && <img
              src={URL.createObjectURL(imageBlob)}
              alt="instance"
            />}
          </div>
        </div>
      )
    }

    const ShallowInstanceView = () => {
      let imgWidth = 48;
      let imgHeight = 22;

      if (printingPreferenceImgPerPage === 2) {
        imgWidth = 98;
        imgHeight = 46;
      }
      else if (printingPreferenceImgPerPage === 15) {
        imgWidth = 32;
        imgHeight = 16;
      }

      return (
        <div className="img-item" style={{ width: imgWidth + '%', height: imgHeight + '%' }}>
        </div>
      )
    }

    const Header = () => {
      if (site?.logo_base64 == null || site?.logo_base64 == "") {
        return (
          <div className="page-header">
            <div className="site-name">{site?.name ?? ""}</div>
          </div>
        )
      }
      return (
        <div className="page-header">
          <img src={`data:image/png;base64, ${site.logo_base64}`} />
          <div className="site-name">{site?.name ?? ""}</div>
        </div>
      )
    }
    const Footer = ({currentPage, totalPages}) => {
      const date = examinationContext?.examination?.examination_date
      const examDate = date && formatDate(date) || '';
      const practitionerName = user?.title;
      const patientFirstName = formatName(examinationContext?.examination?.patient?.name).firstName || '';
      const patientDOB = formatDate(examinationContext?.patient?.dob);

      const showPage = totalPages > 1

      return <div className="page-footer new-footer">
            <div className="footer-block">
              <div className="footer-line">
                <div>
                  {site?.name && <span className="site-name">{site?.name}</span>}
                  {practitionerName && <span>{practitionerName}</span>}
                  {examDate && <span>{examDate}</span>}
                </div>
                {showPage && <div>Page {currentPage}/{totalPages}</div>}
              </div>
              <div className="footer-line">
                <div>
                  {patientFirstName && <span className="patient-name">{patientFirstName}</span>}
                  {patientDOB && <span>{patientDOB}</span>}
                </div>
                <div className="powered-by">
                  {__("branding.poweredBy")}
                  <img height="1rem" width="4rem" src="/logo-full.svg"/>
                </div>
              </div>
            </div>
          </div>
    }
    const SiteMessage = () => {
      if (site?.brand_message == null || site?.brand_message == "") return null;
      return (
        <div className="common-message">
          {parse(site.brand_message)}
        </div>
      )
    }

    const FullSlide = ({children, currentPage, totalPages}) => {
      return (
        <div className="print-preview-page">
          <Header/>
          {children}
          <Footer currentPage={currentPage} totalPages={totalPages}/>
        </div>
      )
    }

    const QrOnlyContent = ({totalPages}) => {
      return (
        <FullSlide currentPage={totalPages} totalPages={totalPages}>
          <div className="qr-only-content">
            {examinationContext.exclusivelyQr && <SiteMessage />}
            <div>{qrCodeContent}</div>
          </div>
        </FullSlide>
      )
    }
    const MaybePrintQrSection = () => {
      if (examinationContext.includeQRCode === false) return null;
      return (<div className="qr-row">{qrCodeContent}</div>)
    }
    const MaybePrintQrPage = ({totalPages}) => {
      if (examinationContext.includeQRCode === false) return null;
      return <QrOnlyContent totalPages={totalPages} />;
    }
    const LastSlideContent = ({ elements, currentPage, totalPages }) => {

      let qrThreshold = 7;
      if (printingPreferenceImgPerPage === 2) qrThreshold = 2;
      else if (printingPreferenceImgPerPage === 15) qrThreshold = 13;

      const renderShallowElements = (count) => {
        let list = [];
        for (let i = 0; i < count; i++) {
          list.push(<ShallowInstanceView key={"shallow"+i} />);
        }
        return list;
      }

      const className = "slides-content last-page" + printingPreferenceImgPerPage;

      if (elements.length && elements.length < qrThreshold) {
        return (
          <FullSlide key="last-page" currentPage={currentPage} totalPages={totalPages} >
            <div className={className} >
              {elements.map(i => <InstanceView instance={i} key={`slide-${i.dicom_instance_id}`} />)}
              {renderShallowElements(qrThreshold - elements.length -1)}
              <MaybePrintQrSection />
            </div>
          </FullSlide>
        )
      }
      return (
        <>
          <FullSlide key="last-page" currentPage={currentPage} totalPages={totalPages}>
            <div className="slides-content">
              {elements.map(i => <InstanceView instance={i} key={`slide-${i.dicom_instance_id}`} />)}
            </div>
          </FullSlide>
          <MaybePrintQrPage totalPages={totalPages} />
        </>
      )
    }
    const OnlySlidesContent = ({ elements, currentPage, totalPages }) => {
      return (
        <FullSlide currentPage={currentPage} totalPages={totalPages}>
          <div className="slides-content">
            {elements.map(i => <InstanceView instance={i} key={`slide-${i.dicom_instance_id}`} />)}
          </div>
        </FullSlide>
      )
    }
    const SlidesContent = () => {
      if (printingPreferenceImgPerPage == null) return null;
      const allItems = instancesToPrint ?? []
      // Chunk elements based on the amount of numbers per page selected
      // to separate pages
      const chunks = chunk(allItems, printingPreferenceImgPerPage)
      const extraPage = extraPageCount(allItems.length, printingPreferenceImgPerPage, examinationContext.includeQRCode)
      if (chunks.length > 1) {
        const totalPages = chunks.length + extraPage;
        const lastChunk = chunks.slice(-1)[0];
        const firstChunks = chunks.slice(0, -1)
        return (
          <>
            {firstChunks.map((chunk, chunkIndex) => (
              <OnlySlidesContent
                elements={chunk}
                key={`page-${chunkIndex}`}
                currentPage={chunkIndex + 1}
                totalPages={totalPages}
              />
            ))}
            <LastSlideContent elements={lastChunk} currentPage={chunks.length} totalPages={totalPages}/>
          </>
        )
      }
      else if (chunks.length === 0) return <LastSlideContent elements={[]} currentPage={1} totalPages={1 + extraPage} />
      return <LastSlideContent elements={chunks[0]} currentPage={1} totalPages={1 + extraPage}/>
    }

    return printOnlyQr ? <QrOnlyContent totalPages={1} /> : <SlidesContent />;

  }, [JSON.stringify(instancesToPrint), printingPreferenceImgPerPage, annotateToggle, anonymizeToggle, iframeLoaded, printableFrame.current?.contentWindow?.document, examinationContext.share, examinationContext.instanceViews, examinationContext.includeQRCode, examinationContext.exclusivelyQr, instancePreviewBlobs])

  const printIframe = async (ref) => {
    setPrintPreviewsLoading(true);
    await reloadInstancePreviewBlobList();

    const iframeWindow = ref.current.contentWindow || ref.current;
    setTimeout(() => {
      iframeWindow.print();
    }, 200)

    setPrintPreviewsLoading(false);
    return false;
  };

  const onPrintClick = useCallback(async (mediaId) => {
    const isSelected = instancesToPrint.some(i => i?.dicom_instance_id === mediaId);
    await examinationContext.toggleInstanceSelectedForPrinting(mediaId, !isSelected);
  }, [instancesToPrint, examination.id]);

  const handleIncludeQRCode = async () => {
    if (examinationContext.includeQRCode === false && !examinationContext.share?.share_link) {
      // There is no share link - for this exam - so create one
      await examinationContext.shareExamination();
    }
    examinationContext.setExclusivelyQr(_ => false)
    examinationContext.setIncludeQRCode((s) => !s)
  }

  // Generating selector function according to mode
  const isMediaSelected = ((m) => {
    if (m === "share") { return ((instance) => instance.shared_with_patient); }
    if (m === "print") { return ((instance) => instance.selected_for_print); }
    return ((_instance) => false);
  })(mode);

  // Generating toggle function according to mode
  const toggleMediaSelection = ((m) => {
    if (m === "share") { return ((instanceId) => examinationContext.toggleInstanceSelectedForSharing(instanceId)); }
    if (m === "print") {
      return ((instanceId) => {
        setSelectedInstancesLoading(selectedInstancesLoading => [...selectedInstancesLoading, instanceId])
        examinationContext.toggleInstanceSelectedForPrinting(instanceId)
      });
    }
    return ((_instanceId) => false);
  })(mode)

  const openQrCodePopup = async () => {
    setShareLoading(true);
    if (!examinationContext.share?.instant_share_link) {
      const generalDocListWithoutAssoc = generalDocuments.filter(doc => doc.id === null);
      await examinationContext.shareExamination(annotateToggle, documentsToggle, generalDocListWithoutAssoc);
    }

    setQrCodePopupOpen(true);
    setShareLoading(false);
  };

  const selectOnClick = (e, mediaId) => {
    e.stopPropagation();
    toggleMediaSelection(mediaId);
  }

  const toggleSharingListWidget = () => setIsSharingListWidget(value => !value);

  const SelectionCount = () => {
    return <b>{__("examinationSharingFlow.nImagesSelected", { count: selectedInstanceAmount })}</b>
  }

  const SelectAllLink = () => {
    const unselectAllInstances = () => {
      instances
        .filter(i => isMediaSelected(i))
        .forEach(i => toggleMediaSelection(i.id))
    }
    const selectAllInstances = () => {
      instances
        .filter(i => !isMediaSelected(i))
        .forEach(i => toggleMediaSelection(i.id))
    }

    return isAllSelected
      ? <a onClick={unselectAllInstances} >{__("examinationSharingFlow.unselectAll")}</a>
      : <a onClick={selectAllInstances} >{__("examinationSharingFlow.selectAll")}</a>
  }

  const filterOptions = [
    {
      type: "heading",
      label: __("examination.settings.zoomLevel"),
    },
    {
      icon: zoomLevel === 3 ? "zoom-out" : "zoom-in",
      label: zoomLevel === 3 ? __("examination.settings.zoomOut") : __("examination.settings.zoomIn"),
      value: "zoom"
    },
  ];
  const filterChangeAction = (action) => {
    if (action === "zoom") updateZoomLevel(zoomLevel === 3 ? 5 : 3);
  };

  const onReportClick = () => {
    onReport && onReport();
  };
  const onShareClick = () => {
    if (mode === "share") {
      closeAction && closeAction();
    } else {
      onShare && onShare();
    }
  };
  const onTopBarPrintClick = () => {
    if (mode === "print") {
      closeAction && closeAction();
    } else {
      onPrint && onPrint();
    }
  };
  const cancelClickEvent = (e) => {
    e.stopPropagation();
  }

  const showAnnotate = currentTrimester !== 'ND';
  const showQrToggle = mode === 'print';
  const showPageSize = showQrToggle;
  const showDocuments = mode === "share" && isFeatureFlagEnabled("sonio.attachments");
  const parametersCount = [showAnnotate, showQrToggle, showPageSize,  showDocuments].filter(x => x).length;

  const printLabel = () => {
    if (printPreviewsLoading) {
      return <><LoaderInline />&nbsp;{__("examinationSharingFlow.actions.print.loading")}</>;
    }
    return __("examinationSharingFlow.actions.print");
  }

  const shareLabel = () => {
    if (shareLoading) {
      return <><LoaderInline />&nbsp;{__("examinationSharingFlow.actions.share.loading")}</>;
    }
    return __("examinationSharingFlow.actions.share");
  }

  return (
    <>
      <div className="examination-sharing-flow-sharing-select-container">
        <SharingTopBar
          mode={mode}
          filterOptions={filterOptions}
          onFilterChange={filterChangeAction}
          close={closeAction}
          onReport={onReportClick}
          onShare={onShareClick}
          onPrint={onTopBarPrintClick}
          showReport={appContext.protocolsEnabled}
        />
        {parametersCount && <div className='examination-sharing-flow-parameters-bar'>
          <span className="parameters-title">{__("examinationSharingFlow.parameters", {count: parametersCount})}: </span>
          {showAnnotate && (
            <label onClick={() => setAnnotateChange(!annotateToggle)}>
              {__("examinationSharingFlow.parameters.annotateImages")}
              <Checkbox checked={annotateToggle} onChange={setAnnotateChange}/>
            </label>
          )}
          {showDocuments && (<div className="examination-sharing-flow-parameters-bar-document-label">
            <label onClick={cancelClickEvent}>
              <span onClick={toggleSharingListWidget}>
                {__("examinationSharingFlow.parameters.documents", {count: totalActiveUploadedDocuments})}
              </span>
              <Icon className="switch-icon" name={isSharingListWidget ? "up" : "down"}
                    onClick={toggleSharingListWidget}/>
              <Checkbox
                checked={documentsToggle}
                onChange={() => setDocumentsChange(!documentsToggle)}
              />
            </label>
            {isSharingListWidget && <SharingListWidget onSuccesfulUpload={() => setDocumentsChange(true)}
                                                       closeWidget={() => toggleSharingListWidget(false)}/>}
          </div>)}
          {showQrToggle && (<>
            <label onClick={handleIncludeQRCode}>
              {__("examinationSharingFlow.parameters.addQRcode")}
              <Checkbox checked={examinationContext.includeQRCode}/>
            </label>
          </>)}
          {showPageSize && (<>
            <label>{__("examinationSharingFlow.parameters.numberPerPage")}
              <SelectInput
                value={printingPreferenceImgPerPage}
                placeholder={{
                  icon: "settings-alt",
                  label: "",
                  value: ""
                }}
                onChange={setPrintingPreferenceImgPerPage}
                options={[{type: "string", label: "2", value: 2},
                  {type: "string", label: "8", value: 8},
                  {type: "string", label: "15", value: 15}]}
              />
            </label>
          </>)
          }
        </div>
        }
        <div className='examination-sharing-flow-sharing-select'>
          <div className={`examination-live-grid-container trimester-${currentTrimester} zoom-level-${zoomLevel}`}
            ref={containerRef}>
            <div className="examination-live-grid">
              <div className="selection-status">
                <SelectionCount />
                <SelectAllLink />
              </div>
              {!!instances.length
                ? <div className="slide-grid-container" style={{ "--number-of-columns": zoomLevel || globalConfig.examinationLive.numberOfColumns }}>
                  {sortedInstances.map(media => {
                    const slide = annotateToggle && currentTrimester !== "ND" ? slideMap.get(media.id) : null;
                    const isSelected = (mode === "share" && media?.shared_with_patient) || (mode === "print" && media?.selected_for_print)
                    return <div
                      key={media.id}
                      className={isSelected ? "examination-live-slide selected" : "examination-live-slide"}
                      onClick={e => { selectOnClick(e, media.id) }}
                    >
                      <Slide
                        slide={slide}
                        trimester={currentTrimester}
                        media={media}
                        zoomLevel={zoomLevel}
                        shareButtonsOverride={<SlideShareDisplay media={media} mode={mode} />}
                      />
                    </div>
                  })}
                </div>
                : <div></div>
              }
            </div>
          </div>
          <div className='examination-sharing-flow-action-bar'>
            <Button
              label={__("examinationSharingFlow.actions.back")}
              variant='outline'
              onClick={closeAction}
            />
            {mode === "share" && (<Button
              icon={shareLoading ? "" : "sharing-indicator"}
              label={__("examinationSharingFlow.actions.share")}
              isLoading={shareLoading}
              size="full-width"
              disabled={shareLoading || (selectedInstanceAmount === 0 && !(totalActiveUploadedDocuments !== 0 && documentsToggle))}
              onClick={openQrCodePopup}
            />)}
            {mode === "print" && (<Button
              icon={printPreviewsLoading ? "" : "print"}
              label={__("examinationSharingFlow.actions.print")}
              isLoading={printPreviewsLoading}
              size="full-width"
              disabled={(examinationContext.patient === null) || ( loadedPreviewsList.length !== 0 ) || (selectedInstanceAmount === 0 && !examinationContext.includeQRCode)}
              onClick={() => printIframe(printableFrame)}
            />)}
          </div>
        </div>
      </div>
      {mode === "share" && qrCodePopupOpen && (
        <SharingPreviewPopup onClose={() => setQrCodePopupOpen(false)} annotate={annotateToggle} documents={documentsToggle} />
      )}

      <iframe
        className='examination-review-print-preview-iframe'
        ref={printableFrame}
        title="CNEOF medias">
        {mountNode && createPortal(frameComponent, mountNode)}
      </iframe>
      <div className="modal-background" onClick={closeAction} />
    </>
  )
}

export default withTranslation()(SharingSelect)

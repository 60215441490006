import { useContext, useEffect, useRef, useState } from "react";
import { withTranslation } from "react-i18next";
import Button from '../../atoms/Button/Button';
import Icon from "../../atoms/Icon/Icon";
import TextInput from "../../atoms/TextInput/TextInput";
import { AppContext } from "../../context-providers/App";
import { ExaminationContext } from "../../context-providers/Examination";
import useAuth from "../../context-providers/Auth";
import { formatName, getNiceGestionalAgeFromDays, createFullName } from "../../services/examination";
import ResourceApi from "../../services/resource";
import { formatDate } from '../../utils';
import "./PatientName.css";

/**
 * <PatientName
 *  patient={patient object}
 * >
 */

let patientSearchTimeout = false;
const PatientName = ({ t: __, patient = false, episode = {}, examinationDate = null , variant=""}) => {
  const appContext = useContext(AppContext);
  const examinationContext = useContext(ExaminationContext);
  const { isFeatureFlagEnabled } = useAuth();
  const isSoniopedia = isFeatureFlagEnabled("soniopedia")
  const popup = useRef(null);
  const container = useRef(null);
  const moreOptions = useRef(null);
  const preferedDateFormat = appContext.preferences.date_format;
  const gestationalAge = getNiceGestionalAgeFromDays(__, examinationContext?.dating?.value) || "";

  const [editMode, setEditMode] = useState(false);

  const [firstName, setFirstName] = useState(!!patient ? formatName(patient.name).firstName : '');
  const [lastName, setLastName] = useState(!!patient ? formatName(patient.name).lastName : '');
  const [middleName, setMiddleName] = useState(!!patient ? formatName(patient.name).middleName : '');
  const [prefix, setPrefix] = useState(!!patient ? formatName(patient.name).prefix : '');
  const [showFetusSexNotice, setShowFetusSexNotice] = useState(false);
  const [dob, setDob] = useState("");
  const [soniopediaValidationError, setSoniopediaValidationError] = useState(false);

  const [previousEpisode, setPreviousEpisode] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [patientDetails, setPatientDetails] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);

  useEffect(() => {
    setFirstName(!!patient ? formatName(patient.name).firstName : '')
    setLastName(!!patient ? formatName(patient.name).lastName : '')
    setMiddleName(!!patient ? formatName(patient.name).middleName : '')
    setPrefix(!!patient ? formatName(patient.name).prefix : '')
  }, [examinationContext?.patient?.id, examinationContext?.patient?.name])

  useEffect(() => {
    if (!moreOptions.current) return false;
    moreOptions.current.style.height = showMoreOptions ? moreOptions.current.scrollHeight + 'px' : 0;
  }, [showMoreOptions]);

  useEffect(() => {
    setShowMoreOptions(false);

    if (editMode) {
      setDob(patient?.dob || "");
      document.querySelector('#root > .App > div').appendChild(popup.current);
    } else {
      const node = document.querySelector('#root > .App > div > .patientname-popup');
      if (node) node.parentNode.removeChild(node);
      setSearchResults([]);
      setPatientDetails(false);
      setPreviousEpisode(false);
    }
  }, [editMode, patient]);

  useEffect(() => {
    if (!!patient?.id) {
      setSearchResults([]);
      return false;
    }

    if (patientSearchTimeout) clearTimeout(patientSearchTimeout);
    if (firstName.length >= 3 || lastName.length >= 3) {
      let searchString = [
        firstName.length >= 3 ? firstName : "",
        lastName.length >= 3 ? lastName : "",
      ].join(" ").trim();
      patientSearchTimeout = setTimeout(() => ResourceApi.getPatients(searchString).then(response => setSearchResults(response.data.data)), 500);
    } else {
      setSearchResults([]);
    }
  }, [firstName, lastName]);

  const updateFirstName = (value) => {
    setPatientDetails(false);
    setFirstName(value);
  }

  const updateLastName = (value) => {
    setSoniopediaValidationError(false);
    setPatientDetails(false);
    setLastName(value);
  }

  const filterOnlyAllowedChar = (event) => {
    const actionKeys = ["Home", "End", "Delete", "Backspace", "ArrowRight", "ArrowLeft", "Tab", "Control"]
    const charKeys = /^[\d]$/g
    if (!actionKeys.includes(event.code) && !charKeys.test(event.key)) event.preventDefault()
  }

  const isSoniopediaIdValid = (lastName, middleName, firstName, prefix) => {
    const tmpFullName = createFullName(lastName, middleName, firstName, prefix).replace(/\^/g, '')
    const charKeys = /^[\d]+$/g
    return charKeys.test(tmpFullName)
  }

  const updatePatient = async () => {
    if (!patient?.id) return false;
    if (isSoniopedia && ! isSoniopediaIdValid(lastName, middleName, firstName, prefix)) return setSoniopediaValidationError(true);
    examinationContext.updatePatient(patient.id, {
      name: createFullName(lastName.trim(), middleName.trim(), firstName.trim(), prefix.trim()),
      dob: dob,
    }).then(resp => {
      if (editMode) setEditMode(false);
    })
  }

  const createPatientAndAssignToCurrentExam = async () => {
    if (isSoniopedia && ! isSoniopediaIdValid(lastName, middleName, firstName, prefix)) return setSoniopediaValidationError(true);
    const patient = await examinationContext.createPatient({ firstName, lastName, dob });
    if (patient.id) {
      examinationContext.updateExamination({patient_id: patient.id, episode_id: patient.current_episode_id})
      setEditMode(false);
    }
  }

  const createEpisodeAndAssignToCurrentExam = async (patient) => {
    const episode = await examinationContext.createEpisode({ patientId: patient.id });
    if (episode.id) {
      setEditMode(false);
      examinationContext.updateExamination({
        episode_id: episode.id,
        patient_id: patient.id
      });
    }
  }

  const associateEpisodeToCurrentExam = (patient, episode) => {
    examinationContext.updateExamination({
      episode_id: episode.id,
      patient_id: patient.id
    });
    setEditMode(false);
  }

  const removePatientExamAssociation = () => {
    setPreviousEpisode({ ...examinationContext.episode });
    setShowMoreOptions(false);
    examinationContext.updateExamination({patient_id: null, episode_id: null})
  }

  const cancel = () => {
    if (!!previousEpisode) {
      examinationContext.assignExaminationToEpisode(examinationContext.examination, previousEpisode.id);
    }
    setEditMode(editMode => !editMode);
  }

  return (
    <div className={`patientname-container ${variant}`}  ref={container}>
      {examinationContext.fetusSexVisibility && examinationContext.fetusSexVisibility !== "visible" && <div className="patientname_warning" onClick={() => setShowFetusSexNotice(open => !open)}>
        <Icon name="fetus-sex-hidden" />
        {!!showFetusSexNotice && (
          <div className="patientname_warning_notice">
            {__("patients.fetusSex." + examinationContext.fetusSexVisibility)}
          </div>
        )}
      </div>}
      <div className="patientname ph-no-capture" onClick={() => setEditMode(editMode => !editMode)}>
        <Icon name="patient" />
        <span className="fullname">{patient ? formatName(patient.name).fullName : __('patients.anonymous')}</span>
        {!!gestationalAge && (
          <span className="gestational-age">{gestationalAge}</span>
        )}
      </div>
      <div className={`patientname-popup ${editMode ? 'open' : 'closed'}`} ref={popup} onClick={() => setEditMode(editMode => !editMode)}>
        {editMode && (
          <>
            <div className={`patientname-popup-inner ${!!searchResults.length ? 'with-search-results' : ''}`} onClick={e => e.stopPropagation()}>
              <div className="patientname-popup-patient">
                <Icon name="close" onClick={cancel} className="clickable" />
                <div className="row">
                  <div className="w12">
                    <TextInput className="ph-no-capture"
                      value={lastName}
                      label={__('patient_details.lastname')}
                      onChange={(value) => updateLastName(value)}
                      maxLength="30"
                      fullwidth
                      onKeyDown={isSoniopedia ? filterOnlyAllowedChar : () => {}}
                    />
                    {isSoniopedia && <small className={`soniopedia-validation-helper ${soniopediaValidationError ? 'error' : ''}`}>
                      {__('patient_details.lastname.validation')}<code>0123456789</code>
                    </small>}
                  </div>
                </div>
                {!isSoniopedia && (<div className="row">
                  <div className="w12">
                    <TextInput className="ph-no-capture"
                      value={firstName}
                      label={__('patient_details.firstname')}
                      onChange={(value) => updateFirstName(value)}
                      maxLength="30"
                      fullwidth
                    />
                  </div>
                </div>)}
                <div className="row vertical-bottom">
                  {/* <div className="w6">
                    <label>{__("patient_details.birthdate")}<br />
                      <DateInput className="ph-no-capture"
                        value={dob}
                        localeOrFormat={preferedDateFormat}
                      />
                    </label>
                  </div> */}
                  {!!patient?.id && <div className="w12 text-align-right">
                    <Button label={__("patient_details.medicalhistory.update")} onClick={updatePatient} />
                  </div>}
                  {!!patient?.id && (
                    <div className="w12 more-options-link">
                      <Button
                        label={__(showMoreOptions ? "patient_details.lessOptions" : "patient_details.moreOptions")}
                        iconAfter={showMoreOptions ? "up" : "down"}
                        variant="link"
                        onClick={() => setShowMoreOptions(showMoreOptions => !showMoreOptions)}
                      />
                    </div>
                  )}
                </div>
                {!patient?.id ? (
                  <div key="cta" className="row">
                    <div className="w4">
                      <div className="submit">
                        <Button
                          label={__("patient_details.cancel")}
                          variant="outline"
                          onClick={cancel}
                        />
                      </div>
                    </div>
                    <div className="w8">
                      <div className="submit">
                        <Button
                          label={__("patient_details.create")}
                          disabled={!firstName && !lastName}
                          onClick={createPatientAndAssignToCurrentExam}
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div key="moreoptions" className="row more-options" ref={moreOptions}>
                    <div className="w12">
                      <div className="submit">
                        <Button
                          label={__("patient_details.removePatient")}
                          icon="reload"
                          variant="outline"
                          onClick={removePatientExamAssociation}
                        />
                        {!!examinationContext.episode?.conception_date && (
                          <small><Icon name="warning" /> {__("patient_details.warningConceptionDateWillBeLost")}</small>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className={`patientname-popup-search ${patientDetails ? 'show-details' : ''}`}>
                <div className="patientname-popup-search-list">
                  <label>{__("patient_details.associateToExistingPatient")}</label>
                  <ul>
                    {searchResults.map(patient => (
                      <li key={patient.id} onClick={() => setPatientDetails(patient)}>
                        <span className="patient-patient-details">
                          <span className="patient-name">{formatName(patient.name).fullName}</span>
                          <span className="patient-dob">{patient?.dob ? formatDate(patient.dob) : ""}</span>
                        </span>
                        <Icon name="right" />
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="patientname-popup-search-details">
                  {patientDetails && (
                    <>
                      <div className="patientname-popup-search-details-header">
                        <Icon name="left" onClick={() => setPatientDetails(false)} />
                        <div>
                          <h3>{formatName(patientDetails.name).fullName}</h3>
                          {!!patientDetails?.dob && (
                            <span className="patient-dob">{formatDate(patientDetails.dob, preferedDateFormat)}</span>
                          )}
                        </div>
                      </div>
                      <label>{__("patient_details.selectEpisode")}</label>
                      <ul>
                        {patientDetails.pregnancies.sort((a, b) => !a.conception_date || a.conception_date < b.conception_date ? -1 : 1).filter(episode => episode.state === "active").map((episode, index) => (
                          <li key={episode.id} onClick={() => associateEpisodeToCurrentExam(patientDetails, episode)}>
                            <span className="episode-details">
                              {__("patient_details.episodeNumber").replace("{number}", index + 1)}
                            </span>
                            {!!episode.conception_date && (
                              <span className="episode-conceptiondate">
                                {__("patient_details.conceptionDate") + " "}
                                {formatDate(episode.conception_date, preferedDateFormat)}
                              </span>
                            )}
                          </li>
                        ))}
                        <li onClick={() => createEpisodeAndAssignToCurrentExam(patientDetails)}>
                          <Icon name="add" /> {__("patient_details.newEpisode")}
                        </li>
                      </ul>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-background" onClick={() => setEditMode(editMode => !editMode)} />
          </>
        )}
      </div>
    </div>
  );
};

export default withTranslation()(PatientName);

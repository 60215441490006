import { useCallback, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { gaToTrimester } from '../../../services/examination';
import SearchBar from "../../../components/SearchBar/SearchBar";
import SimpleSearchEngine from "../../../components/SearchBar/SimpleSearchEngine";
import ResourceApi from '../../../services/resource';
import Recents from '../../../services/recents';
import Icon from '../../../atoms/Icon/Icon';
import Button from "../../../atoms/Button/Button";
import {LevenshteinDistance} from '../../../utils';

/*
supported sources:
• ICD10
*/

const SOURCES = {
  "Elixir.SonioCore.Integration.Connector.IMO": "IMO",
  "Elixir.SonioCore.Integration.Connector.Dictionary": "Dictionary",
}

const ReportTemplateSearch = ({t: __, props, placeholder = {}, carryForward = {}, examinationContext, user = {}, numberOfFetuses = 1, fetuses = [], fetusNames = [], onEndEditing, canEdit}) => {
  const [searchKeyPrevious, setSearchKeyPrevious] = useState("");
  const [resultsFromApi, setResultsFromApi] = useState([]);
  const [minLength, setMinLength] = useState(3);
  const [filterResults, setFilterResults] = useState(true);
  const [filterByFetusNumber, setFilterByFetusNumber] = useState(null);
  const recentCollection = `${props.source}_${props.data}_${examinationContext.examination?.trimester}`;
  const searchBoxPlaceholder = props.placeholder || __("examinationReview.searchBar.defaultPlaceholder", {source: props.source})
  const currentTrimester = gaToTrimester(examinationContext.examination.dating?.value);

  const stringifyCodes = (codes) => {
    if (!codes || !Array.isArray(codes)) return "";
    return codes.map(code => code.code).join(", ");
  }

  const highlight = (string, searchKey) => {
    let html = string;
    for (const key of stripSpecialChars(searchKey).split(" ")) {
      html = html.replace(new RegExp(`(${key})`, "ig"), `<b>$1</b>`);
    }
    return <span dangerouslySetInnerHTML={{ __html: html }}></span>
  }

  const stripSpecialChars = (string) => `${string}`.replace(/[^\w\s\']/g, " ").replace(/\s+/g, " ").trim();

  const computeRelevance = (resultLine, {searchKey, recents = [], relevant = true}) => {
    let relevance = 0;
    const lowerSearchKey = stripSpecialChars(searchKey).toLowerCase();
    const result = ` ${stripSpecialChars(resultLine.value)} `.toLowerCase();

    /* full match: +200 */
    if (result === ` ${lowerSearchKey} `) relevance += 200;
    /* starts with the exact search key: +50 */
    else if (result.startsWith(` ${lowerSearchKey}`)) relevance += 50;
    /* match the whole search key: +30 */
    else if (result.match(new RegExp(` ${lowerSearchKey} `))) relevance += 30;
    /* starts with the whole search key: +10 */
    else if (result.match(new RegExp(` ${lowerSearchKey}`))) relevance += 10;

    for(const key of lowerSearchKey.split(" ")) {
      /* match a whole word: +20 */
      if (result.match(new RegExp(` ${key} `))) relevance += 20;
      /* a word starts with: +5 */
      else if (result.match(new RegExp(` ${key}`))) relevance += 10;
    }


    /* contains "unspecified": -10 */
    if (result.includes("unspecified")) relevance -= 10;

    /* sort by number of fetuses */
    ["singleton", "twin", "triplet", "quadruplet", "quintuplet", "sextuplet", "multiple"].forEach((fetusKey, index) => {
      if (result.match(new RegExp(` ${fetusKey} `))) relevance -= index;
    });

    /* coming from dictionary: +100 */
    if (resultLine.library?.includes("Dictionary")) relevance += 100;
    
    /* coming from carry forward: +100 */
    if (Object.values(carryForward || {}).some(item => item?.label === resultLine.value)) relevance += 100;

    /* coming from most used: +100 + amount of clicks */
    const mostUsed = Recents.getFromMostUsed(resultLine.value, recentCollection, user?.id);
    if (mostUsed) {
      let matchWord = false;
      for(const key of lowerSearchKey.split(" ")) {
        if (result.match(new RegExp(` ${key} `))) matchWord = true;
      }
      relevance += (matchWord ? 100 : 0) + mostUsed.clicks;
    }

    for(const code of resultLine.codes) {
      /* ICD10 code start with O (Pregnancy, childbirth and the puerperium) */
      if(code.match(/^O/)) relevance += 100
      /* ICD10 code start with N (Diseases of the genitourinary system) */
      if(code.match(/^N/)) relevance += 50
      /* ICD10 code start with Z (Factors influencing health status and contact with health services) */
      if(code.match(/^Z/)) relevance += 100

      /* ICD10 not relevant to our speciality */
      if(code.match(/^M/)) relevance -= 50
      if(code.match(/^C/)) relevance -= 50
      if(code.match(/^K/)) relevance -= 50
    }

    /* not relevant for the current trimester / fetus: -1000 */
    if (!relevant) relevance -= 1000;

    return relevance;
  }

  const saveAsRecent = (item) => {
    if (!props.data) return false;

    Recents.addToRecent(recentCollection, user?.id, item.value, {
      value: item.value,
      codes: item.codes,
      codes_object: item.codes_object,
      private: item.private,
      connector: item.connector,
    });
  };

  const getTags = (r, fetuses) => {
    const selected_fetuses = r.codes
      .map((code) => {
        if (code?.applicable_filters?.min_number_of_fetuses >= 2)
          return code?.applicable_filters?.fetus
      })
      .map((fetus) => fetuses.find(({dicom_id}) => dicom_id == fetus)?.fetus?.label)
      .filter((fetus) => fetus)
      .filter((value, index, array) => array.indexOf(value) === index)
      .map(f => `${__("examinationReview.fetus")} ${f}`)

    return (
      [
        selected_fetuses
      ]
    )
      .flatMap((e) => e)
  }

  const isApplicableConcept = (item, force = false) => {
    if(typeof(item.codes?.every) !== 'function') return false
    return !!item.codes?.every(code => isCodeApplicable(code))
  };

  const getFetusNumberFromFilter = (number) => number === "more" ? Math.max(6, numberOfFetuses) : number;

  const isCodeApplicable = (code) => {
    /* Remove old deprecated codes */
    if(!code.applicable_filters) return false;

    if (filterByFetusNumber && code.applicable_filters?.fetus !== filterByFetusNumber) return false;
    if (code.applicable_filters?.fetus && numberOfFetuses < getFetusNumberFromFilter(code.applicable_filters?.fetus)) return false;
    /* We have a selected GA so we verify the code apply to this trimester */
    if (currentTrimester && !code.applicable_filters?.trimesters?.includes(["", "one", "two", "three"][currentTrimester])) return false;
    if (!numberOfFetuses && code.applicable_filters?.trimesters?.length) return false;
    if (code.applicable_filters?.min_number_of_fetuses > numberOfFetuses) return false;
    if (getFetusNumberFromFilter(code.applicable_filters?.max_number_of_fetuses) < numberOfFetuses) return false;
    return true;
  }

  const onSearch = useCallback(async (searchKey) => {
    switch (props.source) {
      case "ICD10":
      case "CPT":
        const minLength = 3;
        setMinLength(minLength);

        const coding_system = props.source === "ICD10" ? "diagnostic" : "procedure";

        const results = searchKey !== searchKeyPrevious
          ? await ResourceApi.searchCode(examinationContext.examination.id, coding_system, searchKey, {type: props.data})
            .then((r) => {
              return r.data
            })
            .catch(() => {
              return [{data: [], copyright: ""}];
            })
          : resultsFromApi;

        setResultsFromApi(results);
        setSearchKeyPrevious(searchKey);

        const recents = Recents.getMostUsed(recentCollection, user?.id, 100);

        const displays = results
          .filter(({data}) => data.length > 0)
          .map(({data}) =>
            data
            .map(r => {
              const library = [
                SOURCES[r.connector],
                Recents.getFromMostUsed(r.description, recentCollection, user?.id) ? "MostUsed" : false,
                Object.values(carryForward || {}).some(item => item?.label === r.description) ? "CarryForward" : false,
              ].filter(s => s);

              return {
                label: <>{highlight(r.description, searchKey)} <small>{stringifyCodes(r.codes)}</small></>,
                value: r.description,
                codes: stringifyCodes(r.codes),
                codes_object: r.codes,
                source: props.source,
                tags: getTags(r, fetuses),
                library,
                private: r.private,
                connector: r.connector,
                relevance: computeRelevance(
                  {
                    value: r.description,
                    library,
                    source: (SOURCES[r.connector] || ""),
                    codes: r.codes.map(({code}) => code),
                  },
                  {searchKey, recents, relevant: !!isApplicableConcept(r, true)}),
                relevant: !!isApplicableConcept(r, true),
                r,
              };
            })
          )
          .reduce((displays, res) => displays.concat(res), [])
          .filter(r => 
            (
              !filterResults
              || r.relevant
            ) && !placeholder.value?.[r.value]
          );

        const scoredResults = displays.concat(getRelevantMostUsed(searchKey)).concat(getRelevantCarryForward(searchKey));
        return scoredResults
          .filter((r, i) => !!r.codes_object?.length && scoredResults.findIndex(d => d.value === r.value) === i) // remove duplicates
          .sort((a, b) => b.relevance - a.relevance);
    }
  }, [props.source, ResourceApi.searchCode, examinationContext.examination.id, resultsFromApi, searchKeyPrevious, filterByFetusNumber, filterResults, setFilterResults, currentTrimester]);

  const onSelect = useCallback(async (items) => {
    switch (props.source) {
      case "ICD10":
      case "CPT":
        if (!Array.isArray(items)) items = [items];

        let newValue = placeholder.value;
        if (!newValue || typeof newValue !== 'object') {
          newValue = {};
        }
        
        for (const item of items) {
          saveAsRecent(item);
          delete newValue[item.value];
          newValue[item.value] = {
            value: true,
            label: item.value,
            order: Object.keys(newValue).length,
            codes: item.codes,
            codes_object: item.codes_object,
            private: item.private,
            connector: item.connector,
          };
        }

        const id = props.data;
        const custom = props.custom === "true";
        onEndEditing(id, {value: newValue}, custom);

        break;
    }
  }, [JSON.stringify({props, placeholder}), ResourceApi.searchCode, onEndEditing, saveAsRecent]);

  const searchFilter = (item, searchKeyParam) => {
    const str1 = item.value.toLowerCase();
    const str2 = (searchKeyParam || "").toLowerCase();

    const distance = LevenshteinDistance(str1, str2);
    const minimumDistance = Math.max(str1.length, str2.length) - Math.min(str1.length, str2.length)
    return (distance - minimumDistance) < 2;
  }

  const getRelevantMostUsed = (searchKey) => {
    return Recents.getMostUsed(recentCollection, user?.id, 100)
      .filter((item) => item?.item?.value && searchFilter(item.item, searchKey))
      .map(item => ({
        ...item.item,
        label: <>{highlight(item.item.value, searchKey)} <small>{stringifyCodes(item.item.codes_object)}</small></>,
        value: item.item.value,
        codes: stringifyCodes(item.item.codes_object),
        codes_object: item.item.codes_object,
        from: "most-used",
        library: ["MostUsed"],
        relevance: computeRelevance(
          {
            value: item.item.value,
            source: "MostUsed",
            codes: item.item.codes_object.map(({code}) => code),
            other_codes: item
          },
          { searchKey, recents: [], relevant: !!isApplicableConcept({ ...item.item, codes: item.item.codes_object}, true)}),
        relevant: !!isApplicableConcept({ ...item.item, codes: item.item.codes_object}, true),
      }));
  }

  const getRelevantCarryForward = (searchKey) => {
    return Object.values(carryForward || {})
      .filter((item) => item?.label && searchFilter({...item, value: item.label}, searchKey))
      .map(item => ({
        ...item,
        label: <>{highlight(item.label, searchKey)} <small>{stringifyCodes(item.codes_object)}</small></>,
        value: item.label,
        codes: stringifyCodes(item.codes_object),
        codes_object: item.codes_object,
        from: "carry-forward",
        library: ["CarryForward"],
        relevance: computeRelevance(
          {
            value: item.label,
            source: "CarryForward",
            codes: item.codes_object.map(({code}) => code),
            other_cods: item.codes_object
          },
          {searchKey, recents: [], relevant: !!isApplicableConcept({...item, codes: item.codes_object}, true)}),
        relevant: !!isApplicableConcept({...item, codes: item.codes_object}, true),
      }));
  }

  const getDefaultResult = () => {
    switch (props.source) {
      case "ICD10":
      case "CPT":

        const carriedForward = Object.values(carryForward)
          .filter((item) => !!item?.codes_object && !placeholder.value?.[item?.label])
          .map((item) => ({...item, description: item.label}))
          .map(item => ({
            ...item,
            label: <><small><Icon name="tab" /></small> {item.label} <small>{item.codes}</small></>,
            value: item.label,
            from: "carry-forward",
          }));

        const mostRecentFilter = (item) => {
          return item?.item?.value && !placeholder.value?.[item?.item?.value] && !!isApplicableConcept({...item.item, codes: item.item.codes_object});
        }
        
        const mostUsedFilter = (item) => {
          return item?.item?.value
            && !placeholder.value?.[item?.item?.value]
            && !mostRecent.some(recentItem => recentItem.value === item.item.value)
            && !!isApplicableConcept({...item.item, codes: item.item.codes_object});
        }

        const mostRecent = Recents.getRecents(recentCollection, user?.id, 5, mostRecentFilter)
          .map(item => ({
            ...item.item,
            label: <><small><Icon name="clock" /></small> {item.item.value} <small>{item.item.codes}</small></>,
            value: item.item.value,
            from: "recent",
          }));

        const mostUsed = Recents.getMostUsed(recentCollection, user?.id, 15, mostUsedFilter)
          .map(item => ({
            ...item.item,
            label: <><small><Icon name="reload" /></small> {item.item.value} <small>{item.item.codes}</small></>,
            value: item.item.value,
            from: "most-used",
          }));

        const carriedForwardHeader = carriedForward?.length ? [{
          label: <span>{__("suggestion.previousExaminations")} <Button label={__("searchbar.library.CarryForward.addAll")} onClick={() => onSelect(carriedForward)} variant="link" size="small" /></span>,
          value: false,
          type: 'heading',
        }] : [];

        const mostRecentHeader = mostRecent?.length ? [{
          label: __("suggestion.recent"),
          value: false,
          type: 'heading',
        }] : [];

        const mostUsedHeader = mostUsed?.length ? [{
          label: __("suggestion.mostUsed"),
          value: false,
          type: 'heading',
        }] : [];

        const warning = [{
          label: <><Icon name="warning" /> {__("signSearchBar.typeAtLeastNChars", {amount: minLength})}</>,
          value: false,
          type: 'warning',
          relevance: 1000,
        }];

        return [...warning, ...carriedForwardHeader, ...carriedForward, ...mostRecentHeader, ...mostRecent, ...mostUsedHeader, ...mostUsed];

      default:
        return null;
    };
  };

  const opts = {}
  const defaultResult = getDefaultResult().map(r => ({
    ...r,
    codes: stringifyCodes(r.codes_object || []),
  }));
  
  if(defaultResult)
    opts.defaultResult = defaultResult;

  return (
    <div className="template-searchbar-wrapper">
      <SimpleSearchEngine filters={`${filterByFetusNumber} ${filterResults} ${currentTrimester}`} placeholder={searchBoxPlaceholder} onSearch={onSearch} onSelect={onSelect} opts={opts} search-placeholder={props["search-placeholder"]}  quickFilters={<QuickFilters {...{fetuses, filterResults, setFilterResults, fetusNames, filterByFetusNumber, setFilterByFetusNumber, currentTrimester}} />}>
        {canEdit && <SearchBar />}
      </SimpleSearchEngine>
    </div>
  )
}

export default withTranslation()(ReportTemplateSearch);


const QuickFilters = withTranslation()(({t: __, filterResults, setFilterResults, fetuses, fetusNames, filterByFetusNumber, setFilterByFetusNumber, currentTrimester}) => {
  return <>
    {fetuses.filter(f => f.id).length > 1 && fetuses.map((fetus, fetusIndex) => (
      !!fetus.id && <Button key={fetus.id} size="small" variant={(!filterByFetusNumber || filterByFetusNumber === fetusIndex) ? "contained" : "outline"} label={fetusNames[fetusIndex]} onClick={() => {
        setFilterByFetusNumber(filter => filter === fetusIndex ? null : fetusIndex);
      }} />
    ))}
    <Button
      size="small"
      icon="filter"
      variant={filterResults ? "contained" : "outline"}
      onClick={() => setFilterResults(!filterResults)}
      hint={__("searchbar.filter.hint", {
        fetus: filterByFetusNumber ? __("searchbar.filter.fetus", {fetus: fetuses[filterByFetusNumber]?.fetus?.label}) : __("searchbar.filter.anyFetus"),
        trimester: (currentTrimester && filterResults) ? __("searchbar.filter.trimester", {trimester: currentTrimester}) : __("searchbar.filter.anyTrimester"),
      })}
    />
  </>
});


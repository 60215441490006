import { cloneElement, useCallback, useEffect, useMemo, useRef } from "react";
import { withTranslation } from 'react-i18next';
import Icon from "../../../atoms/Icon/Icon";
import ReportTemplateDropdown from "./Dropdown";
import Button from "../../../atoms/Button/Button";
import DragAndDropItem from "../../../components/DragAndDropItem/DragAndDropItem";
import { searchSupportedLibraries } from "../../../config";
import { getUniqueId } from "../../../utils";
import Search from "./Search";

function ReportTemplateTable({t: __, props, user, placeholder, carryForward, children, examinationContext, loadDynamicDropdownFullTree, onEndEditing = () => {}, onEndEditingDynamicDropdown = () => {}, setRequiredAndInvalid, showErrors, reportMode, fetus = 1, ...additionalProps}) {

  const commonProps = {
    props,
    user,
    placeholder,
    carryForward,
    children,
    examinationContext,
    onEndEditing,
    setRequiredAndInvalid,
    showErrors,
    ...additionalProps,
  }

  const uniqueId = useMemo(() => getUniqueId(), []);
  const dragAndDropContainer = useRef(null);
  const isMultiple = typeof placeholder?.value === 'object' || placeholder.type === "multiple";
  
  const valueObject = useMemo(() => {
    if (!placeholder?.value) return {};
    if (isMultiple) return placeholder.value;
    const valueByFetus = Array.isArray(placeholder.value) ? placeholder.value[fetus] : placeholder.value;
    if (placeholder.tree?.length) {
      const item = placeholder.tree.find(item => item.id === valueByFetus);
      return item ? {0: {
        ...placeholder,
        label: item.label,
        value: item.id,
        order: 0,
      }} : {};
    }
    return {0: {
      ...placeholder,
      label: valueByFetus,
      value: valueByFetus,
      order: 0,
    }};
  }, [placeholder.value]);

  const valueArray = Object.entries(valueObject).sort((a, b) => a[1][(props["sort-by"] || "order")] - b[1][(props["sort-by"] || "order")]);
  const isEmpty = !valueArray.length;
  const isEditable = placeholder?.editable !== false;
  const isEditMode = !reportMode || reportMode === "edit";
  
  const isRequired = () => {
    return props.required === "true" ? <span className="required">*</span> : false;
  };

  useEffect(() => {
    if (props.required === "true" && setRequiredAndInvalid) {
      setRequiredAndInvalid((prevState) => {
        isEmpty ?
          prevState.add(props.data) : prevState.delete(props.data);
        return prevState;
      });
    }
  }, [props.required, setRequiredAndInvalid, isEmpty]);

  return (
    <div className={`table-wrapper uid-${uniqueId} ${isEditable ? 'is-editable' : 'not-editable'} ${(isEmpty && !props["empty-message"]) ? 'is-empty' : ''} ${(props.required === "true" && showErrors && isEmpty) ? 'required-error' : ''}`}>
      {props.label && <div className="table-label">{props.label} {isRequired()}</div>}
      <table>
        {(!isEmpty || !!props["empty-message"]) && <ReportTemplateTableHeaders children={children || false} props={props} isRequired={isRequired} isEditMode={isEditMode} />}
        <tbody ref={dragAndDropContainer}>
          {isEmpty && !!props["empty-message"] && <tr><td colSpan="5" className="empty-warning">{props["empty-message"]}</td></tr>}
          {valueArray.map(([slug, value], index) => (
            <ReportTemplateTableTr
              key={slug || index}
              defaultKey={slug || index}
              uniqueId={uniqueId}
              value={valueObject}
              isMultiple={isMultiple}
              multiselectValue={slug}
              dragAndDropContainer={dragAndDropContainer}
              props={props}
              placeholder={{...placeholder, value: valueObject}}
              children={children}
              onEndEditing={onEndEditing}
              isEditable={isEditable}
              isEditMode={isEditMode}
            />
          ))}
        </tbody>
        {isEditable && <ReportTemplateTableFooter loadDynamicDropdownFullTree={loadDynamicDropdownFullTree} onEndEditingDynamicDropdown={onEndEditingDynamicDropdown} {...commonProps} />}
      </table>
    </div>
  )
}
export default withTranslation()(ReportTemplateTable);


function ReportTemplateTableHeaders({children, props, isRequired, isEditMode}) {
  if (!children || !Array.isArray(children)) return false;
  
  const labels = children.filter(child => child?.props?.type === "column").map(child => child.props?.props?.label);
  if (!props.label && props.required === "true") labels[0] = <>{labels[0] || ""} {isRequired()}</>;
  if (labels?.every(label => !label)) return false;

  return (
    <thead>
      <tr>
        {(props.sortable === "true" && isEditMode) ? <th className="not-printable"></th> : false}
        {labels.map((label, index) => <th key={index}>{label}</th>)}
        {isEditMode && <th className="not-printable table_cta">&nbsp;</th>}
      </tr>
    </thead>
  );
}

function ReportTemplateTableFooter({props, user, placeholder, carryForward, examinationContext, loadDynamicDropdownFullTree, onEndEditing, onEndEditingDynamicDropdown, ...additionalProps}) {
  const commonProps = {
    props,
    user,
    placeholder,
    carryForward,
    examinationContext,
    onEndEditing,
    ...additionalProps,
  };

  const useSearch = searchSupportedLibraries.includes(props.source);

  return (
    <tfoot className="not-printable">
      <tr>
        <td colSpan="999">
          {useSearch
              ? <Search {...commonProps}/>
              : <ReportTemplateDropdown props={{...props, compact: true, fullwidth: true, label: <Icon name="add" />}} showOptions={false} placeholder={placeholder} loadDynamicDropdownFullTree={loadDynamicDropdownFullTree} onEndEditing={onEndEditing} onEndEditingDynamicDropdown={onEndEditingDynamicDropdown} {...additionalProps}/>
          }
        </td>
      </tr>
    </tfoot>
  );
}

function ReportTemplateTableTr({defaultKey, uniqueId, value, isMultiple, multiselectValue, dragAndDropContainer, props, placeholder, children, onEndEditing, isEditable, isEditMode}) {
  if (!children || !Array.isArray(children)) return false;
  
  const columns = children.filter(child => child?.props?.type === "column").map((child, index) => {
    const childrenArray = !!child.props.children && Array.isArray(child.props.children) ? child.props.children : [child.props.children];
    return <td key={defaultKey + "_" + index}>{childrenArray.map((child, index) => {
      if (child.props?.props?.data === props?.data) {
        return cloneElement(child, {key: index, defaultKey: index, multiselectValue, placeholder, format: isMultiple ? "multiple" : "string"})
      }
      return child;
    })}</td>;
  });

  const removeLine = () => {
    let newValue = {...placeholder.value};
    delete newValue[multiselectValue];
    onEndEditing(props.data, {value: newValue}, props.custom);
  }
  
  const updateOrder = () => {
    const newValue = {...placeholder.value};
    let index = 0;
    for (const tr of dragAndDropContainer.current.querySelectorAll('.table_tr')) {
      const id = tr.dataset.id;
      newValue[id].order = index++;
    }
    onEndEditing(props.data, {value: newValue}, props.custom);
  }

  return (
    <tr key={defaultKey} className="table_tr" data-id={multiselectValue}>
      {(props.sortable === "true" && isEditMode) ?
        <td className="draggable not-printable">
          <DragAndDropItem
            isDraggable={true}
            direction="vertical"
            container={dragAndDropContainer.current}
            targetSelector={`.table-wrapper.uid-${uniqueId} .table_tr`}
            createGhost={(draggedElement) => {
              const dragPlaceholder = draggedElement?.cloneNode(true);
              return dragPlaceholder;
            }}
            onDragStart={(e, attr) => {
              attr.draggedElement?.classList.add('dragged-line');
              if (document.querySelector('.exam-report-content')) document.querySelector('.exam-report-content').style.overflow = "hidden";
            }}
            onDragHover={(e, attr) => {
              if (attr?.hoveredElement) {
                if (attr.mouseOffset.x <= 50) {
                  attr.hoveredElement.parentNode.insertBefore(attr.draggedElement, attr.hoveredElement);
                } else {
                  attr.hoveredElement.parentNode.insertBefore(attr.draggedElement, attr.hoveredElement.nextSibling);
                }
              }
            }}
            onDrop={async (e, attr) => {
              updateOrder();
              attr.draggedElement?.classList.remove('dragged-line');
              if (document.querySelector('.exam-report-content')) document.querySelector('.exam-report-content').style.overflow = "";
              return true;
            }}
          >
            <Icon name="drag" />
          </DragAndDropItem>
        </td>
      : false}
      {columns}
      {isEditMode && isMultiple && (
        <td className="not-printable table_cta">
          {isEditable && <Button icon="trash" variant="link" size="small" onClick={removeLine} />}
        </td>
      )}
    </tr>
  );
}

import Icon from "../../../atoms/Icon/Icon";

const PausedPrintButton = ({ isPrintable, toggleIsPrintable = () => { } }) => {
  return (
    <Icon name={isPrintable ? 'print' : 'print-add'} className={isPrintable ? '' : 'paused-with-no-print'} onClick={(e) => {
      e.stopPropagation();
      toggleIsPrintable();
    }} />
  );
};

export default PausedPrintButton;

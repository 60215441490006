import { withTranslation } from "react-i18next";
import React, {useState, useContext} from "react";
import Button from "../../atoms/Button/Button"
import ResourceApi from "../../services/resource";
import { AppContext } from "../../context-providers/App";
import "./PrintingSharingSettings.css";
import Icon from "../../atoms/Icon/Icon";
import useAnchorScroll from "../../hooks/useAnchorScroll";

const FALLBACK_IMAGE_URL = "images/no-image-500.png";
const MAX_SIZE_IN_BYTES = 5e6; //5MB ~ 5,000,000 bytes

const LogoSettings = ({ t: __ }) => {
  useAnchorScroll();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const { site, setSite } = useContext(AppContext);

  const handleImageChange = async (e) => {
    const files = e?.target?.files ?? [];
    if (files.length > 0 && files[0].size <= MAX_SIZE_IN_BYTES) {
      setErrorMessage(false);
      setLoading(true);
      const newFile = files[0];
      const formData = new FormData();
      formData.append("image", newFile);
      try {
        const res = await ResourceApi.uploadBrandImage(formData);
        if(res?.data?.error == null) {
          setSite(res.data.data);
        }
      } catch(e) {
        console.error(e);
        setErrorMessage(__("preferences.printingSharing.logo.uploadError"))
      }
      setLoading(false);
    } else {
      setErrorMessage(__("preferences.printingSharing.logo.uploadTooBig"))
    }
  }

  const handleDeleteImage = async () => {
    setLoading(true)
    try {
      const res = await ResourceApi.updateSite(site?.id, 
        { logo_filename: null }
      )
      if(res?.data?.error == null) {
        setSite(res.data.data);
      }
    } catch(e){
      console.error(e);
    }
    setLoading(false);
  }

  const Image = () => {
    if(loading) return "Loading";
    else if(site?.logo_base64 == null) return <img src={FALLBACK_IMAGE_URL} />;
    else return <img src={`data:image/png;base64, ${site.logo_base64}`} />;
  }

  return(
    <div id="logo-section" className="section">
      <div className="row">
        <div className="w4">
          <div className="title">
            {__("preferences.printingSharing.logo")}
          </div>
          <div className="subtitle">
            {__("preferences.printingSharing.uploadInfo")}
          </div>
        </div>
        <div className="w8">
          <div className="content">
            <div className="image-thumbnail">
              <Image />
            </div>
            <div className="image-actions">
              <label htmlFor="edit" className="button">
                    {__("preferences.printingSharing.edit")}
                  <input id="edit" type="file" accept="image/png, image/jpeg" style={{display: "none"}} onChange={handleImageChange} />
              </label>
              <Button
                label={__("preferences.printingSharing.delete")}
                onClick={handleDeleteImage}
                />
            </div>
          </div>
        </div>
      </div>
      {!!errorMessage && (
        <div className="error-message"><Icon name="warning" /> {errorMessage}</div>
      )}
    </div>
  )
};

export default withTranslation()(LogoSettings);

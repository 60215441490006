import { useContext, useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useParams, useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";

/* Contextes */
import { ExaminationContext } from "../../context-providers/Examination";
import { WindowContext } from "../../context-providers/Window";
import useAuth from "../../context-providers/Auth";

/* atoms */
import  Icon from "../../atoms/Icon/Icon";
import  Button from "../../atoms/Button/Button";

/* Services */
import ResourceApi from "../../services/resource";

/* CSS */
import "./index.css";
import ArrangeOnTop from "../../atoms/ArrangeOnTop/ArrangeOnTop";
import NumericInput from "../../atoms/NumericInput/NumericInput";

const ANNOTATION_COLOR = "#ffff00";
const ANNOTATION_FONT_SIZE = 36;
const CLICK_TOLERANCE = 10;

const getThumbnailDataURL = (canvas) => {
  const dataURL = canvas?.toDataURL("image/jpeg", 1.0);
  return dataURL;
}

const uncacheThumbnail = (dicomInstanceId) => {
  /* This hack is to reload the thumbnail in the browser cache ... */

  const iframe = document.createElement("iframe")
  document.body.append(iframe)
  const img = iframe.contentWindow.document.createElement("img")
  img.addEventListener(
    "load",
    () => {
      /* We can now reload the iframe */
      iframe.addEventListener(
        "load",
        () => {
          /* Now we can delete everything */
          iframe.remove()
        }
      )
      iframe.contentWindow.location.reload(true);
      return
    },
    false,
  );
  img.src = `/api/v2/dicom-instance/${dicomInstanceId}/thumbnail`
}

const extractMeasurement = (measurement, canvasToLocalmm) => {
  switch(measurement.type) {
    case "ellipse":
      const ellipseMiddle = segmentMiddle(measurement.axis);
      const axisXDistance = Math.sqrt(
        squareDistance(
          {
            x: canvasToLocalmm.x(measurement.axis.from.x),
            y: canvasToLocalmm.y(measurement.axis.from.y),
          },
          {
            x: canvasToLocalmm.x(measurement.axis.to.x),
            y: canvasToLocalmm.y(measurement.axis.to.y),
          }
        )
      );

      const axisYDistance = 2 * Math.sqrt(
        squareDistance(
          {
            x: canvasToLocalmm.x(ellipseMiddle.x),
            y: canvasToLocalmm.y(ellipseMiddle.y),
          },
          {
            x: canvasToLocalmm.x(measurement.projectedPosition.x),
            y: canvasToLocalmm.y(measurement.projectedPosition.y),
          }
        )
      );

      const rx = axisXDistance / 2;
      const ry = axisYDistance / 2;
      const h = Math.pow((rx-ry), 2) / Math.pow((rx+ry), 2);

      return {
        circumference: (Math.PI * ( rx + ry )) * (1 + ( (3 * h) / ( 10 + Math.sqrt( 4 - (3 * h) )) )),
        axisX: axisXDistance,
        axisY: axisYDistance,
      }
    case "line":
      return {
        distance: Math.sqrt(
          squareDistance(
            {
              x: canvasToLocalmm.x(measurement.from.x),
              y: canvasToLocalmm.y(measurement.from.y),
            },
            {
              x: canvasToLocalmm.x(measurement.to.x),
              y: canvasToLocalmm.y(measurement.to.y),
            }
          )
        )
      }
  }
}

const drawShape = (shape, canvas, style, previewCanvasRatio) => {
  const ctx = canvas.getContext("2d");
  ctx.save();
  applyStyle(ctx, style);
  const drawResult = doDrawShape(shape, canvas, previewCanvasRatio);
  ctx.restore();
  return drawResult;
}

const applyStyle = (ctx, style) => {
  return Object.entries(style).forEach(([key, value]) => {
    ctx[key] = value;
  })
}

const doDrawShape = (shape, canvas, previewCanvasRatio) => {
  const ctx = canvas.getContext("2d");

  switch(shape.type) {
    case "text":
      /*
       * from: {x, y},
       * text: ""
       */
      ctx.save();
      ctx.font = `${ANNOTATION_FONT_SIZE}px sans-serif`;
      ctx.fillStyle = ANNOTATION_COLOR;
      ctx.textBaseline = "bottom";
      ctx.fillText(shape.text, shape.from.x, shape.from.y);
      ctx.restore();

      return;

    case "dashed-line":
      /*
       * from: {x, y},
       * to: {x, y}
       */
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 2 * previewCanvasRatio.x;
      ctx.setLineDash([ctx.lineWidth, ctx.lineWidth * 2]);
      ctx.moveTo(shape.from.x, shape.from.y);
      ctx.lineTo(shape.to.x, shape.to.y);

      ctx.stroke();
      ctx.restore();

      return;

    case "line":
      /*
       * from: {x, y},
       * to: {x, y}
       */
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 2;
      ctx.moveTo(shape.from.x, shape.from.y);
      ctx.lineTo(shape.to.x, shape.to.y);

      ctx.stroke();
      ctx.restore();

      return;

    case "circle":
      /*
       * from: {x, y},
       * to: {x, y},
       */
      ctx.beginPath();
      const [ circle_from, circle_to ] = [
        {x: shape.from.x, y: shape.from.y},
        {x: shape.to.x, y: shape.to.y},
      ]
      const [ circle_x, circle_y, diameter_size, circle_angle ] = [
        (circle_from.x + circle_to.x)/2,
        (circle_from.y + circle_to.y)/2,
        Math.sqrt(Math.pow(circle_from.x - circle_to.x, 2) + Math.pow(circle_from.y - circle_to.y, 2)),
        Math.atan((circle_axis.from.y - circle_axis.to.y) / (circle_axis.from.x - circle_axis.to.x))
      ]
      ctx.ellipse(circle_x, circle_y, diameter_size / 2, diameter_size / 2, circle_angle, 0, 2 * Math.PI)
      ctx.stroke();
      return

    case "ellipse":
      /*
       * axis: {
       *  from: {x, y},
       *  to: {x, y}
       * },
       * projectedPosition
       */
      ctx.beginPath();
      const [ellipse_from, ellipse_to, ellipse_projectedPosition] = [
        {x: shape.axis.from.x, y: shape.axis.from.y},
        {x: shape.axis.to.x, y: shape.axis.to.y},
        {x: shape.projectedPosition.x, y: shape.projectedPosition.y},
      ]
      const ellipse_center = segmentMiddle({from: ellipse_from, to: ellipse_to})
      const [axis_size, ellipse_angle, ellipse_orthogonalSize] = [
        Math.sqrt(squareDistance(ellipse_from, ellipse_to)),
        Math.atan((ellipse_from.y - ellipse_to.y) / (ellipse_from.x - ellipse_to.x)),
        Math.sqrt(squareDistance(ellipse_center, ellipse_projectedPosition))
      ]

      ctx.ellipse(ellipse_center.x, ellipse_center.y, axis_size / 2, ellipse_orthogonalSize, ellipse_angle, 0, 2 * Math.PI)
      ctx.stroke();
      return;

    case "patch":
      /*
       * from: {x, y},
       * to: {x, y}
       */
      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = 2;
      const xr = shape.from.x;
      const yr = shape.from.y;
      const wr = shape.to.x - xr;
      const hr = shape.to.y - yr;
      ctx.rect(xr, yr, wr, hr);

      ctx.fill();
      ctx.restore()
      return;

    default:
      return;
  }
}

/* Transform a measurement to a shape that can be drawn on the canvas */
const measurementToShape = (measurement) => {
  switch(measurement.type) {
    case "calibration":
      return pointDrawing(measurement.from).concat(pointDrawing(measurement.to)).concat({
        type: "line",
        from: measurement.from,
        to: measurement.to,
      });
    case "ellipse":
      return ellipseDrawing(measurement.axis, measurement.projectedPosition);
    case "line":
      return pointDrawing(measurement.from).concat(pointDrawing(measurement.to)).concat({
        type: "line",
        from: measurement.from,
        to: measurement.to,
      });
    case "patch":
      return [{
        type: "patch",
        from: measurement.from,
        to: measurement.to,
      }];
    case "text":
      return [{
        type: "text",
        from: measurement.from,
        text: measurement.text,
      }];
    default:
      return []
  }
}

/* Return the power 2 of the distance (using the square method) */
const squareDistance = (from, to) => {
  return Math.pow(from.x - to.x, 2) + Math.pow(from.y - to.y, 2)
}

/* Return the middle of a segment */
const segmentMiddle = (segment) => {
  return {
    x: (segment.from.x + segment.to.x) / 2,
    y: (segment.from.y + segment.to.y) / 2,
  }
}

/* get the position of the projected point in the median of a segment at a given distance */
const projectionOnMedianByDistance = (distance, segment) => {
  const middle = segmentMiddle(segment);
  const p = {x: segment.from.x - segment.to.x, y: segment.from.y - segment.to.y};
  let n = {x: -p.y, y: p.x};
  const norm_length = Math.sqrt((n.x * n.x) + (n.y * n.y));
  n.x /= norm_length;
  n.y /= norm_length;
  return {x: middle.x + (distance * n.x), y: middle.y + (distance * n.y)};
}

/* Project the current position on the line defined by the segment */
const projectionOnMedian = (currentPosition, segment) => {
  const middle = segmentMiddle(segment);
  const colinearVector = {
    x: segment.from.x - segment.to.x,
    y: segment.from.y - segment.to.y
  }

  const normalVector = {
    x: colinearVector.y,
    y: - colinearVector.x,
  }

  /* Vector from the middle of the ellipse to the currentPosition of the cursor */
  const currentVector = {
    x: currentPosition.x - middle.x,
    y: currentPosition.y - middle.y,
  }

  /* We want to express the current position in the base of colinearVector and normalVector.
   * So we have the following equation system to solve 
   *  [ currentVector.x = a * normalVector.x + b * colinearVector.x
   *  [ currentVector.y = a * normalVector.y + b * colinearVector.y
   *
   * Then having the projection of the vector would simply be:
   * [ projection.x = a * normalVector.x
   * [ projection.y = a * normalVector.y
   *
   *  To find a we can express the upper equation system as:
   *  [ (currentVector.x + currentVector.y) = a * (normalVector.x + normalVector.y) + b * (colinearVector.x + colinearVector.y)
   *  [ (currentVector.x - currentVector.y) = a * (normalVector.x - normalVector.y) + b * (colinearVector.x - colinearVector.y)
   *
   *  Here we have 2 possible expression of b in function of a
   *    If colinearVector.x + colinearVector.y != 0
   *      b = ((currentVector.x + currentVector.y) - a * (normalVector.x + normalVector.y)) / (colinearVector.x + colinearVector.y)
   *    If colinearVector.x - colinearVector.y != 0
   *      b = ((currentVector.x - currentVector.y) - a * (normalVector.x - normalVector.y)) / (colinearVector.x + colinearVector.y)
   *
   *  And by replacing b in the upper system
   *    If colinearVector.x + colinearVector.y != 0
   *      currentVector.x = a * normalVector.x + (((currentVector.x + currentVector.y) - a * (normalVector.x + normalVector.y)) / (colinearVector.x + colinearVector.y)) * colinearVector.x
   *      currentVector.x - ((currentVector.x + currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x = a * (normalVector.x - (normalVector.x + normalVector.y) / (colinearVector.x + colinearVector.y) * colinearVector.x)
   *      a = (currentVector.x - ((currentVector.x + currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x) / (normalVector.x - (normalVector.x + normalVector.y) / (colinearVector.x + colinearVector.y) * colinearVector.x)
   *    If colinearVector.x - colinearVector.y != 0
   *      currentVector.x = a * normalVector.x + (((currentVector.x - currentVector.y) - a * (normalVector.x - normalVector.y)) / (colinearVector.x + colinearVector.y)) * colinearVector.x
   *      currentVector.x - ((currentVector.x - currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x = a * normalVector.x + ((normalVector.x - normalVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x
   *      a = (currentVector.x - ((currentVector.x - currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x) / normalVector.x + ((normalVector.x - normalVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x
   */

  if(colinearVector.x + colinearVector.y != 0) {
    const a = (currentVector.x - ((currentVector.x + currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x) / (normalVector.x - (normalVector.x + normalVector.y) / (colinearVector.x + colinearVector.y) * colinearVector.x)

    return {
      x: a * normalVector.x + middle.x,
      y: a * normalVector.y + middle.y
    }
  } else {
    /* NB: colinearVector.x + colinearVector.y == 0 && colinearVector.x - colinearVector.y => colinearVector.x == 0 && colinearVector.y == 0 */
    const a = (currentVector.x - ((currentVector.x - currentVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x) / normalVector.x + ((normalVector.x - normalVector.y) / (colinearVector.x + colinearVector.y)) * colinearVector.x

    return {
      x: a * normalVector.x + middle.x,
      y: a * normalVector.y + middle.y
    }
  }
}

const ellipseDrawing = (initialVector, projectedPosition) => {
  return [
    {
      type: "dashed-line",
      ...initialVector
    },
    {
      type: "dashed-line",
      from: segmentMiddle(initialVector),
      to: projectedPosition,
    },
    {
      type: "ellipse",
      axis: {...initialVector},
      projectedPosition
    }
  ]
}

const pointDrawing = (point) => {
  return [
    {
      type: "line",
      from: {x: point.x - 5, y: point.y},
      to: {x: point.x + 5, y: point.y},
    },
    {
      type: "line",
      from: {x: point.x, y: point.y - 5},
      to: {x: point.x, y: point.y + 5},
    },
  ]
}

const pointInArea = (point, area) => {
  const left = Math.min(area.from.x, area.to.x)
  const right = Math.max(area.from.x, area.to.x)
  const top = Math.min(area.from.y, area.to.y)
  const bottom = Math.max(area.from.y, area.to.y)

  return point.x >= left && point.x <= right && point.y >= top && point.y <= bottom
}

const areIntersecting = (rect, area) => {
  if (!rect.to) rect.to = rect.from;
  const rectLeft = Math.min(rect.from.x, rect.to.x)
  const rectRight = Math.max(rect.from.x, rect.to.x)
  const rectTop = Math.min(rect.from.y, rect.to.y)
  const rectBottom = Math.max(rect.from.y, rect.to.y)

  const areaLeft = Math.min(area.from.x, area.to.x)
  const areaRight = Math.max(area.from.x, area.to.x)
  const areaTop = Math.min(area.from.y, area.to.y)
  const areaBottom = Math.max(area.from.y, area.to.y)

  return !(rectLeft > areaRight || rectRight < areaLeft || rectTop > areaBottom || rectBottom < areaTop);
}

const isMeasurementInArea = (measurement, area) => {
  switch(measurement.type) {
    case "text":
      return areIntersecting(measurement, area);

    case "ellipse":
      const ellipseCenter = segmentMiddle(measurement.axis);
      const projectedPosition2 = {
        x: 2 * ellipseCenter.x - measurement.projectedPosition.x,
        y: 2 * ellipseCenter.y - measurement.projectedPosition.y,
      };
      return [
        measurement.axis.from,
        measurement.axis.to,
        measurement.projectedPosition,
        projectedPosition2,
      ].some((p) => pointInArea(p, area));

    case "line":
      return [
        measurement.from,
        measurement.to
      ].some((p) => pointInArea(p, area));
  }
}

/* Find element of the inteface to be selected
 * For the moment we only search for measurements
 * But in the future we can also add labels, ...
 */
const findSelectedElements = (area, measurements) => {
  const results = Object.entries(measurements)
    .reverse()
    .filter(([_, measurement]) => isMeasurementInArea(measurement, area))

  if(results.length) {
    return {type: "measurement", id: results[0][0]}
  }
  return 
}



/****************************/
/* Component implementation */
/****************************/


const DisplayMeasurement = ({measurement, canvasToLocalmm }) => {
  return (
    <div className="image-manipulation-measurement">
      {
        Object.entries(extractMeasurement(measurement, canvasToLocalmm) || {})
          .filter(([name, value]) => value != NaN)
          .map(([name, value]) => (
            <div key={name}>
              <label>{name}</label>
              <span>{value.toFixed(2)} mm</span>
            </div>
          ))
      }
    </div>
  )
}
const WarningMessage = ({short, full, cta}) => {
  const [display, setDisplay] = useState(true)
  if(!display)
    return null

  return (
    <div className="image-manipulation-warning">
      <div className="image-manipulation-warning-message">
        <div className="image-manipulation-warning_icon">
          <Icon name="attention" />
        </div>
        <div>
          <div className="image-manipulation-warning_short">{short}</div>
          {!!full && <div className="image-manipulation-warning_full">{full}</div>}
          {cta && <div className="image-manipulation-warning_cta">{cta}</div>}
        </div>
        <div className="image-manipulation-warning-close" onClick={() => setDisplay(false)}>
          <Icon name="close" />
        </div>
      </div>
    </div>
  )
}

const MeasurementEditor = ({ measurement, setMeasurement, saveMeasurement, componentContext }) => {
  if(!measurement)
    return null
  
  const nodePosition = (position) => ({
    left: position.x / mousePosition.canvasPixelRatio,
    top: position.y / mousePosition.canvasPixelRatio,
  });

  switch(measurement.type) {
    case "calibration":
      const onStartEditingCalibration = (key) => {
        return (e) => {
          const target = e.target;
          target.style.pointerEvents = "none";

          const onMouseMoveHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
            setMeasurement({...measurement, [key]: currentPosition})
          }
          
          const onMouseUpHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
            saveMeasurement({...measurement, [key]: currentPosition})
            target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false)
            window.removeEventListener("mouseup", onMouseUpHandler, false)
          }

          window.addEventListener("mousemove", onMouseMoveHandler, false)
          window.addEventListener("mouseup", onMouseUpHandler, false)
        }
      }
      return <>
        <div className="image-manipulation-editor-node" style={nodePosition(measurement.from)} onMouseDown={onStartEditingCalibration("from")} />
        <div className="image-manipulation-editor-node" style={nodePosition(measurement.to)} onMouseDown={onStartEditingCalibration("to")} />
      </>

    case "text":
      // remove previous text and create a new one as the previous one, but editable
      componentContext.deleteMeasurement();
      onStartAnnotate(componentContext, measurement);
      return false;

    case "rect":
    case "line":
      const onStartEditingLine = (key) => {
        return (e) => {
          const target = e.target;
          target.style.pointerEvents = "none";

          const onMouseMoveHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
            setMeasurement({...measurement, [key]: currentPosition})
          }
          
          const onMouseUpHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
            saveMeasurement({...measurement, [key]: currentPosition})
            target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false)
            window.removeEventListener("mouseup", onMouseUpHandler, false)
          }

          window.addEventListener("mousemove", onMouseMoveHandler, false)
          window.addEventListener("mouseup", onMouseUpHandler, false)
        }
      }
      return <>
        <div className="image-manipulation-editor-node" style={nodePosition(measurement.from)} onMouseDown={onStartEditingLine("from")} />
        <div className="image-manipulation-editor-node" style={nodePosition(measurement.to)} onMouseDown={onStartEditingLine("to")} />
      </>

    case "ellipse":
      const onStartEditingProjectedPosition = (e) => {
        const target = e.target;
        target.style.pointerEvents = "none";

        const onMouseMoveHandler = (e) => {
          e.preventDefault();
          e.stopPropagation();
          const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
          setMeasurement({...measurement, projectedPosition: projectionOnMedian(currentPosition, measurement.axis)})
        }

        const onMouseUpHandler = (e) => {
          e.preventDefault();
          e.stopPropagation();
          const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
          saveMeasurement({...measurement, projectedPosition: projectionOnMedian(currentPosition, measurement.axis)})
          target.style.pointerEvents = "";
          window.removeEventListener("mousemove", onMouseMoveHandler, false)
          window.removeEventListener("mouseup", onMouseUpHandler, false)
        }

        window.addEventListener("mousemove", onMouseMoveHandler, false)
        window.addEventListener("mouseup", onMouseUpHandler, false)
      }

      const onStartEditingAxis = (key) => {
        return (e) => {
          const target = e.target;
          target.style.pointerEvents = "none";

          const onMouseMoveHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
            const newAxis = {...measurement.axis, [key]: currentPosition}
            setMeasurement({...measurement, axis: newAxis, projectedPosition: projectionOnMedian(measurement.projectedPosition, newAxis)})
          }
          const onMouseUpHandler = (e) => {
            e.preventDefault();
            e.stopPropagation();
            const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
            const newAxis = {...measurement.axis, [key]: currentPosition}
            saveMeasurement({...measurement, axis: newAxis, projectedPosition: projectionOnMedian(measurement.projectedPosition, newAxis)})
            target.style.pointerEvents = "";
            window.removeEventListener("mousemove", onMouseMoveHandler, false)
            window.removeEventListener("mouseup", onMouseUpHandler, false)
          }

          window.addEventListener("mousemove", onMouseMoveHandler, false)
          window.addEventListener("mouseup", onMouseUpHandler, false)
        }
      }
      return <>
        <div className="image-manipulation-editor-node" style={nodePosition(measurement.projectedPosition)} onMouseDown={onStartEditingProjectedPosition} />
        <div className="image-manipulation-editor-node" style={nodePosition(measurement.axis.from)} onMouseDown={onStartEditingAxis("from")} />
        <div className="image-manipulation-editor-node" style={nodePosition(measurement.axis.to)} onMouseDown={onStartEditingAxis("to")} />
      </>
    default:
      console.error("Editing not implemented for measurement", measurement.type)
      return null
  }
}

const SelectedElementsEditor = ({ selectedElements, mode, measurements, setMeasurements, deleteMeasurement, canvas, addNewOperation, componentContext }) => {
  if(!["edit", "calibrate"].includes(mode))
    return null
  if(!selectedElements)
    return null
  if(!canvas)
    return null

  switch(selectedElements.type) {
    case "measurement":
    case "calibration":
      const measurement = measurements[selectedElements.id];
      return <MeasurementEditor
        measurement={measurement}
        setMeasurement={(measurement) => setMeasurements({...measurements, [selectedElements.id]: measurement})}
        saveMeasurement={(newMeasurement) => addNewOperation({
          type: "edit-measurement",
          id: selectedElements.id,
          previous: measurement,
          new: newMeasurement
        })}
        deleteMeasurement={deleteMeasurement}
        componentContext={componentContext}
      />
    default:
      console.error("Editing not implemented for ", selectedElements.type)
      return null
  }
}


let mousePosition = {};
let mouseWheel = {};

const onEditMouseDown = (e, componentContext) => {
  e.preventDefault();
  e.stopPropagation();

  const {measurements, setSelectionArea, setSelectedElements, mode} = componentContext;

  const currentPosition = {x: mousePosition?.canvasWrapperX, y: mousePosition?.canvasWrapperY, canvasX: mousePosition.canvasX, canvasY: mousePosition.canvasY};
  let updatedSelectionArea = {
    from: currentPosition,
    to: currentPosition
  };
  setSelectionArea(updatedSelectionArea);
  
  const mouseMoveHandler= () => {
    setSelectionArea(selectionArea => {
      const currentPosition = {x: mousePosition?.canvasWrapperX, y: mousePosition?.canvasWrapperY, canvasX: mousePosition.canvasX, canvasY: mousePosition.canvasY};
      updatedSelectionArea = {...selectionArea, to: currentPosition};
      return updatedSelectionArea;
    }
  )};
  
  const mouseUpHandler = (e) => {
    e.preventDefault();
    e.stopPropagation();

    window.removeEventListener("mouseup", mouseUpHandler, false);
    window.removeEventListener("mousemove", mouseMoveHandler, false);
    
    const tolerance = CLICK_TOLERANCE * mousePosition.canvasPixelRatio;

    const selectedElements = (updatedSelectionArea.from.x === updatedSelectionArea.to.x && updatedSelectionArea.from.y === updatedSelectionArea.to.y)
    ? findSelectedElements({
      from: { x: updatedSelectionArea.from.canvasX - tolerance, y: updatedSelectionArea.from.canvasY - tolerance },
      to: { x: updatedSelectionArea.from.canvasX + tolerance, y: updatedSelectionArea.from.canvasY + tolerance },
    }, measurements)
    : findSelectedElements({
      from: { x: updatedSelectionArea.from.canvasX, y: updatedSelectionArea.from.canvasY },
      to: { x: updatedSelectionArea.to.canvasX, y: updatedSelectionArea.to.canvasY },
    }, measurements);

    if (selectedElements || mode !== "calibrate") {
      setSelectedElements(selectedElements);
    }
    setSelectionArea(null);
    updatedSelectionArea = null;
  }
  
  window.addEventListener("mouseup", mouseUpHandler, false);
  window.addEventListener("mousemove", mouseMoveHandler, false);
};

const onAnnotateMouseDown = (e, componentContext) => {
  onStartDrawing(e, componentContext);
}

const onDrawingMouseDown = (e, componentContext) => {
  if(componentContext.currentlyDrawing) return;
  componentContext.setCurrentlyDrawing(true);
  onStartDrawing(e, componentContext)
    .finally(() => componentContext.setCurrentlyDrawing(false))
}

const onStartDrawingEllipse = (componentContext) => {
  return new Promise((resolve, reject) => {
    const { setMode, setCurrentDrawing, addNewMeasurement, setSelectedElements } = componentContext;

    const initialPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
    const initialVector = {from: initialPosition, to: initialPosition};
    let currentVector = {from: initialPosition, to: initialPosition};
    let currentDelta = 0;
    let drawing = "vector";
    
    setCurrentDrawing(pointDrawing(initialPosition))

    /* The orthogonal projection of the initialVector.to onto the initialVector median is the middle of the initial vector
     * This is a slight optimization to draw it faster
     */
    setCurrentDrawing(ellipseDrawing(initialVector, segmentMiddle(initialVector)));

    const mouseMoveEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
        if (drawing === "vector") {
          currentVector = {from: initialPosition, to: currentPosition};
          setCurrentDrawing(ellipseDrawing(currentVector, projectionOnMedianByDistance(currentDelta, currentVector)));
        } else {
          setCurrentDrawing(ellipseDrawing(currentVector, projectionOnMedian(currentPosition, currentVector)));
        }
      }
    }

    const mouseUpEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
        const tolerance = CLICK_TOLERANCE * mousePosition.canvasPixelRatio;

        if (
          Math.abs(currentPosition.x - initialPosition.x) > tolerance
          || Math.abs(currentPosition.y - initialPosition.y) > tolerance
        ) {
          if (drawing === "vector" && currentDelta === 0) {
            drawing = "median";
          } else {
            const newMeasurement = {
              type: "ellipse",
              axis: currentVector,
              projectedPosition: currentDelta ? projectionOnMedianByDistance(currentDelta, currentVector) : projectionOnMedian(currentPosition, currentVector)
            };
            setCurrentDrawing(null);
            setMode("edit");
            const uuid = addNewMeasurement(newMeasurement);
            setSelectedElements({type: "measurement", id: uuid});
    
            window.removeEventListener("mousemove", mouseMoveEventListener);
            window.removeEventListener("mouseup", mouseUpEventListener);
            window.removeEventListener("wheel", mouseUpEventListener);
            resolve();
          }
        }
      }
    }

    const mouseWheelEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        currentDelta += (mouseWheel.deltaY > 0 ? 1 : -1) * mousePosition.canvasPixelRatio;
        mouseMoveEventListener.handleEvent(e);
      }
    }

    window.addEventListener("mousemove", mouseMoveEventListener, false);
    window.addEventListener("mouseup", mouseUpEventListener, false);
    window.addEventListener("wheel", mouseWheelEventListener, false);
  })
}

const onStartDrawingCalibration = (componentContext) => {
  return new Promise((resolve, reject) => {
    const { setMode, setCurrentDrawing, addNewMeasurement, setSelectedElements } = componentContext;

    const initialPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
    setCurrentDrawing(pointDrawing(initialPosition))
    
    const mouseMoveEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
        setCurrentDrawing(pointDrawing(initialPosition).concat(pointDrawing(currentPosition)).concat({
          type: "dashed-line",
          from: initialPosition,
          to: currentPosition,
        }));
      }
    }
 
    const mouseUpEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
        const tolerance = CLICK_TOLERANCE * mousePosition.canvasPixelRatio;

        if (
          Math.abs(currentPosition.x - initialPosition.x) > tolerance
          || Math.abs(currentPosition.y - initialPosition.y) > tolerance
        ) {
          const newMeasurement = {
            type: "calibration",
            from: initialPosition,
            to: currentPosition,
            size: 10,
          };
          setCurrentDrawing(null);
          const uuid = addNewMeasurement(newMeasurement);
          setSelectedElements({type: "calibration", id: uuid});
  
          window.removeEventListener("mousemove", mouseMoveEventListener);
          window.removeEventListener("mouseup", mouseUpEventListener);
          resolve();
        }
      }
    }

    window.addEventListener("mousemove", mouseMoveEventListener, false);
    window.addEventListener("mouseup", mouseUpEventListener, false);
  })
}

const onStartDrawingLine = (componentContext) => {
  return new Promise((resolve, reject) => {
    const { setMode, setCurrentDrawing, addNewMeasurement, setSelectedElements } = componentContext;

    const initialPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
    
    setCurrentDrawing(pointDrawing(initialPosition))
    
    const mouseMoveEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
        setCurrentDrawing(pointDrawing(initialPosition).concat(pointDrawing(currentPosition)).concat({
          type: "dashed-line",
          from: initialPosition,
          to: currentPosition,
        }));
      }
    }
 
    const mouseUpEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
        const tolerance = CLICK_TOLERANCE * mousePosition.canvasPixelRatio;

        if (
          Math.abs(currentPosition.x - initialPosition.x) > tolerance
          || Math.abs(currentPosition.y - initialPosition.y) > tolerance
        ) {
          const newMeasurement = {
            type: "line",
            from: initialPosition,
            to: currentPosition,
          };
          setCurrentDrawing(null);
          setMode("edit");
          const uuid = addNewMeasurement(newMeasurement);
          setSelectedElements({type: "measurement", id: uuid});
  
          window.removeEventListener("mousemove", mouseMoveEventListener);
          window.removeEventListener("mouseup", mouseUpEventListener);
          resolve();
        }
      }
    }

    window.addEventListener("mousemove", mouseMoveEventListener, false);
    window.addEventListener("mouseup", mouseUpEventListener, false);
  })
}

const onStartDrawingPatch = (componentContext) => {
  return new Promise((resolve, reject) => {
    const { setCurrentDrawing, addNewMeasurement } = componentContext;

    const initialPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
    
    setCurrentDrawing(pointDrawing(initialPosition));

    const mouseMoveEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
        setCurrentDrawing([{
          type: "patch",
          from: initialPosition,
          to: currentPosition,
        }]);
      }
    }

    const mouseUpEventListener = {
      handleEvent: (e) => {
        e.preventDefault();
        e.stopPropagation();
        const currentPosition = {x: mousePosition.canvasX, y: mousePosition.canvasY};
        addNewMeasurement({
          type: "patch",
          from: initialPosition,
          to: currentPosition,
        });
        setCurrentDrawing(null);

        window.removeEventListener("mousemove", mouseMoveEventListener);
        window.removeEventListener("mouseup", mouseUpEventListener);
        resolve();
      }
    }

    window.addEventListener("mousemove", mouseMoveEventListener, false);
    window.addEventListener("mouseup", mouseUpEventListener, false);
  })
}

const onStartAnnotate = (componentContext, editMeasurement = false) => {
  return new Promise((resolve, reject) => {
    const { canvasRef, currentlyDrawing, setCurrentlyDrawing, setCurrentDrawing, addNewMeasurement } = componentContext;

    if (!currentlyDrawing) {
      const initialPosition = editMeasurement?.from
        ? {x: editMeasurement.from.x, y: editMeasurement.from.y}
        : {x: mousePosition.canvasX, y: mousePosition.canvasY};
      const offsetWhileMoving = {x: 0, y: 0, wrapperX: 0, wrapperY: 0};
      
      setCurrentDrawing([{
        type: "text",
        from: initialPosition,
        text: editMeasurement?.text?.trim() || ""
      }]);
      setCurrentlyDrawing(true);

      const inputField = document.createElement('DIV');
      inputField.type = "text";
      inputField.contentEditable = true;
      inputField.className = "annotation-text-input";
      inputField.innerHTML = editMeasurement?.text?.trim() || "";

      const inputFieldUpdateStyle = (left, top) => {
        const fontSize = ANNOTATION_FONT_SIZE / mousePosition.canvasPixelRatio;
        inputField.style = `--left: ${left}px; --top: ${top - fontSize}px; --font-size: ${fontSize}px; color: ${ANNOTATION_COLOR}`;
      }

      if (editMeasurement?.from) {
        inputFieldUpdateStyle(editMeasurement?.from.x / mousePosition.canvasPixelRatio, editMeasurement?.from.y / mousePosition.canvasPixelRatio);
      } else {
        inputFieldUpdateStyle(mousePosition.canvasWrapperX, mousePosition.canvasWrapperY);
      }
      
      const mouseDownOnAnnotationEventListener = {
        handleEvent: (e) => {
          e.preventDefault();
          inputField.classList.toggle("moving", true);
          const inputFieldsBounding = inputField.getBoundingClientRect();
          offsetWhileMoving.wrapperX = mousePosition.mouseX - inputFieldsBounding.x;
          offsetWhileMoving.x = offsetWhileMoving.wrapperX * mousePosition.canvasPixelRatio;
          offsetWhileMoving.wrapperY = mousePosition.mouseY - inputFieldsBounding.y;
          offsetWhileMoving.y = offsetWhileMoving.wrapperY * mousePosition.canvasPixelRatio;
          window.addEventListener("mousemove", mouseMoveOnAnnotationEventListener);
          window.addEventListener("mouseup", mouseUpOnAnnotationEventListener);
        }
      }
      
      const mouseUpOnAnnotationEventListener = {
        handleEvent: (e) => {
          inputField.classList.toggle("moving", false);
          window.removeEventListener("mousemove", mouseMoveOnAnnotationEventListener);
          window.removeEventListener("mouseup", mouseUpOnAnnotationEventListener);
        }
      }
      
      const mouseMoveOnAnnotationEventListener = {
        handleEvent: (e) => {
          initialPosition.x = mousePosition.canvasX - offsetWhileMoving.x;
          initialPosition.y = mousePosition.canvasY + offsetWhileMoving.y;
          inputFieldUpdateStyle(mousePosition.canvasWrapperX - offsetWhileMoving.wrapperX, mousePosition.canvasWrapperY + offsetWhileMoving.wrapperY);

          setCurrentDrawing([{
            type: "text",
            from: initialPosition,
            to: {x: initialPosition.x - inputField.offsetHeight, y: initialPosition.y + inputField.offsetWidth},
            text: inputField.innerText
          }]);
        }
      }
      
      const mouseUpEventListener = {
        handleEvent: (e) => {
          e?.preventDefault();
          e?.stopPropagation();
          window.removeEventListener('mouseup', mouseUpEventListener);
          canvasRef?.current?.parentNode.querySelector('.image-manipulation-annotation-editor').appendChild(inputField);
          inputField.focus();
          inputField.addEventListener("keydown", keyDownEventListener, false);
          inputField.addEventListener("keyup", keyUpEventListener, false);
          inputField.addEventListener("blur", endAnnotatingEventListener);
          inputField.addEventListener("mousedown", mouseDownOnAnnotationEventListener);
        }
      }

      const keyDownEventListener = {
        handleEvent: (e) => {
          // Prevent editor shortcuts from being triggered
          e.stopPropagation();
        }
      }

      const keyUpEventListener = {
        handleEvent: (e) => {
          e.stopPropagation();
          if (["Enter", "Esc", "Tab"].includes(e.code)) {
            e.preventDefault();
            inputField.blur();
            return;
          }
          inputField.scrollTo({left: 0});

          setCurrentDrawing([{
            type: "text",
            from: initialPosition,
            to: {x: initialPosition.x - inputField.offsetHeight, y: initialPosition.y + inputField.offsetWidth},
            text: inputField.innerText
          }]);
        }
      }
      
      const endAnnotatingEventListener = {
        handleEvent: (e) => {
          if (inputField.innerText) {
            addNewMeasurement({
              type: "text",
              from: initialPosition,
              to: {x: initialPosition.x - inputField.offsetHeight, y: initialPosition.y + inputField.offsetWidth},
              text: inputField.innerText
            });
          }

          setCurrentDrawing(null);
          setCurrentlyDrawing(false);

          inputField.parentNode.removeChild(inputField);
          resolve();
        }
      }

      
      if (editMeasurement?.from) {
        mouseUpEventListener.handleEvent();
      } else {
        window.addEventListener('mouseup', mouseUpEventListener);
      }
    }  
  })
}

/* The user has clicked on the canvas with the drawing tool
 * Here we start the workflow to draw a shape on the canvas */
const onStartDrawing = (e, componentContext) => {
  return new Promise((resolve, reject) => {
    const { mode } = componentContext;

    switch (mode) {
      case "annotate":
        return onStartAnnotate(componentContext)
          .then((res) => resolve(res))
          .catch((res) => reject(res));
      case "calibrate":
        return onStartDrawingCalibration(componentContext)
          .then((res) => resolve(res))
          .catch((res) => reject(res));
      case "drawing-line":
        return onStartDrawingLine(componentContext)
          .then((res) => resolve(res))
          .catch((res) => reject(res));
      case "drawing-ellipse":
        return onStartDrawingEllipse(componentContext)
          .then((res) => resolve(res))
          .catch((res) => reject(res));
      case "drawing-patch":
        return onStartDrawingPatch(componentContext)
          .then((res) => resolve(res))
          .catch((res) => reject(res));
      default:
        return;
    }
  });
}


const ImageManipulation = ({ t: __, instanceId }) => {
  const examinationContext = useContext(ExaminationContext);
  const windowContext = useContext(WindowContext);
  const { isFeatureFlagEnabled } = useAuth();
  const history = useHistory();
  const examId = Number(useParams()?.examId);
  const dicomInstanceId = instanceId || useParams()?.dicomInstanceId;
  const instance = ((examinationContext?.instances) || []).find(({id}) => id == dicomInstanceId );
  const slideKey = instance ? `${instance.idx_in_template}_${instance.slideId}_${instance.idx_in_group}` : false;

  /* Image of the dicom instance we will use to draw the image on the canvas */
  const img = useMemo(() => new Image(), []);
  const [imgLoaded, setImgLoaded] = useState(false);
  const [saving, setSaving] = useState(false);

  /* Ratio between the loaded image and the dicom image */
  const dicomColumns = parseInt(instance?.metadata?.Columns);
  const dicomRows = parseInt(instance?.metadata?.Rows);

  const previewCanvasRatio = {
    x: img?.width / dicomColumns,
    y: img?.height / dicomRows,
  }

  /* Mode of the editor. It can be:
   * annotate: The user can write annotations on the canvas
   * drawing-*: The user can paint on the canvas using the default tool to draw ellipses or lines or patches
   * edit: The user can grab a shape and edit it
   * move: The user can move around the canvas image. And zoom-in and out -> TODO Not implemented yet
   */
  const [mode, setMode] = useState("edit");
  const [currentlyDrawing, setCurrentlyDrawing] = useState(false);
  const [currentDrawing, setCurrentDrawing] = useState(null);
  const [savedMeasurements, setSavedMeasurements] = useState(null);
  const [measurements, setMeasurements] = useState({});
  const [selectionArea, setSelectionArea] = useState(null);

  const [operationStack, setOperationStack] = useState([]);
  const [unDoneOperationStack, setUnDoneOperationStack] = useState([]);
  const [onKeyDownOperationStack, setOnKeyDownOperationStack] = useState(null);

  const [selectedElements, setSelectedElements] = useState(null);
  
  const sizeOfCalibrationSegment = useMemo(() => Object.values(measurements).find(m => m.type === "calibration")?.size, [measurements]); // in cm
  const setSiteOfCalibrationSegment = (valueInCm) => {
    setMeasurements( measurements => {
      return Object.fromEntries(
        Object.entries(measurements || {}).map(m => {
          return m[1].type === "calibration"
            ? [m[0], {...m[1], size: Number(valueInCm)}]
            : m;
        })
      );
    });
  }
  const customPixelSpacing = useMemo(() => {
    const measurement = Object.values(measurements).find(m => m.type === "calibration");
    if (!measurement) return null;

    const distance = Math.sqrt(
      squareDistance(
        {
          x: measurement.from.x,
          y: measurement.from.y,
        },
        {
          x: measurement.to.x,
          y: measurement.to.y,
        }
      )
    );
    const pixelSpacing = (Number(measurement.size) || 10) / distance / dicomColumns * img.width;
    return [pixelSpacing, pixelSpacing];
  }, [measurements]);

  const [mmPerPixelY, mmPerPixelX] = customPixelSpacing || instance?.metadata?.PixelSpacing?.split("\\")?.map(parseFloat) || [null, null];

  /* Convert canvas distance to milimeter distance */
  const canvasToLocalmm = {
    x: (canvasLength) => canvasLength * dicomColumns / img.width * mmPerPixelX,
    y: (canvasLength) => canvasLength * dicomRows / img.height * mmPerPixelY
  }

  const canvasRef = useRef(null);

  const addNewMeasurement = (measurement) => {
    const id = crypto.randomUUID();
    if(measurements[id]) // Should never have a conflict but in case
      return addNewMeasurement(measurement);
    addNewOperation({type: "add-measurment", id, measurement});
    return id;
  }

  const deleteMeasurement = () => {
    addNewOperation({
      type: "delete-measurement",
      id: selectedElements.id,
      measurement: measurements[selectedElements.id],
    });
    setSelectedElements(null);
  };

  /* vars passed to all the non-react functions */
  const componentContext = {
    mode,
    canvasRef,
    setMode,
    selectionArea,
    setSelectionArea,
    setSelectedElements,
    currentlyDrawing,
    setCurrentlyDrawing,
    currentDrawing,
    setCurrentDrawing,
    measurements,
    addNewMeasurement,
    deleteMeasurement,
  };

  /* performOperation and unperformOperation are 2 functions to be used to do or undo any action on the canvas.
   * These function help us keep a track of what has been performed or undo.
   * This allow to provide the undo and redo action mechanism
   */

  const performOperation = useCallback((operation) => {
    switch(operation.type) {
      case "add-measurment":
        setMeasurements({...measurements, [operation.id]: operation.measurement})
        return
      case "edit-measurement":
        setMeasurements({...measurements, [operation.id]: operation.new})
        return
      case "delete-measurement":
        delete measurements[operation.id]
        setMeasurements({...measurements})
        return

      default:
        console.error("Unkown operation", operation.type, "\nnothing done")
    }
  }, [measurements])

  const unperformOperation = useCallback((operation) => {
    switch(operation.type) {
      case "add-measurment":
        delete measurements[operation.id]
        setMeasurements({...measurements})
        return
      case "edit-measurement":
        setMeasurements({...measurements, [operation.id]: operation.previous})
        return
      case "delete-measurement":
        setMeasurements({...measurements, [operation.id]: operation.measurement})
        return
      default:
        console.error("Unkown operation", operation.type, "\nnothing reverted")
    }
  }, [measurements])

  /* This function pop the last modification made to the canvas to implement a sort of CTRL+Z on it
   * This function is a callback because we need to modify the mousedown event on the window every time it is modified 
   */
  const undoLastOperation = useCallback(() => {
    if(operationStack.length < 1) return
    const lastOperation = operationStack[operationStack.length - 1]
    const newOperationStack = operationStack.slice(0, -1)
    const newUnDoneOperationStack = [...unDoneOperationStack, lastOperation]
    setOperationStack(newOperationStack)
    setUnDoneOperationStack(newUnDoneOperationStack)
    unperformOperation(lastOperation)
  }, [operationStack, unDoneOperationStack, unperformOperation])

  const redoLastUnperformedOperation = useCallback(() => {
    if(unDoneOperationStack.length < 1) return
    const lastOperation = unDoneOperationStack[unDoneOperationStack.length - 1]
    const newUnDoneOperationStack = unDoneOperationStack.slice(0, -1)
    const newOperationStack = [...operationStack, lastOperation]
    setOperationStack(newOperationStack)
    setUnDoneOperationStack(newUnDoneOperationStack)

    performOperation(lastOperation)
    }, [operationStack, unDoneOperationStack, performOperation])

  const addNewOperation = useCallback((operation) => {
    setUnDoneOperationStack([])
    setOperationStack([...operationStack, operation])
    performOperation(operation)
  }, [operationStack, performOperation])

  /* If the undoLastOperation is modified (because modification stack or measurements is modified) we update the window event handler with the new function */
  useEffect(() => {
    if(onKeyDownOperationStack) {
      window.removeEventListener("keydown", onKeyDownOperationStack, false)
    }
    const newOnKeyDownOperationStack = {
      handleEvent: (e) => {
        if((e.metaKey || e.ctrlKey) && e.key == "z") {
          e.preventDefault();
          e.stopPropagation();
          return undoLastOperation()
        }
        if((e.metaKey || e.ctrlKey) && e.key == "y") {
          e.preventDefault();
          e.stopPropagation();
          return redoLastUnperformedOperation()
        }
        return
      }
    }
    window.addEventListener("keydown", newOnKeyDownOperationStack, false)
    setOnKeyDownOperationStack(newOnKeyDownOperationStack)
  }, [undoLastOperation])

  /* Load examination */
  useEffect(() => {
    examinationContext.loadExamination(examId)
  }, [examId])

  const loadMeasurements = ({user_edits: {measurement}}) => {
    if(!measurement) {
      setMeasurements({})
      setSavedMeasurements("")
      return
    }
    const formattedMeasurements = measurement.reduce((acc, {id, type, content}) => {
      acc[id] = {type, ...content}
      return acc
    }, {})
    setMeasurements(formattedMeasurements)
    setSavedMeasurements(JSON.stringify(measurement))
  }

  useEffect(() => {
    if(!!instance &&  JSON.stringify(instance?.user_edits?.measurement) != savedMeasurements) {
      /* Some one has edited the measurement on an other interface. Let's load it. */
      loadMeasurements(instance)
    }
  }, [instance])

  /* Load the dicom instance */
  useEffect(() => {
    if(!canvasRef) return
    img.addEventListener('load', renderCanvas);
    img.src = `/api/v2/dicom-instance/${dicomInstanceId}/preview`;
  }, [canvasRef, dicomInstanceId]);

  /* custom behaviors for specific tools */
  useEffect(() => {
    const currentPixelRatio = Object.entries(measurements)?.find(([id, m]) => m.type === "calibration");
    if (mode === "calibrate" && currentPixelRatio) {
      setSelectedElements({id: currentPixelRatio[0], type: currentPixelRatio[1].type});
    } else if (mode !== "edit") {
      setSelectedElements(null);
    } else if (mode === "edit" && selectedElements?.type === "calibration") {
      setSelectedElements(null);
    }
  }, [mode, measurements]);

  const onCanvasMouseDown = (e) => {
    switch(mode) {
      case "annotate":
        onAnnotateMouseDown(e, componentContext);
        return;
      case "drawing-line":
      case "drawing-ellipse":
      case "drawing-patch":
        onDrawingMouseDown(e, componentContext);
        return;
      case "calibrate":
        Object.values(measurements).some(m => m.type === "calibration")
          ? onEditMouseDown(e, componentContext)
          : onDrawingMouseDown(e, componentContext);
        return;
      case "edit":
        onEditMouseDown(e, componentContext);
        return;
      case "grab":
        /* TODO this mode will be used to move / zoom on the image */
        return;
    }
  }

  const getCanvasPixelRatio = () => {
    const canvas = canvasRef.current.getBoundingClientRect();
    return canvasRef.current?.width / canvas.width;
  }

  const refreshCanvasPixelRatio = () => {
    mousePosition.canvasPixelRatio = getCanvasPixelRatio();
  }

  const onCanvasMouseMove = (e) => {
    // we are assuming that canvas and canvas' wrapper have same size and position
    const canvas = canvasRef.current.getBoundingClientRect();
    const canvasPixelRatio = getCanvasPixelRatio();

    mousePosition = {
      mouseX: e.clientX,
      mouseY: e.clientY,
      wrapperOffsetX: canvas.x,
      wrapperOffsetY: canvas.y,
      canvasWrapperX: e.clientX - canvas.x,
      canvasWrapperY: e.clientY - canvas.y,
      canvasX: (e.clientX - canvas.x) * canvasPixelRatio,
      canvasY: (e.clientY - canvas.y) * canvasPixelRatio,
      canvasPixelRatio,
    };
  }

  const onCanvasMouseWheel = (e) => {
    let deltaY = mouseWheel.deltaY || 0;
    let deltaX = mouseWheel.deltaX || 0;
    if ((deltaY >= 0 && e.deltaY < 0) || (deltaY <= 0 && e.deltaY > 0)) deltaY = 0;
    if ((deltaX >= 0 && e.deltaX < 0) || (deltaX <= 0 && e.deltaX > 0)) deltaX = 0;
    
    mouseWheel = {
      deltaY: deltaY + (e.deltaY > 0 ? 1 : -1),
      deltaX: deltaX + (e.deltaX > 0 ? 1 : -1),
    }
  }

  useEffect(() => {
    refreshCanvasPixelRatio();
  }, [canvasRef.current]);

  useEffect(() => {
    window.addEventListener("resize", refreshCanvasPixelRatio);
    return () => window.removeEventListener("resize", getCanvasPixelRatio);
  }, []);

    /* This useEffect hook is used to trigger a rerender of the canvas. (As the useEffect has no dependency it will be trigger after each react rerender)
     * It will redraw everything saved in the state of component plus additional temporary forms that are currently being drawn by the sonographer.
     *    - Component state:
     *        - img: Raw image of the DICOM instance
     *        - measurements: all the user measurements already made to the image
     *        - currentDrawing: the current form being drawn by the user
     */
  useEffect(() => {
    renderCanvas();
  });
  
  useEffect(() => {
    setImgLoaded(false);
  }, [img]);

  const renderCanvas = () => {
    if(!canvasRef?.current) return
    if(!img.width) return
    if(!img.height) return
    
    if (!imgLoaded) setImgLoaded(true);

    const canvas = canvasRef?.current;
    canvas.height = img.height;
    canvas.width = img.width;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0, img.width, img.height);

    const currentDrawingStyle = {
      strokeStyle: "#ff0000",
    }

    if(currentDrawing) {
      currentDrawing.forEach((shape) => drawShape(shape, canvas, currentDrawingStyle, previewCanvasRatio))
    }

    const measurementsStyle = {}

    Object.entries(measurements).forEach(([id, measurement]) => {
      if(["edit", "calibrate"].includes(mode) && selectedElements?.id === id) {
        measurementsStyle.strokeStyle = "#ff0000";
      } else {
        measurementsStyle.strokeStyle = "#ffff00";
      }
      if (mode !== "calibrate" && measurement.type === "calibration") return;
      if (mode === "calibrate" && measurement.type !== "calibration") return;
      return measurementToShape(measurement).forEach((shape) => drawShape(shape, canvas, measurementsStyle, previewCanvasRatio))
    })
  }

  const selectionAreaStyle = () => {
    const fromX = selectionArea?.from?.x || 0;
    const fromY = selectionArea?.from?.y || 0;
    const toX = selectionArea?.to?.x ?? fromX;
    const toY = selectionArea?.to?.y ?? fromY;

    return {
      top: Math.min(fromY, toY),
      height: Math.abs(fromY - toY),
      left: Math.min(fromX, toX),
      width: Math.abs(fromX - toX),
    }
  }

  const onSave = () => {
    if(!img || !instance) return;
    setSaving(true);
    setMode("edit");
    setSelectedElements(null);
  }

  useEffect(() => {
    if (img && instance && canvasRef.current && saving && mode === "edit" && !selectedElements) {
      const formattedMeasurements = Object.entries(measurements).map(([id, measurement]) => {
        const {type, ...content} = measurement
        return {
          id,
          type,
          content
        }
      });

      ResourceApi.updateDicomInstance(dicomInstanceId, {
        user_edits: {
          measurement: formattedMeasurements
        },
        thumbnailDataURL: getThumbnailDataURL(canvasRef.current)
      }).then(({data: {data}}) => {
        loadMeasurements(data);
        uncacheThumbnail(dicomInstanceId);
      }).finally(() => {
        setSaving(false);
        closeImageEditor();
      });
    }
  }, [saving, mode, selectedElements]);

  const closeImageEditor = () => {
    if (isFeatureFlagEnabled("sonio.multiscreen") && windowContext.isDetached) {
      windowContext.postMessageToView("core", { event: "refreshInstances" });
      windowContext.postMessageToView("core", { event: "refreshInstanceImg", mediaId: dicomInstanceId });
      history.push(`/window/slide/${examId}/${slideKey}`);
    } else {
      history.push(`/exam/${examId}/#media-${dicomInstanceId}`);
    }
  }

  const [ctrlIsPressed, setCtrlIsPressed] = useState(false);

  /** keyboard shortcuts */
  const onKeyDown = useCallback((e) => {
    switch (e.code) {
      case "Control":
        setCtrlIsPressed(true);
        break;
      case "KeyZ":
        if (ctrlIsPressed) undoLastOperation();
        break;
      case "KeyY":
        if (ctrlIsPressed) redoLastUnperformedOperation();
        break;
      case "KeyL":
        setMode("drawing-line");
        break;
      case "KeyC":
        setMode("drawing-ellipse");
        break;
      case "KeyP":
        setMode("drawing-patch");
        break;
      case "KeyA":
        setMode("annotate");
        break;
      case "KeyR":
        setMode("calibrate");
        break;
      case "KeyS":
        setMode("edit");
        break;
      case "Delete":
      case "Backspace":
        if (["edit", "calibrate"].includes(mode) && selectedElements) deleteMeasurement();
        break;
      }

  }, [mode, selectedElements, ctrlIsPressed, setMode, setCtrlIsPressed]);
  
  const onKeyUp = useCallback((e) => {
    switch (e.code) {
      case "Control":
        setCtrlIsPressed(false);
        break;
    }
  }, [setCtrlIsPressed]);

  useEffect(() => {
    window.addEventListener("mousemove", onCanvasMouseMove);
    window.addEventListener("wheel", onCanvasMouseWheel);
    window.addEventListener("keydown", onKeyDown);
    window.addEventListener("keyup", onKeyUp);
    return () => {
      window.removeEventListener("mousemove", onCanvasMouseMove);
      window.removeEventListener("wheel", onCanvasMouseWheel);
      window.removeEventListener("keydown", onKeyDown);
      window.removeEventListener("keyup", onKeyUp);
    }
  }, [instanceId, onKeyDown, onKeyUp, onCanvasMouseMove]);
  /** /keyboard shortcuts */

  return (
    <div className="image-manipulation-container" data-window-detached={windowContext.isDetached}>
      <div className="image-manipulation-tools-section">
        <div className="image-manipulation-tools-head">
          <div className="image-manipulation-tools-grid">
            <Button icon="pointer" variant={mode === "edit" ? "" : "outline"} onClick={() => setMode("edit")} hint="Select (S)" />
            <Button icon="annotation" variant={mode === "annotate" ? "" : "outline"} onClick={() => setMode("annotate")} hint="Annotate (A)" />
            <Button icon="line" variant={mode === "drawing-line" ? "" : "outline"} onClick={() => setMode("drawing-line")} hint="Draw line (L)" />
            <Button icon="draw-circle" variant={mode === "drawing-ellipse" ? "" : "outline"} onClick={() => setMode("drawing-ellipse")} hint="Draw Circle (C)" />
            <Button icon="patch" variant={mode === "drawing-patch" ? "" : "outline"} onClick={() => setMode("drawing-patch")} hint="Patch (P)" />
            <hr />
            <div className="image-manipulation-tool">
              <Button icon="ruler" variant={mode === "calibrate" ? "" : "outline"} onClick={() => setMode("calibrate")} hint="Calibrate (R)" />
              {customPixelSpacing && (
                <div className="image-manipulation-tool_edited-button" />
              )}
              {mode === "calibrate" && (
                <ArrangeOnTop variant="balloon">
                  <div className="image-manipulation-tools-grid_calibration-balloon">
                    {selectedElements?.type === "calibration"
                      ? <NumericInput label={__("imageManipulation.calibration.segmentSize")} value={sizeOfCalibrationSegment} onChange={setSiteOfCalibrationSegment} suffix=" mm" />
                      : <div className="hint">{__("imageManipulation.calibration.initialInstructions")}</div>
                    }
                  </div>
                </ArrangeOnTop>
              )}
            </div>
          </div>
        </div>
        <div className="image-manipulation-tools-foot">
          <div className="image-manipulation-tools-grid">
            {selectedElements && <Button icon="trash" onClick={deleteMeasurement} hint="Delete (Del)" /> }
            <Button icon="undo" variant={!!operationStack.length ? "" : "outline"} onClick={undoLastOperation} hint="Undo (Ctrl-Z)" />
            <Button icon="redo" variant={!!unDoneOperationStack.length ? "" : "outline"} onClick={redoLastUnperformedOperation} hint="Redo (Ctrl-Y)" />
            <Button label={__("imageManipulation.save")} onClick={onSave} disabled={saving}/>
          </div>
        </div>
      </div>
      <div className="image-manipulation-canvas-container" data-mode={mode}>
        <div className="image-manipulation-canvas-wrapper" style={{aspectRatio: `${img.width} / ${img.height}`}}>
          <div className="image-manipulation-canvas-innerWrapper" style={{aspectRatio: `${img.width} / ${img.height}`}}>
            {
              selectedElements && selectedElements.type == "measurement" && measurements[selectedElements.id] && mode == "edit" ? (
                <div className="image-manipulation-measurements-section">
                  <DisplayMeasurement
                    measurement={measurements[selectedElements.id]}
                    canvasToLocalmm={canvasToLocalmm}
                  />
                </div>
              ) : null
            }

            <div className="image-manipulation-warnings-wrapper">
              {img && instance && previewCanvasRatio.x != previewCanvasRatio.y && (
                <WarningMessage
                  short={__("imageManipulation.imageDicomRatioNotSquare.short")}
                  full={__("imageManipulation.imageDicomRatioNotSquare.full")}
                />
              )}
              { instance && mmPerPixelY != mmPerPixelX && (
                <WarningMessage
                  short={__("imageManipulation.dicomPixelSpacingNotSquare.short")}
                  full={__("imageManipulation.dicomPixelSpacingNotSquare.full")}
                />
              )}
              { instance && !mmPerPixelY && !mmPerPixelX && mode !== "calibrate" && (
                <WarningMessage
                  short={__("imageManipulation.notCalibrated.short")}
                  full={__("imageManipulation.notCalibrated.full")}
                  cta={<>
                    <Button label={__("imageManipulation.notCalibrated.close")} variant="outline" color="common" onClick={closeImageEditor} />
                    <Button label={__("imageManipulation.notCalibrated.calibrate")} color="common" onClick={() => setMode("calibrate")} />
                  </>}
                />
              )}
              { instance && customPixelSpacing && mode !== "calibrate" && (
                <div className="image-manipulation-warnings_note">
                  {__("imageManipulation.manuallyCalibrated.warning")}
                </div>
              )}
            </div>

            <canvas
              className="image-manipulation-canvas"
              ref={canvasRef}
              onMouseDown={onCanvasMouseDown}
            />

            <div className="image-manipulation-annotation-editor" />

            <SelectedElementsEditor
              selectedElements={selectedElements}
              mode={mode}
              measurements={measurements}
              setMeasurements={setMeasurements}
              addNewOperation={addNewOperation}
              canvas={canvasRef.current}
              componentContext={componentContext}
            />

            {selectionArea ? (
              <div className="image-manipulation-selection-area" style={selectionAreaStyle()}/>
            ) : null}
          </div>
        </div>

      </div>
    </div>
  )
}

export default withTranslation()(ImageManipulation);

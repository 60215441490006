import { isValidElement, cloneElement } from "react";

export default function ReportTemplateElse({defaultKey='', children, fetus = 1, numberOfFetuses, fetusNames}) {

  const childrenWithFetusNumber = Array.isArray(children) ? children.map((child, i) => {
    if (isValidElement(child) && i !== 1) {
      return cloneElement(child, { numberOfFetuses, fetus, fetusNames, defaultKey: `${defaultKey}_${i}_block_${fetus}` });
    }
    return child;
  }) : children;


  return childrenWithFetusNumber;
}
export const groupLabels = [
    {
        order: 1,
        label: {
            en: "Fetal biometry",
            fr: "Biométrie fœtale",
            ptbr: "Biometria fetal",
            de: "Fetale Biometrie",
        },
        depth: "section",
        slug: "fetal_biometry",
        fetal: true,
    },
    {
        order: 2,
        label: {
            en: "Cardiac Biometry",
            fr: "Biométrie cardiaque",
            ptbr: "Biometria cardíaca",
            de: "Kardiale Biometrie",
        },
        depth: "section",
        slug: "cardiac_biometry",
        fetal: true,
    },
    {
        order: 3,
        label: {
            en: "Fetal Dopplers",
            fr: "Dopplers fœtaux",
            ptbr: "Dopplers fetais",
            de: "Fetale Doppler",
        },
        depth: "section",
        slug: "fetal_dopplers",
        fetal: true,
    },
    {
        order: 4,
        label: {
            en: "Cardiac Dopplers",
            fr: "Dopplers cardiaques",
            ptbr: "Dopplers cardíacos",
            de: "Kardiale Doppler",
        },
        depth: "section",
        slug: "cardiac_dopplers",
        fetal: true,
    },
    {
        order: 5,
        label: {
            en: "Maternal structures",
            fr: "Structures maternelles",
            ptbr: "Estruturas maternas",
            de: "Mütterliche Strukturen",
        },
        depth: "section",
        slug: "maternal_structures",
        fetal: "FALSE",
    },
    {
        order: 6,
        label: {
            en: "Maternal Dopplers",
            fr: "Dopplers maternels",
            ptbr: "Dopplers maternos",
            de: "Mütterliche Doppler",
        },
        depth: "section",
        slug: "maternal_dopplers",
        fetal: "FALSE",
    },
    {
        order: 1,
        label: {
            en: "Standard",
            fr: "Standard",
            ptbr: "Padrão",
            de: "Standard",
        },
        depth: "subsection",
        slug: "standard",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Head / Face / Neck",
            fr: "Tête / Visage / Cou",
            ptbr: "Cabeça / Rosto / Pescoço",
            de: "Kopf / Gesicht / Hals",
        },
        depth: "subsection",
        slug: "head_face_neck",
        fetal: null,
    },
    {
        order: 3,
        label: {
            en: "Thorax / Lungs",
            fr: "Thorax / Poumons",
            ptbr: "Tórax / Pulmões",
            de: "Brustkorb / Lungen",
        },
        depth: "subsection",
        slug: "thorax_lungs",
        fetal: null,
    },
    {
        order: 5,
        label: {
            en: "Extremities / Bony Structures",
            fr: "Abdomen / Voies urinaires",
            ptbr: "Abdômen / Trato urinário",
            de: "Bauch / Harnwege",
        },
        depth: "subsection",
        slug: "extremities_bony_structures",
        fetal: null,
    },
    {
        order: 4,
        label: {
            en: "Abdomen / Urinary Tract",
            fr: "Extrémités / Structures osseuses",
            ptbr: "Extremidades / Estruturas ósseas",
            de: "Extremitäten / Knochenstrukturen",
        },
        depth: "subsection",
        slug: "abdomen_urinary_tract",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Heart / Great Vessels",
            fr: "Cœur / Grands vaisseaux",
            ptbr: "Coração / Grandes vasos",
            de: "Herz / Große Gefäße",
        },
        depth: "subsection",
        slug: "heart_great_vessels",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Heart dimensions / Z score",
            fr: "Dimensions cardiaques / Score Z",
            ptbr: "Dimensões cardíacas / Escore Z",
            de: "Herzmaße / Z-Score",
        },
        depth: "subsection",
        slug: "heart_dimensions_z_score",
        fetal: null,
    },
    {
        order: 3,
        label: {
            en: "M-mode measurements",
            fr: "Mesures en mode M",
            ptbr: "Medições em modo M",
            de: "M-Modus Messungen",
        },
        depth: "subsection",
        slug: "m_mode_measurements",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Umbilical Artery / MCA",
            fr: "Artère ombilicale / ACM",
            ptbr: "Artéria umbilical / ACM",
            de: "Nabelarterie / MCA",
        },
        depth: "subsection",
        slug: "umbilical_artery_mca",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "DV / UV / Hepatic",
            fr: "DV / UV / Hépatique",
            ptbr: "DV / UV / Hepático",
            de: "DV / UV / Hepatisch",
        },
        depth: "subsection",
        slug: "dv_uv_hepatic",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Intracardiac",
            fr: "Intracardiaque",
            ptbr: "Intracardíaco",
            de: "Intrakardial",
        },
        depth: "subsection",
        slug: "intracardiac",
        fetal: null,
    },
    {
        order: 3,
        label: {
            en: "Postcardiac",
            fr: "Postcardiaque",
            ptbr: "Pós-cardíaco",
            de: "Postkardial",
        },
        depth: "subsection",
        slug: "postcardiac",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Uterus / Cervix",
            fr: "Utérus / Col de l'utérus",
            ptbr: "Útero / Colo do útero",
            de: "Gebärmutter / Zervix",
        },
        depth: "subsection",
        slug: "uterus_cervix",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Ovaries",
            fr: "Ovaires",
            ptbr: "Ovários",
            de: "Eierstöcke",
        },
        depth: "subsection",
        slug: "ovaries",
        fetal: null,
    },
    {
        order: 3,
        label: {
            en: "Adnexa",
            fr: "Annexes",
            ptbr: "Anexos",
            de: "Adnexe",
        },
        depth: "subsection",
        slug: "adnexa",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Uterine Artery Dopplers",
            fr: "Dopplers artères utérines",
            ptbr: "Dopplers artéria uterina",
            de: "Uterusarterie-Doppler",
        },
        depth: "subsection",
        slug: "uterine_artery_dopplers",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Ovarian Artery Dopplers",
            fr: "Dopplers artères ovariennes",
            ptbr: "Dopplers artéria ovariana",
            de: "Ovarialarterie-Doppler",
        },
        depth: "subsection",
        slug: "ovarian_artery_dopplers",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "T1",
            fr: "T1",
            ptbr: "T1",
            de: "T1",
        },
        depth: "subsubsection",
        slug: "t1",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "T2 / T3",
            fr: "T2 / T3",
            ptbr: "T2 / T3",
            de: "T2 / T3",
        },
        depth: "subsubsection",
        slug: "t2_t3",
        fetal: null,
    },
    {
        order: 3,
        label: {
            en: "Amniotic fluid",
            fr: "Liquide amniotique",
            ptbr: "Líquido amniótico",
            de: "Fruchtwasser",
        },
        depth: "subsubsection",
        slug: "amniotic_fluid",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Head",
            fr: "Tête",
            ptbr: "Cabeça",
            de: "Kopf",
        },
        depth: "subsubsection",
        slug: "head",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Face / Neck",
            fr: "Visage / Cou",
            ptbr: "Rosto / Pescoço",
            de: "Gesicht / Hals",
        },
        depth: "subsubsection",
        slug: "face_neck",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Thorax",
            fr: "Thorax",
            ptbr: "Tórax",
            de: "Brustkorb",
        },
        depth: "subsubsection",
        slug: "thorax",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Abdomen",
            fr: "Abdomen",
            ptbr: "Abdômen",
            de: "Bauch",
        },
        depth: "subsubsection",
        slug: "abdomen",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Right Kidney",
            fr: "Rein droit",
            ptbr: "Rim direito",
            de: "Rechte Niere",
        },
        depth: "subsubsection",
        slug: "right_kidney",
        fetal: null,
    },
    {
        order: 4,
        label: {
            en: "Left Kidney",
            fr: "Rein gauche",
            ptbr: "Rim esquerdo",
            de: "Linke Niere",
        },
        depth: "subsubsection",
        slug: "left_kidney",
        fetal: null,
    },
    {
        order: 3,
        label: {
            en: "Lower Extremities",
            fr: "Extrémités inférieures",
            ptbr: "Extremidades inferiores",
            de: "Untere Extremitäten",
        },
        depth: "subsubsection",
        slug: "lower_extremities",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Heart",
            fr: "Cœur",
            ptbr: "Coração",
            de: "Herz",
        },
        depth: "subsubsection",
        slug: "heart",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Great arteries",
            fr: "Grandes artères",
            ptbr: "Grandes artérias",
            de: "Große Arterien",
        },
        depth: "subsubsection",
        slug: "great_arteries",
        fetal: null,
    },
    {
        order: 3,
        label: {
            en: "Systemic Veins",
            fr: "Veines systémiques",
            ptbr: "Veias sistêmicas",
            de: "Systemische Venen",
        },
        depth: "subsubsection",
        slug: "systemic_veins",
        fetal: null,
    },
    {
        order: 4,
        label: {
            en: "Other",
            fr: "Autre",
            ptbr: "Outro",
            de: "Andere",
        },
        depth: "subsubsection",
        slug: "other",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Cardiac chambers (1)",
            fr: "Chambres cardiaques (1)",
            ptbr: "Câmaras cardíacas (1)",
            de: "Herzkammern (1)",
        },
        depth: "subsubsection",
        slug: "cardiac_chambers_1",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Cardiac Chambers (2)",
            fr: "Chambres cardiaques (2)",
            ptbr: "Câmaras cardíacas (2)",
            de: "Herzkammern (2)",
        },
        depth: "subsubsection",
        slug: "cardiac_chambers_2",
        fetal: null,
    },
    {
        order: 3,
        label: {
            en: "Cardiac chambers (3)",
            fr: "Chambres cardiaques (3)",
            ptbr: "Câmaras cardíacas (3)",
            de: "Herzkammern (3)",
        },
        depth: "subsubsection",
        slug: "cardiac_chambers_3",
        fetal: null,
    },
    {
        order: 4,
        label: {
            en: "Cardiac chambers (4)",
            fr: "Chambres cardiaques (4)",
            ptbr: "Câmaras cardíacas (4)",
            de: "Herzkammern (4)",
        },
        depth: "subsubsection",
        slug: "cardiac_chambers_4",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Heart M-Mode (1)",
            fr: "Mode M cardiaque (1)",
            ptbr: "Modo M cardíaco (1)",
            de: "Herz M-Modus (1)",
        },
        depth: "subsubsection",
        slug: "heart_m_mode_1",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Heart M-Mode (2)",
            fr: "Mode M cardiaque (2)",
            ptbr: "Modo M cardíaco (2)",
            de: "Herz M-Modus (2)",
        },
        depth: "subsubsection",
        slug: "heart_m_mode_2",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Umbilical Artery",
            fr: "Artère ombilicale",
            ptbr: "Artéria umbilical",
            de: "Nabelarterie",
        },
        depth: "subsubsection",
        slug: "umbilical_artery",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "MCA",
            fr: "ACM",
            ptbr: "ACM",
            de: "MCA",
        },
        depth: "subsubsection",
        slug: "mca",
        fetal: null,
    },
    {
        order: 3,
        label: {
            en: "Right MCA",
            fr: "ACM droit",
            ptbr: "ACM direita",
            de: "Rechte MCA",
        },
        depth: "subsubsection",
        slug: "right_mca",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Ductus Venosus",
            fr: "Ductus venosus",
            ptbr: "Ducto venoso",
            de: "Ductus Venosus",
        },
        depth: "subsubsection",
        slug: "ductus_venosus",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Tricuspid valve",
            fr: "Valve tricuspide",
            ptbr: "Válvula tricúspide",
            de: "Tricuspidalklappe",
        },
        depth: "subsubsection",
        slug: "tricuspid_valve",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Mitral Valve",
            fr: "Valve mitrale",
            ptbr: "Válvula mitral",
            de: "Mitralklappe",
        },
        depth: "subsubsection",
        slug: "mitral_valve",
        fetal: null,
    },
    {
        order: 4,
        label: {
            en: "RVOT / Pulmonary Valve",
            fr: "RVOT / Valve pulmonaire",
            ptbr: "RVOT / Válvula pulmonar",
            de: "RVOT / Pulmonalklappe",
        },
        depth: "subsubsection",
        slug: "rvot_pulmonary_valve",
        fetal: null,
    },
    {
        order: 5,
        label: {
            en: "LVOT / Aortic Valve",
            fr: "LVOT / Valve aortique",
            ptbr: "LVOT / Válvula aórtica",
            de: "LVOT / Aortenklappe",
        },
        depth: "subsubsection",
        slug: "lvot_aortic_valve",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Main PA",
            fr: "Artère pulmonaire principale",
            ptbr: "AP principal",
            de: "Haupt-PA",
        },
        depth: "subsubsection",
        slug: "main_pa",
        fetal: null,
    },
    {
        order: 4,
        label: {
            en: "Ductus Arteriosus",
            fr: "Ductus arteriosus",
            ptbr: "Ducto arterioso",
            de: "Ductus Arteriosus",
        },
        depth: "subsubsection",
        slug: "ductus_arteriosus",
        fetal: null,
    },
    {
        order: 5,
        label: {
            en: "Asc. Aorta",
            fr: "Aorte asc.",
            ptbr: "Aorta asc.",
            de: "Aufsteigende Aorta",
        },
        depth: "subsubsection",
        slug: "asc_aorta",
        fetal: null,
    },
    {
        order: 7,
        label: {
            en: "Desc. Aorta",
            fr: "Aorte desc.",
            ptbr: "Aorta desc.",
            de: "Absteigende Aorta",
        },
        depth: "subsubsection",
        slug: "desc_aorta",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Uterus",
            fr: "Utérus",
            ptbr: "Útero",
            de: "Gebärmutter",
        },
        depth: "subsubsection",
        slug: "uterus",
        fetal: null,
    },
    {
        order: 2,
        label: { en: "Cervix", fr: "Col de l'utérus", ptbr: "Colo do útero", de: "Zervix" },
        depth: "subsubsection",
        slug: "cervix",
        fetal: null,
    },
    {
        order: 3,
        label: {
            en: "Endometrium",
            fr: "Endomètre",
            ptbr: "Endométrio",
            de: "Endometrium",
        },
        depth: "subsubsection",
        slug: "endometrium",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Right Ovary",
            fr: "Ovaire droit",
            ptbr: "Ovário direito",
            de: "Rechter Eierstock",
        },
        depth: "subsubsection",
        slug: "right_ovary",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Left Ovary",
            fr: "Ovaire gauche",
            ptbr: "Ovário esquerdo",
            de: "Linker Eierstock",
        },
        depth: "subsubsection",
        slug: "left_ovary",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Right Adnexa",
            fr: "Annexe droite",
            ptbr: "Anexo direito",
            de: "Rechte Adnexe",
        },
        depth: "subsubsection",
        slug: "right_adnexa",
        fetal: null,
    },
    {
        order: 2,
        label: {
            en: "Left Adnexa",
            fr: "Annexe gauche",
            ptbr: "Anexo esquerdo",
            de: "Linke Adnexe",
        },
        depth: "subsubsection",
        slug: "left_adnexa",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Uterine Artery",
            fr: "Artère utérine",
            ptbr: "Artéria uterina",
            de: "Uterusarterie",
        },
        depth: "subsubsection",
        slug: "uterine_artery",
        fetal: null,
    },
    {
        order: 1,
        label: {
            en: "Ovarian Artery",
            fr: "Artère ovarienne",
            ptbr: "Artéria ovariana",
            de: "Ovarialarterie",
        },
        depth: "subsubsection",
        slug: "ovarian_artery",
        fetal: null,
    },
];
